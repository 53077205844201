import { Heading, LoadingPlaceholder } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { Icon } from '@vodafoneziggo/sandwich/icons';
import { Button, ButtonVariant } from 'components/Button';
import { TranslatedString } from 'components/Intl';
import NavigationLink from 'components/NavigationLink';
import React from 'react';
import { IPortalCardButton } from './portal-card-model';
import * as S from './portal-card.styles';

export interface ISubContent {
  testId?: string;
  title?: TranslatedString;
  text?: TranslatedString;
}
interface Props {
  testId?: string;
  title?: TranslatedString;
  children: React.ReactNode;
  subContent?: ISubContent;
  bottomContent?: React.ReactNode | string;
  primaryButton?: IPortalCardButton;
  secondaryButton?: IPortalCardButton;
  icon?: Icon;
  isLoading?: boolean;
}

const PortalCard = ({
  title,
  children,
  bottomContent,
  primaryButton,
  secondaryButton,
  subContent,
  testId,
  icon,
  isLoading,
}: Props) => {
  return !isLoading ? (
    <S.Card data-testid={testId}>
      <S.CardHeader>
        {icon && (
          <S.CardIcon>
            <Icon icon={icon} />
          </S.CardIcon>
        )}
        {title && (
          <Heading.H5 as={HeadingTags.H2} marginBottom="none">
            {title}
          </Heading.H5>
        )}
      </S.CardHeader>
      <S.CardBody>
        {subContent && subContent.title && (
          <S.CardSubContentTitle data-testid={`${subContent.testId}--title`}>{subContent.title}</S.CardSubContentTitle>
        )}
        {primaryButton && primaryButton.href && (
          <NavigationLink href={primaryButton.href} testId={`link-${testId}`} feature={primaryButton.feature}>
            <S.CardContent>{children}</S.CardContent>
          </NavigationLink>
        )}
        {(!primaryButton || !primaryButton.href) && <S.CardContent>{children}</S.CardContent>}
        {subContent && subContent.text && (
          <S.CardSubContent>
            {subContent.text && <div data-testid={`${subContent.testId}--text`}>{subContent.text}</div>}
          </S.CardSubContent>
        )}
        {bottomContent && <S.CardBottom>{bottomContent}</S.CardBottom>}
      </S.CardBody>
      {(primaryButton || secondaryButton) && (
        <S.CardFooter>
          <S.CardButtons>
            {primaryButton && (
              <Button
                testId={primaryButton.testId || 'primary-button'}
                variant={primaryButton.variant || ButtonVariant.LIGHT}
                onClick={primaryButton.onClick}
                href={primaryButton.href}
                feature={primaryButton.feature}
                isDisabled={primaryButton.isDisabled}>
                {primaryButton.content}
              </Button>
            )}
            {secondaryButton &&
              (React.isValidElement(secondaryButton) ? (
                secondaryButton
              ) : (
                <Button
                  testId={secondaryButton.testId || 'secondary-button'}
                  variant={secondaryButton.variant || ButtonVariant.LIGHT}
                  onClick={secondaryButton.onClick}
                  href={secondaryButton.href}
                  feature={secondaryButton.feature}
                  isDisabled={secondaryButton.isDisabled}>
                  {secondaryButton.content}
                </Button>
              ))}
          </S.CardButtons>
        </S.CardFooter>
      )}
    </S.Card>
  ) : (
    <S.Card data-testid={testId}>
      <S.CardHeader>
        <S.CardIcon>
          <LoadingPlaceholder size="medium" square />
        </S.CardIcon>
        <S.CardLoader>
          <LoadingPlaceholder width={100} size="small" />
        </S.CardLoader>
      </S.CardHeader>
      <S.CardBody>
        <S.CardLoader>
          <LoadingPlaceholder width={100} size="small" />
        </S.CardLoader>
        <S.CardLoader>{children || <LoadingPlaceholder size="large" square />}</S.CardLoader>
        <S.CardLoader>
          <LoadingPlaceholder width={100} size="small" />
        </S.CardLoader>
      </S.CardBody>
      <S.CardFooter>
        <S.CardButtons>
          <LoadingPlaceholder width={100} size="large" />
        </S.CardButtons>
      </S.CardFooter>
    </S.Card>
  );
};

export default PortalCard;
