import { TranslateKey } from 'components/Intl';

export interface IMessage {
  translationKey: TranslateKey;
  value: any;
}

export class Message implements IMessage {
  public translationKey: TranslateKey;

  public value: any;

  constructor(init: Partial<Message>) {
    Object.assign(this, init);
  }
}
