import { Color, Spacing, media } from '@vodafoneziggo/sandwich';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  max-width: 31.25rem;
  margin: ${Spacing.XL} auto;
  background-color: ${Color.WHITE};
  box-shadow: ${props => props.theme.shadows.light};
  text-align: center;
  border-radius: 0.375rem;

  ${media.up('md')`
  padding-bottom: ${Spacing.M};
  `}
`;

export const ImageContainer = styled.div`
  overflow: hidden;
  height: 40%;
`;

const imageAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
`;

export const Image = styled.div`
  animation: linear infinite 3s ${imageAnimation} forwards;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.S};
  padding-bottom: ${Spacing.M};

  ${media.down('xs')`
    flex-direction: column;
    padding: ${Spacing.S};
  `}
`;
