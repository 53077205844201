import { useAuthSubscription } from 'containers/auth';
import { ContainerStatuses, defaultLoadingStatus } from 'models/container-statuses.model';
import { ISubscription } from 'models/subscription.model';
import { useEffect, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import api from 'utils/api';
import { makeRequestReducer } from 'utils/api/useApiRequest';
import DateFNS from 'utils/date';
import { wrapWithCancellation } from 'utils/function';
import { triggerApiErrorEvent } from 'utils/tracking';
import { DevicePayOffFormValues } from './DeviceAndSim/pages/DevicePayOff/device-pay-off.model';
import { IPauseSubscription } from './Subscription/pages/PauseSubscription/pause-subscription.model';
import { ComponentCatalogCodes, ICappingStatus, IContract, IDeviceRc, IProduct, IProductSettings } from './models';

export enum ProductServiceType {
  LOAN_DEVICE = 'LD',
  MOBILE_OFFER = 'MO',
}
interface ProductsState {
  product: IProduct;
  loans: IDeviceRc[];
  contract: IContract;
  productSettings: IProductSettings[];
  cappingStatus: ICappingStatus;
}

const defaultCappingStatus: ICappingStatus = {
  national: {
    is_capped: false,
    is_unblocked: false,
  },
  roaming: {
    is_capped: false,
    is_unblocked: false,
  },
};

function useProducts() {
  const reducer = makeRequestReducer<ProductsState>();
  const [state, dispatch] = useReducer(reducer, defaultLoadingStatus);

  /**
   * Method that can be used to (re)fetch the products. The useEffect watches the
   * fetchTick value and will redo its work when the value changes.
   */
  const [fetchTick, fetchProducts] = useReducer(x => x + 1, 0);

  /**
   * Extract required auth values and reset the request hook whenever auth values change
   * so the request returns to its initial loading state.
   */
  const { bcId, productId } = useAuthSubscription();

  useEffect(() => {
    if (!bcId || !productId) return;

    dispatch({ type: ContainerStatuses.LOADING });

    const cancellation = wrapWithCancellation();

    const getProduct = api.get(`/my/product/${bcId}/${productId}?with_addons=false`);

    getProduct
      .then(
        cancellation.wrapper(productResponse => {
          let loans = [];

          if (Array.isArray(productResponse.data.device_rc)) {
            loans = productResponse.data.device_rc.filter((loan: IDeviceRc | undefined) => loan !== undefined);
          } else if (productResponse.data.device_rc !== undefined) {
            loans = [productResponse.data.device_rc];
          }

          // Initialize state with product data
          const newState = {
            product: productResponse.data,
            loans,
            contract: productResponse.data.contract,
            productSettings: state.data?.productSettings || [],
            cappingStatus: state.data?.cappingStatus || defaultCappingStatus,
          };

          // Dispatch with product data
          dispatch({
            type: ContainerStatuses.READY,
            data: newState,
          });

          const getSettings = api.get(`/my/${bcId}/products/${productId}/settings?with_capping=true`);

          getSettings
            .then(
              cancellation.wrapper(settingsResponse => {
                dispatch({
                  type: ContainerStatuses.READY,
                  data: {
                    ...newState,
                    productSettings: settingsResponse.data.settings,
                    cappingStatus: settingsResponse.data.capping_status || defaultCappingStatus,
                  },
                });
              })
            )
            .catch(
              cancellation.wrapper(() => {
                // If settings call fails, do nothing, as the state has already been updated with product data
              })
            );
        })
      )
      .catch(
        cancellation.wrapper(errors => {
          dispatch({ type: ContainerStatuses.FAILED, errors: errors.errorMessages });
          triggerApiErrorEvent('products.container', errors.status, errors.errorMessages);
        })
      );

    return cancellation.cancel;
  }, [bcId, productId, fetchTick]);

  return {
    state,

    fetchProducts,

    /* eslint-disable-next-line require-await */
    updateRoamingDataCap: async (value: string) =>
      api.post(`/my/${bcId}/products/${productId}/settings`, {
        component_code: 'Data_Roaming',
        simulate_only: false,
        action: 'SET',
        component_settings: [
          {
            value,
            settingcode: 'Roaming_Spending_Limit',
          },
        ],
      }),

    /* eslint-disable-next-line require-await */
    updateWelcomeSmsEnabled: async (value: 'Yes' | 'No', assignedComponentId: string, catalogCode: string) => {
      const payload = {
        component_code: catalogCode,
        simulate_only: false,
        action: 'SET',
        assigned_component_id: assignedComponentId,
        parent_assigned_component_id: '',
        return_eligible_settings: false,
        service_id: '',
        component_settings: [
          {
            value,
            settingcode: ComponentCatalogCodes.WELCOME_SMS_ENABLED,
          },
        ],
      };

      return api.post(`/my/${bcId}/products/${productId}/settings`, payload);
    },

    /* eslint-disable-next-line require-await */
    postRequestNewMobileNumber: async (subscription: ISubscription) => api.post('/my/changemsisdn', subscription),

    /* eslint-disable-next-line require-await */
    submitTimeoutForm: async (values: IPauseSubscription) =>
      api.post('/my/timeout', {
        ...values,
        start_date: DateFNS.format(values.start_date, 'dd-MM-yyyy').toString(),
        end_date: values.end_date && DateFNS.format(values.end_date, 'dd-MM-yyyy').toString(),
      }),

    /* eslint-disable-next-line require-await */
    postRequestDevicePayOff: async (formValues: DevicePayOffFormValues) => {
      const response = await api.post(`/my/device/loan/payoff`, {
        billing_customer_id: formValues?.billing_customer_id,
        loan_id: formValues?.loan_id,
        loan_amount: String(formValues?.loan_amount),
        loan_start_date: String(formValues?.loan_start_date),
        loan_end_date: String(formValues?.loan_end_date),
        phone_number: formValues?.phone_number,
        address: formValues?.address,
        email: formValues?.email,
        initial: formValues?.initial,
        last_name: formValues?.last_name,
        emailconfirm: formValues?.email_repeat,
        notification_type: formValues?.notification_by === 'email' ? 'Email' : null,
      });

      return response;
    },

    getLoanById: (loanId: string) => {
      // Use optional chaining to avoid errors if state.data or state.data.loans is null or undefined
      return state.data?.loans.find(loan => loan.id === loanId);
    },
  };
}

export default createContainer(useProducts);
