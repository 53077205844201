/**
 * Returns true if matching selectors against element's root yields element, and false otherwise.
 */
const matches = (el: Element, s: string) => {
  const method = el.matches;

  return method(s);
};

/**
 * Find closest element that matches selector (searching up the tree).
 * Supported from IE9+ and later.
 * Inspired by https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
 *
 * @param element
 * @param selector
 */
export const closest = (element: Element, selector: string) => {
  if (element.closest) {
    return element.closest(selector);
  }

  let el: Element | null = element;

  do {
    if (matches(el, selector)) return el;
    el = el.parentElement || (el.parentNode as HTMLElement);
  } while (el !== null && el.nodeType === 1);

  return null;
};
