import { DEFAULT_SCOPES } from 'containers/auth/scopes';
import { getStatic } from 'utils/api';

/**
 * Get the app settings from the static JSON
 */
export const getAppSettings = async () => {
  try {
    const response = await getStatic('settings/app.settings.json');
    const settings = response.data || {};

    return settings;
  } catch (e) {
    return e;
  }
};

/**
 * Authentication config for the  oidc Service
 */
export const getOidcConfig = async () => {
  try {
    const AppSettings = await getAppSettings();

    return {
      response_type: 'id_token token',
      authorisation: `${AppSettings.SSO_BASE_URL}/sso/`,
      post_logout_redirect_uri: '/account/uitgelogd',
      token_type: 'Bearer',
      authorize_endpoint: `${AppSettings.SSO_BASE_URL}/sso/authorize`,
      csrf_token_endpoint: `${AppSettings.SSO_BASE_URL}/sso/csrf`,
      validate_token_endpoint: `${AppSettings.REST_BASE_URL}/sso/check-tokens`,
      is_session_alive_endpoint: `${AppSettings.REST_BASE_URL}/user-sessions`,
      upgrade_session_endpoint: `${AppSettings.SSO_BASE_URL}/sso/session-upgrade`,
      redirect_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/my`,
      login_endpoint: `${AppSettings.SSO_BASE_URL}/sso/j_spring_security_check`,
      logout_endpoint: `${AppSettings.SSO_BASE_URL}/sso/logout`,
      restricted_redirect_uris: ['uitloggen', 'uitgelogd', 'inloggen', 'maak-een-keuze'],
      post_logout_provider_ids_to_be_cleaned: [
        'vodafone-account', // My Vodafone - Account
        'vodafone-selfservice', // My Vodafone - Self Service
        'ziggo-account', // Mijn Ziggo Account
        'ziggo-selfservice', // Mijn Ziggo Self Service
      ],
      silent_refresh_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/account/assets/silent.html`,
      silent_logout_uri: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/account/uitloggen`,
      // Override them with brand specific values
      provider_id: 'vodafone-selfservice',
      client_id: AppSettings.SSO_CLIENT_ID_VODAFONE,
      scope: `${DEFAULT_SCOPES.join(' ')} sim_read esim_activation_code_read smswebform`,
      debug: true,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('get static/settings/app.settings.json ERROR.');

    return e;
  }
};
