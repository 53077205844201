export interface IProductSettings {
  context: IContext;
  component_identifier: IComponentIdentifier;
}

export interface IProductSettingsPayload {
  action: string;
  assigned_component_id: string;
  component_code: string;
  component_settings: ComponentSetting[];
  parent_assigned_component_id: string;
  return_eligible_settings: boolean;
  service_id: string;
  simulate_only: boolean;
}

interface ComponentSetting {
  value: string;
  settingcode: string;
}

interface IContext {
  context_def: string;
  subscription_id: string;
  secondary_resource: string;
}

interface IComponentIdentifier {
  catalog_code: string | ComponentCatalogCodes;
  catalog_name: string;
  catalog_description: string;
  assigned_id: string;
  parent_assigned_component_id: string;
  assigned_parent_id: string;
  pricing_elements: any[];
  item_attributes: IItemAttribute[];
  item_identifier_relation: IItemIdentifierRelation;
  total_prices: ITotalPrices;
  change_settings_allowed: boolean;
}

// This list might not be complete.
export enum ComponentCatalogCodes {
  CRM_SERVICES = 'CRM_Services',
  DATA = 'Data',
  PRIMARY_MSISDN = 'Primary_MSISDN',
  ROAMING = 'Roaming',
  DATA_ROAMING = 'Data_Roaming',
  SETTINGS = 'Settings',
  DIRECTORY_LISTING = 'Directory_Listing',
  WELCOME_SMS_ENABLED = 'Welcome_SMS_Enabled',
}

interface IItemAttribute {
  catalog_id: string;
  catalog_code: string | ItemCatalogCodes;
  selected_value: string | ItemAttributeValues;
  name: string;
  default_value: string | ItemAttributeValues;
  data_type: number;
  type: number;
  data_size: number;
  valid_values: IValidValue[];
  mandatory: boolean;
}

// This list might not be complete.
export enum ItemCatalogCodes {
  // CRM_SERVICES
  MOBILITY_REPORT = 'Mobility_Report',

  // DATA
  INTERNET_ACCESS = 'Internet_Access',

  // PRIMARY_MSISDN
  SECRET_NUMBER_INDICATION = 'Secret_Number_Indication',
  // MOBILITY_REPORT = 'Mobility_Report', // Should be added here but is a duplicate from CRM_SERVICES

  // ROAMING
  WELCOME_SMS_ENABLED = 'Welcome_SMS_Enabled',
  NO_ROAMING_RESTRICTIONS = 'No_roaming_restrictions',

  // DATA_ROAMING
  ROAMING_SPENDING_LIMIT = 'Roaming_Spending_Limit',

  // SETTINGS
  PREMIUM_DESTINATIONS = 'Premium_Destinations',
  CALL_TRANSFER = 'Call_Transfer',

  // DIRECTORY_LISTING
  LAST_NAME = 'Last_Name',
  FIRST_NAME = 'First_Name',
  MIDDLE_NAME = 'Middle_Name',
  STREET = 'Street',
  HOUSE_NUMBER = 'House_Number',
  HOUSE_NUMBER_EXTENSION = 'House_Number_Extension',
  EMAIL = 'Email',
  TELEPHONY_NUMBER = 'Telephony_Number',
  CITY = 'City',
  POSTAL_CODE = 'Postal_Code',
  PO_BOX = 'PO_Box',
  GENDER = 'Gender',
  FAX = 'Fax',
  COMPANY_NAME = 'Company_Name',
  MOBILE_NUMBER = 'Mobile_Number',
  WEB_SITE = 'Web_Site',
  NUMBER_INFORMATION_SERVICE = 'Number_Information_Service',
}

export enum ItemAttributeValues {
  YES = 'Yes',
  NO = 'No',
}

interface ITotalPrices {
  total_oc: number;
  total_rc: number;
  previous_total_rc: number;
  original_total_oc: number;
  discount_total_oc: number;
  override_total_oc: number;
  tax_total_oc: number;
  original_total_rc: number;
  discount_total_rc: number;
  tax_total_rc: number;
  prorated_total_rc: number;
  prorated_discount_Total_rc: number;
  prorated_tax_total_rc: number;
  previous_tax_total_rc: number;
}

interface IItemIdentifierRelation {
  minimum_quantity: number;
  maximum_quantity: number;
  currently_assigned: number;
}

export interface IValidValue {
  code: string;
  name: string;
}
