import { ApolloError } from '@apollo/client';
import RelayService from '@vodafonepub/relay-service';

export interface IItemValidationError {
  error_code: string;
  item_key: string;
}

export enum ErrorTypes {
  ITEM_VALIDATION = 'item_validation',
  REQUEST = 'request',
  MOSA = 'mosa',
  BSL = 'bsl',
}

export interface IErrorMessages {
  [key: string]: string;
}

/**
 * Filter errorMessages by ErrorType. The function returns a array with the values of each object key that
 * mathes the supplied type(s)
 * @param { errors } IErrorMessages are the messages we want to filter on
 * @param { types } ErrorTypes[] is an array of types we want to use for filtering
 */
export const filterErrorMessagesByType = (errors: IErrorMessages, types: ErrorTypes[]): string[] => {
  return Object.keys(errors)
    .filter(err => types.includes(err.split('.')[0] as ErrorTypes))
    .map(err => errors[err]);
};

export const apolloDdmTrigger = (response: 'success' | 'fail', form: string, err?: ApolloError): void => {
  RelayService.trigger('form.submit', {
    result: response,
    name: form,
    error: err?.message || undefined,
  });
};
