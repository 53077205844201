import { Header, Heading } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { Footer } from 'components/Footer';
import { useTranslations } from 'components/Intl/useTranslations';
import { Tile } from 'components/Tiles';
import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import { IMaintenanceMessage } from './maintenance-message.model';
import * as S from './maintenance-messages.styles';

const intlPrefix = 'pages.maintenance';

interface MaintenanceMessagesProps {
  message?: IMaintenanceMessage;
}

const MaintenanceMessages = ({ message }: MaintenanceMessagesProps) => {
  const { translate, translateHtml } = useTranslations();
  const consumerMessage = message?.consumerMessage.join(' ');

  const activeMessage = consumerMessage || translateHtml(intlPrefix, 'message');

  return (
    <div data-testid="maintenance-message">
      <Header
        changeBillingCustomer={() => null}
        billingCustomers={[]}
        activeBillingCustomer={null}
        shouldShowMenuItem={() => Promise.resolve(false)}
        shouldRenderSubmenu={false}
      />
      <S.MaintenanceContainer className="maintenance-container">
        <Container className="container">
          <Tile>
            <S.StyledTileContent>
              <Heading.H1 color="primary" as={HeadingTags.H2} data-testid="maintenance-title">
                {translate(intlPrefix, 'title')}
              </Heading.H1>
              {typeof activeMessage === 'string' ? (
                <div dangerouslySetInnerHTML={{ __html: activeMessage }} />
              ) : (
                message
              )}
            </S.StyledTileContent>
          </Tile>
        </Container>
      </S.MaintenanceContainer>
      <Footer />
    </div>
  );
};

export default MaintenanceMessages;
