import styled from 'styled-components';
import { hoverFocus, padding, margin, media } from '@vodafoneziggo/sandwich';
import { Link } from 'react-router-dom';

const Breadcrumbs = styled.nav`
  white-space: nowrap;
`;

const BreadcrumbsList = styled.ol`
  ${margin.all('none')};
  ${padding.all('none')};
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const BreadcrumbsItem = styled.li`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 1rem;

  ${media.up('lg')`
    font-size: 1.625rem;
  `}

  &:first-child {
    .breadcrumbs__link {
      padding-left: 0;
    }
  }

  &::after {
    content: '/';
    color: #fff;
    opacity: 0.7;
  }
`;

const BreadcrumbsLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${padding.all('none', 'tiny')}
  text-decoration: none;
  color: #fff;
  opacity: 0.7;

  ${hoverFocus`
    opacity: 1;
  `}
`;

export { Breadcrumbs, BreadcrumbsList, BreadcrumbsItem, BreadcrumbsLink };
