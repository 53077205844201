import styled from '@vodafoneziggo/sandwich/theme';

const bgImgDesktop = require('@images/girl-in-red-coat-with-phone3.jpg');

export const ManageSubscriptionContainer = styled.div`
  background-image: url(${bgImgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
