import { IUserContextState } from 'components/User';
import { ProductServiceType } from 'pages/Products/products.container';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SetProductSummariesState } from '../products.model';
import { getProductSummaries } from '../products.utils';

export const useFetchProductSummariesOnBillingCustomerChange = (
  userState: IUserContextState,
  setProductSummariesState: SetProductSummariesState
) => {
  const location = useLocation();

  const productsPage = /^\/producten.*$/.test(location.pathname);

  const prevActiveBcId = useRef<string | null>(null);

  useEffect(() => {
    if (!userState.activeBcId) return;

    if (prevActiveBcId.current !== userState.activeBcId) {
      prevActiveBcId.current = userState.activeBcId;

      setProductSummariesState(state => ({
        ...state,
        forBc: null,
        currentProduct: undefined,
      }));
    }

    getProductSummaries(userState.activeBcId)
      .then(products => {
        let filteredProducts = products || [];

        if (!productsPage) {
          filteredProducts = filteredProducts.filter(
            product => product.service_type !== ProductServiceType.LOAN_DEVICE
          );
        }

        setProductSummariesState(state => ({
          ...state,
          forBc: userState.activeBcId,
          products: filteredProducts,
          hasMobileOffer: !!filteredProducts.length,
        }));
      })
      .catch(() => {
        setProductSummariesState(state => ({
          ...state,
          forBc: userState.activeBcId,
          products: [],
          hasMobileOffer: false,
        }));
      });
  }, [userState.activeBcId, location.pathname]);
};
