import { useLocation, useNavigate } from 'react-router-dom';

export const useSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentSearchParams = () => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams;
  };

  const setNewSearchParams = (params: URLSearchParams) => {
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
        hash: location.hash,
      },
      { replace: true }
    );
  };

  const addToSearchParams = (key: string, value: string) => {
    const searchParams = getCurrentSearchParams();

    searchParams.append(key, value);

    setNewSearchParams(searchParams);
  };

  const removeFromSearchParams = (key: string) => {
    const searchParams = getCurrentSearchParams();

    searchParams.delete(key);

    setNewSearchParams(searchParams);
  };

  return {
    addToSearchParams,
    removeFromSearchParams,
    getCurrentSearchParams,
  };
};
