import { UserContext } from 'components/User';
import { FeaturesContext } from 'containers/featuresContext';
import { PermissionSettings } from 'models';
import { FEATURES } from 'models/features.model';
import React, { useContext, useEffect, useState } from 'react';
import { isUserAllowed } from 'utils/permissions';

export interface RestrictedSettings {
  /** Feature string to test for */
  feature?: FEATURES;
  /** Users allowed to view */
  permissions?: PermissionSettings;
  /** Allows to redirect the user to a different URL to explain why the user has no access */
  noPermissionRedirectUrl?: string;
}

const DEFAULT_AUTHORISATION = true;

interface RestrictedProps extends RestrictedSettings {
  /** Can be used as a render prop, which will then return the viewAllowed as a param, or
   * you can directly inject children */
  children: ((viewAllowed: boolean) => React.ReactElement) | React.ReactNode;
}

/**
 * Restrict the view of a component based on a feature name.
 * Features have a on/off state in feature-settings.json
 * and have a restriction in the file authorisation.postpaid.settings.json.
 *
 * If you only want to restrict based on roles, you can pass the 'withoutToggle'
 * prop to ignore anything in the feature-toggle files.
 *
 * If a user is NOT allowed to view the component, null is returned in render.
 *
 * Usage:
 * <Restricted feature="name.of.your.feature">
 *  <div>Add your own components here.</div>
 * </Restricted>
 */
const Restricted = (props: RestrictedProps) => {
  const { isFeatureEnabled } = useContext(FeaturesContext);
  const { state: userState } = useContext(UserContext);
  const { children, feature, permissions } = props;

  /**
   * Check if the current user is allowed to access the component
   */
  const checkIfUserIsAllowedToView = () => {
    let hasAccess = DEFAULT_AUTHORISATION;
    let isEnabled = DEFAULT_AUTHORISATION;

    if (permissions && userState.activeBc) {
      hasAccess = isUserAllowed(userState.activeBc.is_consumer, userState.roles, permissions);
    }

    if (feature) {
      isEnabled = isFeatureEnabled(feature);
    }

    return Boolean(hasAccess && isEnabled);
  };

  // By immediately checking in the default state if the user can view this page,
  // we prevent unnecessary hiding of elements during load.
  const [viewAllowed, setViewAllowed] = useState<null | boolean>(checkIfUserIsAllowedToView());

  /** Permissions and feature need to be added as dependencies. Otherwise the Restricted component won't work when it's
   * implemented multiple times at the same page with different feature and permission settings, as
   * the viewAllowed state would be shared. */
  useEffect(() => {
    setViewAllowed(checkIfUserIsAllowedToView());
  }, [userState.activeBcId, userState.roles, permissions, feature]);

  // Don't do anything yet if viewAllowed isn't resolved yet.
  if (viewAllowed === null) return null;

  // If children is used as render prop, we always render the children with the viewAllowed boolean.
  // Component is then responsible for handling this.
  if (children instanceof Function) return children(viewAllowed);

  // If view is not allowed and no render prop is passed, component will not render.
  if (!viewAllowed) return null;

  // If view IS allowed but no render prop is passed, it's children are simply rendered.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Restricted;
