// eslint-disable-next-line import/no-extraneous-dependencies
import { onError } from '@apollo/client/link/error';
import { logException } from 'utils/error-logging';

/**
 * This link will catch errors and update the exception state
 */

export const errorLink = onError(({ graphQLErrors, operation }) => {
  if (!graphQLErrors) {
    return;
  }

  if (operation.operationName.endsWith('OrFailSilent')) {
    return;
  }

  graphQLErrors.forEach(err => {
    const { message, locations, path } = err;

    logException(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
  });
});
