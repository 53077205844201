import React from 'react';
import { FormattedHTMLMessage, IntlShape } from 'react-intl';

export interface IIdAndValues {
  id: string;
  values: { [key: string]: string | number } | undefined;
}

const isStringOrNumber = (value: any): boolean => {
  return typeof value === 'string' || typeof value === 'number';
};

/**
 * Filter the ID and values array from a rest parameter.
 *
 * @param { idsAndValues }
 * @returns { IIdAndValues }
 */
const getIdAndValues = (idsAndValues: any[]): IIdAndValues => {
  const id = idsAndValues.filter(value => isStringOrNumber(value)).join('.');
  const last = idsAndValues.slice(-1).pop();
  const values = last === Object(last) ? last : undefined;

  return {
    id,
    values,
  };
};

/**
 * Translate text, provide as many arguments as you want. Arguments are joined together with a .
 * Last argument can be an object with values to pass into the translation.
 * Example translate(some.prefix, 'your.key', {msisdn: string})
 * @param {...idsAndValues}
 */
export const translateText = (intl: IntlShape, ...idsAndValues: any[]): string => {
  const { id, values } = getIdAndValues(idsAndValues);

  return intl.formatMessage({ id }, values);
};

/**
 * Translate HTML, provide as many arguments as you want. Arguments are joined together with a .
 * Last argument can be an object with values to pass into the translation.
 * Example translate('some.prefix', 'your.key', {msisdn: string})
 * @param {...idsAndValues}
 */
export const translateHtml = (...idsAndValues: any[]): JSX.Element => {
  const { id, values } = getIdAndValues(idsAndValues);

  return <FormattedHTMLMessage id={id} tagName="span" values={values} />;
};

/**
 * Translate HTML, provide as many arguments as you want. Arguments are joined together with a .
 * First argument is the fallback message.
 * Last argument can be an object with values to pass into the translation.
 * Example translateHtmlWithDefaultMessage('Generic fallback message', 'some.prefix', 'your.key', {msisdn: string})
 * @param { defaultMessage, idsAndValues }
 */
export const translateHtmlWithDefaultMessage = (defaultMessage: string, ...idsAndValues: any[]): JSX.Element => {
  const { id, values } = getIdAndValues(idsAndValues);

  return <FormattedHTMLMessage id={id} tagName="span" values={values} defaultMessage={defaultMessage} />;
};
