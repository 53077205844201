import { PermissionSettings, Roles } from 'models';
import { EVERYONE, ADMIN_POSTPAID_ONLY, ADMIN_ONLY, WITHOUT_BILL } from './_default-settings';
import { none } from 'utils/permissions/permission-conditions';

// Base service permissions which are applied on the main route.
export const SERVICE: PermissionSettings = EVERYONE;

export const ORDER_MANAGEMENT: PermissionSettings = ADMIN_ONLY;
export const PRIVACY: PermissionSettings = WITHOUT_BILL;
export const PERSONAL_DATA_REQUEST = ADMIN_ONLY;
export const PERSONAL_DATA_CHANGE = ADMIN_ONLY;
export const PERSONAL_DATA_REMOVE = ADMIN_ONLY;
export const PERSONAL_DATA_OBJECT = ADMIN_ONLY;
export const RETURN_NETWORK_COMPLAINT: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const SELF_SERVICE: PermissionSettings = [none([Roles.BILL, Roles.DELAYED_PAYMENT, Roles.CEASED_BC])];
export const DEVICE_SIM: PermissionSettings = [none([Roles.BILL, Roles.DELAYED_PAYMENT, Roles.CEASED_BC])];
