export interface INBAOptions {
  // Is the NBA-viewer a consumer account? (Fixes redirects to shop/verlengen and zakelijk/shop/verlengen)
  isConsumer?: boolean;
  // Display the NBA in a certain way.
  displayMode: DisplayModes;
  // Disable addEventListeners when testing a full list of NBA's
  addEventListeners: boolean;
  // Filter the NBA's and show only these types.
  onlyOfType?: string[];
  // Additional template variables as described in INBATemplateVars
  templateVars?: INBATemplateVars;
  // The endpoint to the AEM service that provided the json for the NBA's
  apiEndpoint: string;
}

export type IOptions = Partial<INBAOptions>;

export enum EventTypes {
  IMPRESSION = 'impression',
  CLICK = 'click',
  DISMISS = 'dismiss',
}

export enum DisplayModes {
  LANDSCAPE,
  PORTRAIT,
}

export interface INBA {
  id: string;
  background_color: string;
  large_icon?: boolean;
  icon_src?: string;
  icon_alt?: string;
  title: string;
  pre_title?: string;
  content: string;
  image_src?: string;
  image_alt?: string;
  pricing?: string;
  primary_button_text: string;
  primary_button_url: string;
  secondary_button_text?: string;
  secondary_button_url?: string;
  last_updated_at: string;
}

export interface INBACache {
  [nbaId: string]: INBA;
}

export interface INBATemplateVars {
  [index: string]: any;
  street?: string;
  house_number?: string;
  house_number_extension?: string;
  zipcode?: string;
  city?: string;
}

export interface INBAPegaResponse {
  nba_arr: string[];
}
