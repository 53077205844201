/**
 * These enums are mapped to the names and values as they are in kahuna-backend
 * @see: src/main/java/com/vodafone/hawaii/interfaces/domain/UnitOfMeasure.java
 */

export type MeasurementUnits = DataUnits | CallUnits | OccurenceUnits | OtherUnits;

export enum DataUnits {
  GIGABYTES = 'G',
  MEGABYTES = 'H',
  KILOBYTES = 'K',
  BYTES = 'B',
}

export enum CallUnits {
  MINUTES = 'M',
  MINUTES_HYBRID = 'M-hybrid',
  SECONDS = 'S',
}

export enum OccurenceUnits {
  OCCURENCE = 'O',
  OCCURENCE_HYBRID = 'O-hybrid',
}

export enum OtherUnits {
  CHARGE = 'E',
  CHARGE_CENTS = 'C',
  COST_CENTS = 'CC',
  COST_VOICE_SMS = 'CVS',
  COST_VOICE_SMS_DATA = 'CVSD',
  VALUE_ADDED_SERVICES = 'V',
  UNKNOWN = 'U',
}
