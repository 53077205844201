import React, { ReactNode, useMemo, useState } from 'react';
import { IBreadcrumb } from './Breadcrumb';

export interface BreadcrumbsProviderContext {
  items: IBreadcrumb[];
  add: (item: IBreadcrumb) => void;
  remove: (item: IBreadcrumb) => void;
}

const initialContext: BreadcrumbsProviderContext = {
  items: [],
  add: () => {},
  remove: () => {},
};

export const BreadcrumbsContext = React.createContext<BreadcrumbsProviderContext>(initialContext);

const BreadcrumbsProvider = ({ children }: { children: ReactNode }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumb[]>([]);
  const add = (item: IBreadcrumb) => {
    setBreadcrumbItems(items => [...items, item]);
  };

  const remove = (item: IBreadcrumb) => {
    setBreadcrumbItems(items => {
      const newItems = items.filter((i: IBreadcrumb) => i.url !== item.url);

      return newItems;
    });
  };

  const context = useMemo(() => {
    return {
      items: breadcrumbItems,
      add,
      remove,
    };
  }, [breadcrumbItems, add, remove]);

  return <BreadcrumbsContext.Provider value={context}>{children}</BreadcrumbsContext.Provider>;
};

export default BreadcrumbsProvider;
