import { IBillingCustomer, Roles } from 'models';

/**
 * Returns roles for the current active BC
 * @param bc is the BC we want to define the roles for
 */
export const getRolesFromBillingCustomer = (bc: IBillingCustomer): Roles[] => {
  const roles: Roles[] = [];

  if (!bc) {
    return roles;
  }

  if (bc.user_role) {
    roles.push(Roles[bc.user_role.toUpperCase() as keyof typeof Roles]);
  }

  roles.push(bc.is_prepaid ? Roles.PREPAID : Roles.POSTPAID);

  if (bc.is_eligible_for_naw_bonus) {
    roles.push(Roles.ELEGIBLE_NAW_BONUS);
  }

  if (bc.is_ceased) {
    roles.push(Roles.CEASED_BC);
  }

  if (bc.has_ceased_products) {
    roles.push(Roles.CEASED_PRODUCTS);
  }

  if (bc.has_delayed_payment) {
    roles.push(Roles.DELAYED_PAYMENT);
  }

  return roles;
};

/**
 * Makes sure first does not only contain initials, an email (@),
 * a dot or a comma, and is not all uppercase,
 * to make the message as friendly as possible.
 */
export const isValidFirstName = (firstName: string | undefined) => {
  const stringIsAllUppercase = (word: string) => word === word.toUpperCase();

  if (
    !firstName ||
    firstName.length <= 2 ||
    firstName.includes('@') ||
    firstName.includes('.') ||
    firstName.includes(',') ||
    stringIsAllUppercase(firstName)
  ) {
    return false;
  }

  return true;
};
