import { Loader } from '@vodafoneziggo/sandwich';
import React, { useEffect, useState } from 'react';
import * as S from './loadable-component.styles';

interface LoadableComponentProps {
  children: React.ReactNode | null;
  isLoading: boolean;
}

const LoadableComponent = ({ isLoading, children }: LoadableComponentProps) => {
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setContentVisible(!isLoading);
  }, [isLoading]);

  return (
    <S.Wrapper contentVisible={contentVisible} data-testid="loadable-component">
      <Loader />

      {contentVisible && <div>{children}</div>}
    </S.Wrapper>
  );
};

export default LoadableComponent;
