import {
  Heading,
  SpacingProps,
  ThemeInterface,
  margin,
  media,
  padding,
  spacing,
  themeProp,
} from '@vodafoneziggo/sandwich';
import styled, { css } from 'styled-components';
import { InfoBlockType, InfoBlockVariant } from './InfoBlock';

type Props = {
  type: InfoBlockType;
  variant?: InfoBlockVariant;
} & SpacingProps;

const getColorForType = ({ type, theme }: { type: InfoBlockType; theme: ThemeInterface }) =>
  ({
    [InfoBlockType.DEFAULT]: theme.colors.grayL10,
    [InfoBlockType.SUCCESS]: theme.colors.green,
    [InfoBlockType.WARNING]: theme.colors.orange,
    [InfoBlockType.ERROR]: theme.colors.red,
  }[type]);

export const InfoBlockBase = styled.div<Props>`
  ${spacing}

  position: relative;

  /* Needed for alignment of optional icon next to the content */
  display: flex;
  flex-direction: column;

  /* If contained within a flex parent, it should behave like a block element */
  flex: 1;

  background-color: ${themeProp('colors.white')};
`;

export const Icon = styled.div<Props>`
  color: white;

  .svg-icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  ${props => media.up('sm')`
    
    ${padding.right('small')}

    color: ${getColorForType(props)};

    .svg-icon {
      width: 1.625rem;
      height: 1.625rem;
    }
  `};
`;

export const Content = styled.div`
  width: 100%;
  /** 
   * Should the error contain any elements with a bottom margin, then this rule removes it so the info block
   * is not higher than it should be.
   */
  *:last-child {
    ${margin.bottom('none')}
  }
`;

export const CloseIcon = styled.div`
  cursor: pointer;
  color: white;
  ${media.up('sm')`
    color:currentColor;
  `}
  .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const BlockHeader = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;

  ${padding.all('small', 'normal')}

  background-color: ${props => {
    return getColorForType(props);
  }};
  height: 2.25rem;

  ${props => media.up('sm')`
  height: 0.4rem;
    ${
      props.variant === 'filled'
        ? css`
            ${padding.all('smaller', 'none')}
          `
        : css`
            ${padding.all('none')}
          `
    }
  `}

  ${Icon}, ${CloseIcon} {
    display: block;

    ${media.up('sm')`
      display:none;
    `}
  }
`;

export const BlockContent = styled.div<Props>`
  display: flex;
  flex-direction: row;
  ${padding.all('normal', 'normal', 'medium')}

  border-width: 1px;
  border-style: solid;
  border-color: ${getColorForType};
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;

  ${props =>
    props.variant === 'filled' &&
    css`
      ${padding.all('none', 'normal', 'normal')}
      background-color: ${getColorForType(props)};
      color: white;

      ${media.up('sm')`
              ${padding.bottom('medium')}
            `}

      ${Content} {
        ${padding.top('tiny')}

        ${media.up('sm')`
              ${padding.top('none')}
            `}
      }
      ${Heading.H5}, ${Icon} {
        color: white;
      }
    `}

  ${CloseIcon} {
    margin-left: auto;
  }

  ${Icon}, ${CloseIcon} {
    display: none;

    ${media.up('sm')`
          display:block;
    `}
  }
`;

export const Spacer = styled.div`
  visibility: hidden;

  /* same size as the svg close icon */
  width: 1.5rem;
  height: 1.5rem;
`;
