import Page, {
  CenteredContent,
  Content,
  ContentBlocksWrapper,
  FullWidthContent,
  Sidebar,
  SupportPage,
  TilesWrapper,
  Wrapper,
} from './Page';

export default {
  Page,
  Wrapper,
  TilesWrapper,
  ContentBlocksWrapper,
  Sidebar,
  Content,
  FullWidthContent,
  CenteredContent,
  SupportPage,
};
