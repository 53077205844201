import { UserContext } from 'components/User';
import { ProductSummariesContext } from 'containers/products';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface WrapperProps {
  children: React.ReactNode;
}

export enum AuthStatus {
  READY,
  LOADING,
}

export const AuthWrapperContext = React.createContext(AuthStatus.LOADING);
const PAGES_ALLOWED_WITHOUT_SELECTED_BC = ['/my/maak-een-keuze'];

/**
 * Wrapper component that provides a context with AuthStatus. This status
 * tells any consumer whether the page is ready to render or not, and can be used to
 * render loading states during loading of the user and switching Billing Customer.
 *
 * It also checks the variable PAGES_ALLOWED_WITHOUT_SELECTED_BC defined above. Any path
 * defined in that array will always make the status turn to READY.
 */
const Wrapper = ({ children }: WrapperProps) => {
  const location = useLocation();
  const [pageviewAllowedWithoutSelectedBc, setPageviewAllowedWithoutSelectedBc] = useState(false);
  const { state: userState } = useContext(UserContext);
  const { state: productSummariesState } = useContext(ProductSummariesContext);

  // Some pages must be viewed without a processed user (eg a selected BC).
  // If the user is on one of these pages, the wrapper will only wait for the isLoading
  // boolean of the auth container, but ignores the userProcessed boolean.
  const updatePageviewAllowedWithoutProcessedUser = () => {
    setPageviewAllowedWithoutSelectedBc(
      !!PAGES_ALLOWED_WITHOUT_SELECTED_BC.filter(page => window.location.pathname.startsWith(page)).length
    );
  };

  // On route change update if user is allowed to view the page without processed user.
  useEffect(() => {
    updatePageviewAllowedWithoutProcessedUser();
  }, [location]);

  // We check for user_name because the authenticated user call can also return with only a features object under the user,
  // in this case the My page will not work.
  const hasFetchedUserData = !!userState.user?.user_name;
  const hasSelectedBillingCustomer = typeof userState.activeBc !== 'undefined';
  const hasProductsForBc = productSummariesState.forBc === userState.activeBcId;

  // The page is ready to render if we DO have user data (authenticated user call was successfull),
  // and either the page is allowed to be viewed without a selected BC (see constant defined above),
  // or the user has both selected a billing customer AND a product.
  const pageIsReady =
    hasFetchedUserData && (pageviewAllowedWithoutSelectedBc || (hasSelectedBillingCustomer && hasProductsForBc));

  return (
    <AuthWrapperContext.Provider value={pageIsReady ? AuthStatus.READY : AuthStatus.LOADING}>
      {children}
    </AuthWrapperContext.Provider>
  );
};

export default Wrapper;
