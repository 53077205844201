import { PermissionSettings } from 'models';
import { ADMIN_POSTPAID_ONLY, EVERYONE, NOBODY } from './_default-settings';

export const SMS_CHALLENGE: PermissionSettings = {
  consumer: EVERYONE,
  business: NOBODY,
};

export const BUNDLE_ADVICE: PermissionSettings = {
  consumer: ADMIN_POSTPAID_ONLY,
  business: NOBODY,
};

export const NEXT_BEST_ACTIVITY: PermissionSettings = EVERYONE;

export const STATIC_LINKS: PermissionSettings = EVERYONE;
