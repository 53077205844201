import Axios from 'axios';
import { handleRequest } from './api.utils';
import { ApiResponse } from '.';

export enum BASE_PATH {
  STATIC = '/my/static/',
  KAHUNA_ASSETS = '/_assets/',
  CONFIGS = '/fe-configs/',
}

/**
 * Creating Axios instance
 * This is needed for testing
 */
export const fileApiInstance = Axios.create();

/**
 * Requests GET call from my/statics endpoint
 * @param url relative url to static folder
 */
export const getStatic = (url: string): Promise<ApiResponse> =>
  handleRequest(fileApiInstance, { url: `${BASE_PATH.STATIC}${url}` });

/**
 * Requests GET call from /_assets endpoint
 * @param url relative url to _assets folder
 */
export const getFromKahunaAssets = (url: string): Promise<ApiResponse> =>
  handleRequest(fileApiInstance, { url: `${BASE_PATH.KAHUNA_ASSETS}${url}` });

/**
 * Requests GET call from fe-configs endpoint
 * @param url relative url to vf-frontend-configs repository
 */
export const getFromFeConfigs = (url: string): Promise<ApiResponse> =>
  handleRequest(fileApiInstance, { url: `${BASE_PATH.CONFIGS}${url}` });
