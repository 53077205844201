import { margin, media, padding, themeProp } from '@vodafoneziggo/sandwich';
import styled, { css } from 'styled-components';
import { Col } from 'styles/elements/grid.styles';

interface ContentBlockStyles {
  isClickable: boolean;
}

export const Content = styled.div<ContentBlockStyles>`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${props =>
    props.isClickable
      ? css`
          .content-block__content {
            width: 70%;
          }

          ${media.up('lg')`
            .content-block__navigation {
              width: 25%;
            }
        `}
        `
      : css`
          .content-block__content {
            width: 80%;
            ${margin.bottom('small')}
          }

          .content-block__navigation {
            width: 100%;
          }

          ${media.up('md')`
            .content-block__navigation {
              width: 25%;
            }

            .content-block__content {
              ${margin.bottom('none')}
            }
          `}
        `}

  .content-block__icon {
    width: 50px;
    align-self: center;

    ${media.up('sm')`
      width: 5rem;
      height: 5rem;
    `}

    ${media.up('md')`
      width: 5.5rem;
      height: 5.5rem;
    `}
  }

  .content-block__content {
    flex-wrap: wrap;
    align-self: center;
    justify-content: space-between;

    ${media.up('md')`
      width: 60%;
    `};

    p:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: 0;
    }
  }

  .content-block__navigation {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: auto;

    a,
    button {
      margin: 0.25rem 0;
      width: 100%;
    }
  }
`;

export const HeaderBlock = styled(Col)`
  ${padding.vertical('large')};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentBlock = styled.div`
  ${padding.all('normal')};
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: ${props => props.theme.shadows.base};
  background-color: ${themeProp('colors.white')};

  ${media.up('md')`
    ${padding.all('large')};
  `}

  .content-block__navigation__chevron {
    ${media.up('lg')`
      display: none;
      `};

    .svg-icon {
      width: 1.25rem;
      height: 1.25rem;
      color: ${themeProp('colors.primary')};
    }
  }
`;
