import { TranslatedString } from 'components/Intl';
import React, { useEffect, useState } from 'react';
import { BreadcrumbsContext, BreadcrumbsProviderContext } from './BreadcrumbsProvider';

export interface IBreadcrumb {
  url: string;
  title: TranslatedString;
}

interface BreadcrumbProps {
  url: string;
  title: TranslatedString;
}

interface BreadcrumbComponentProps extends BreadcrumbsProviderContext {
  item: IBreadcrumb;
}

const BreadcrumbComponent = ({ item, add, remove }: BreadcrumbComponentProps) => {
  // we have to remember the current breadcrum so we can remove it when the item prop updates
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<IBreadcrumb>(item);

  useEffect(() => {
    // when the given item changes we have to update the breadcrumb in the state
    if (item !== currentBreadcrumb) {
      setCurrentBreadcrumb(item);
    }
  }, [item.title, item.url]);

  useEffect(() => {
    // add the current breadcrumb to the breadcrumb list in the provider on mount and on state update
    add(currentBreadcrumb);

    return () => {
      // remove the current breadcrumb on component unmount
      remove(currentBreadcrumb);
    };
  }, [currentBreadcrumb.title, currentBreadcrumb.url]);

  return null;
};

/**
 * When placed somewhere in the application, it registers a breadcrumb item for the Breadcrumbs
 * component to render.
 * @param props contain the url and title properties passed to the component
 */
const Breadcrumb = ({ url, title }: BreadcrumbProps) => (
  <BreadcrumbsContext.Consumer>
    {context => context && <BreadcrumbComponent {...context} item={{ url, title }} />}
  </BreadcrumbsContext.Consumer>
);

export default Breadcrumb;
