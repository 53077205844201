import { PermissionSettings, Roles } from 'models';
import { none } from 'utils/permissions/permission-conditions';
import { WITHOUT_USER } from './_default-settings';

export const INVOICES: PermissionSettings = WITHOUT_USER;
export const INVOICES_MENU: PermissionSettings = [[Roles.POSTPAID]];
export const VAT_SPECIFICATION: PermissionSettings = [[Roles.ADMIN], none([Roles.CEASED_BC, Roles.PREPAID])];
export const CONTENT_SERVICES_POSTPAID: PermissionSettings = [[Roles.POSTPAID]];
export const CONTENT_SERVICES_PREPAID: PermissionSettings = [[Roles.PREPAID]];
export const CONTENT_SERVICES_ADMIN: PermissionSettings = [[Roles.ADMIN]];
export const CONTENT_SERVICES_USER: PermissionSettings = [[Roles.USER]];
