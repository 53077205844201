import {
  Icon,
  MEDIA_COMMUNITY,
  MEDIA_FACEBOOKGREY,
  MEDIA_LINKEDINGREY,
  MEDIA_TWITTERGREY,
  MEDIA_YOUTUBEGREY,
  MEDIA_ZIGGOTRANSPARENT,
  Footer as VfFooter,
} from '@vodafoneziggo/sandwich';
import { useTranslations } from 'components/Intl/useTranslations';
import { UserContext } from 'components/User';
import React, { useContext } from 'react';

const intlPrefix = 'footer';

export const Footer = () => {
  const { translate, translateHtml } = useTranslations();
  const { state: userState } = useContext(UserContext);
  const isZiggoCustomer = userState.user?.is_ziggo_customer;

  return (
    <VfFooter
      legalLinks={[
        {
          text: translate(intlPrefix, 'links.legal.link-1'),
          url: 'https://www.vodafone.nl/support/over-deze-website/privacy-en-disclaimer',
        },
        {
          text: translate(intlPrefix, 'links.legal.link-2'),
          url: 'https://www.vodafone.nl/cookies',
        },
        {
          text: translate(intlPrefix, 'links.legal.link-3'),
          url: 'https://www.vodafone.nl/support/abonnement-en-rekening/tarieven-en-voorwaarden.shtml',
        },
      ]}
      socialLinks={[
        {
          ariaLabel: translate(intlPrefix, 'links.social.link-1'),
          text: <Icon icon={MEDIA_COMMUNITY} width="36px" height="36px" />,
          url: 'https://community.vodafone.nl/',
        },
        {
          ariaLabel: translate(intlPrefix, 'links.social.link-2'),
          text: <Icon icon={MEDIA_FACEBOOKGREY} width="36px" height="36px" />,
          url: 'https://www.facebook.com/VodafoneNL',
        },
        {
          ariaLabel: translate(intlPrefix, 'links.social.link-3'),
          text: <Icon icon={MEDIA_TWITTERGREY} width="36px" height="36px" />,
          url: 'https://twitter.com/vodafoneNL',
        },
        {
          ariaLabel: translate(intlPrefix, 'links.social.link-4'),
          text: <Icon icon={MEDIA_LINKEDINGREY} width="36px" height="36px" />,
          url: 'https://www.linkedin.com/company/vodafone',
        },
        {
          ariaLabel: translate(intlPrefix, 'links.social.link-5'),
          text: <Icon icon={MEDIA_YOUTUBEGREY} width="36px" height="36px" />,
          url: 'https://www.youtube.com/user/vodafonenl',
        },
      ]}
      internalLinks={[
        ...(isZiggoCustomer
          ? [
              {
                className: 'converged',
                text: (
                  <>
                    <Icon icon={MEDIA_ZIGGOTRANSPARENT} width="24px" height="24px" />{' '}
                    {translate(intlPrefix, 'links.internal.link-1')}
                  </>
                ),
                url: 'https://www.ziggo.nl/mijnziggo',
              },
            ]
          : []),
        {
          text: translate(intlPrefix, 'links.internal.link-2'),
          url: 'https://www.vodafone.nl/abonnement/mobiel',
        },
        {
          text: translate(intlPrefix, 'links.internal.link-3'),
          url: 'https://www.vodafone.nl/support',
        },
        {
          text: translate(intlPrefix, 'links.internal.link-4'),
          url: 'https://www.vodafone.nl/support/contact/',
        },
      ]}
      address={
        <address>
          {translateHtml(intlPrefix, 'address', {
            currentYear: new Date().getFullYear(),
          })}
        </address>
      }
    />
  );
};
