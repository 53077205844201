import { Gender } from 'models';
import { BillingArrangementBase } from './billing-arrangement.model';

export interface IInvoices {
  billing_arrangements: IBillingArrangement[];
  invoices_type_bill: IBaseInvoice[];
  invoices_type_other: IBaseInvoice[];
  average_invoice_cost: IAverageInvoiceCosts;
  subscriber_count: number;
}

export interface IBaseInvoice {
  number: string;
  billing_arrangment_id?: string;
  type: IInvoiceType;

  // For regular bills, this object consists of the month and year of the invoice. For special
  // invoices this property is empty and should not be used.
  cycle?: IInvoiceCycle;

  // This prop is to be used in favor of the `status` prop. It contains all the information that
  // the `status` prop contains, and more.
  customer_invoice_status: CustomerInvoiceStatus;

  // This property is not to be used or displayed anymore, all information regarding the invoice state
  // should be derived from the `customer_invoice_status` prop
  status: InvoiceStatus;

  // If true, the PDF for the regular bill (not the specification) is already rendered and can
  // be directly downloaded from the server. In all other cases, the availability of the PDF should
  // first be consulted on the backend via a designated API call.
  is_pdf_available: boolean;

  // If true, it means the 'Pay invoice' button can be shown to the user.
  show_pay_deck: boolean;

  payment_pending: boolean;
  details_available: boolean;
  is_first_bill: boolean;
  has_recurring_charges_changed: boolean;

  creation_date: number;
  close_date: number;
  // On this date the invoice passes it's due date.
  due_date: number;

  // The direct-debit will happen on this date. This date is always before the due_date.
  dd_due_date: number;

  paid_date?: number;
  last_direct_debit_extract_date?: number;

  payments?: IPayment[];
  credits?: ICredit[];
  direct_debits?: IDirectDebit[];

  rc_amount?: IVatAmounts;
  due_amount: IVatAmounts;
  uc_amount?: IVatAmounts;
  oc_amount?: IVatAmounts;
  hardware_amount?: IVatAmounts;

  non_disclosed_amount?: IVatAmounts;
  discount_amount?: IVatAmounts;
  credit_amount?: IVatAmounts;

  total_amount_excluding_non_disclosed: IVatAmounts;
  total_amount?: IVatAmounts;
}

export enum CustomerInvoiceStatus {
  OPEN = 'Open',
  PAYMENT_PENDING = 'Payment Pending',
  NOT_OVERDUE = 'Not Overdue',
  PAID = 'Paid',
  NOT_PAID = 'Not Paid',
}

export enum InvoiceStatus {
  CLOSED = 'c',
  OPEN = 'o',
  FINALIZED = 'f',
}

export interface IAverageInvoiceCosts {
  average_total_invoice: IVatAmounts;
  average_usage_charges: IVatAmounts;
}

export interface IVatAmounts {
  amount_including_vat: number;
  amount_excluding_vat?: number;
  vat_amount?: number;
}

interface IInvoiceType {
  id: InvoiceTypes;
  title: string;
}

export enum InvoiceTypes {
  BILL = 'BILL',
  CREDIT_NOTA = 'CN',
  DEBIT_NOTA = 'DN',
  IMMEDIATE_CHANGE = 'IMCG',
  DISHONORED_CHECK = 'DCK', // not sure if this is actively used.
  DEPOSIT = 'DPST', // not sure if this is actively used.
}

export interface IInvoiceCycle {
  code: string;
  month: number;
  year: number;
  start_date: number;
  end_date: number;
}

export interface IPayment {
  payment_id: string;
  date: number;
  amount: number;
  original_amount: number;
  allocated_amount: number;
}
interface ICredit {
  credit_reason: string;
  amount: number;
  tax_amount: number;
  credit_level_code: string;
  balance_impact_code: string;
  restricted_invoice_number: string;
  restricted_charge_id: string;
  memo_text: string;
  allocated_amount: number;
}
export interface IDirectDebit {
  direct_debit_voucher: string;
  payment_method: string;
  request_type: string;
  request_status: string;
  status_reason_code: string;
  status_update_date: number;
  requested_amount: number;
  actual_amount: number;
  extract_date: number;
  credit_card_type: string;
  credit_card_number: string;
  cc_expiry_date: string;
  bank_code: string;
  bank_account_number: string;
  ddr_source: string;
}

/**
 * You might have noticed that invoices have their own BillingArrangement interface. This is
 * because although the invoices api response contains billing arrangements, the props of those
 * billing arrangements do not match those of the IBillingArrangement interface defined in the
 * billing arrangement model
 */
export interface IBillingArrangement extends BillingArrangementBase {
  primary_contact: IPrimaryContact;
  invoice_address: IInvoiceAddress;
  pay_means: IPayMeans;
  is_current: boolean;
  subscriber_count: number | string; // "" (empty string) when 0
  delivery_options: IDeliveryOptions;
}

interface IDeliveryOptions {
  bill_delivery_method: DeliveryOptions;
}

export enum DeliveryOptions {
  EBILL = 'EBill',
  PAPER = 'Paper',
}

interface IPrimaryContact {
  gender: Gender;
  first_name: string;
  last_name: string;
  full_name: string;
}

interface IInvoiceAddress {
  address_id: string;
  street_name: string;
  house_number: string;
  house_number_extension?: string;
  zip_code: string;
  city: string;
  country: string;
}
interface IPayMeans {
  bank_account_number: string;
  bank_account_name: string;
  payment_type: PaymentTypes;
}

export enum PaymentTypes {
  CASH = 'Cash',
  DIRECT_DEBIT = 'Direct Debit',
  CREDIT_CARD = 'Credit Card',
}

export enum PayMeans {
  IDEAL = 'IDEAL',
  CREDITCARD = 'CREDITCARD',
}

export enum PayReturnTarget {
  WEB = 'web',
  APP = 'app',
}
