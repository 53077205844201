import { Color, FontLevels, Spacing, themeProp } from '@vodafoneziggo/sandwich';
import { Button } from '@vodafoneziggo/sandwich/components/button/button.styles';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${themeProp('colors.white')};
  box-shadow: ${themeProp('shadows.base')};
  border-radius: 0.375rem;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  padding: ${Spacing.S};
  border-bottom: 0.0625rem ${Color.GRAY_300} solid;

  div + h5 {
    margin-left: ${Spacing.XS};
  }
`;

export const CardIcon = styled.div`
  width: 2.25rem;
  height: 2.25rem;

  .svg-icon {
    width: 100%;
    height: 100%;
  }
`;

export const CardBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: ${Spacing.S} ${Spacing.S} 0;
`;

export const CardContent = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  padding-bottom: ${Spacing.S};
  align-items: center;
  ${FontLevels.LEVEL_7};
`;

export const CardSubContent = styled.div`
  position: relative;
  padding: ${Spacing.XS} 0;
  border-top: 0.0625rem ${Color.GRAY_300} solid;
  ${FontLevels.LEVEL_7};
`;

export const CardSubContentTitle = styled.div`
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15.625rem;
  margin-bottom: ${Spacing.XS};
`;

export const CardBottom = styled.div`
  padding: ${Spacing.XS} 0;
  border-top: 0.0625rem ${Color.GRAY_300} solid;
  ${FontLevels.LEVEL_7};

  div:not(:first-child) {
    margin-top: ${Spacing.XS};
    padding-top: ${Spacing.XS};
    border-top: 0.0625rem ${Color.GRAY_300} solid;
  }
`;

export const CardButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: ${Spacing.S};

  /* Buttons should be a little bit spaced */
  ${Button} {
    margin: 0;
  }

  ${Button} + ${Button} {
    margin-top: ${Spacing.S};
  }
`;

export const CardFooter = styled.div`
  background-color: ${themeProp('colors.white')};
  border-top: 1px ${Color.GRAY_300} solid;
  margin-top: auto;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
`;

export const CardLoader = styled.div`
  min-height: 1rem;
  width: 100%;
  margin: 0 ${Spacing.XS};
  padding: ${Spacing.XS};
`;
