import styled from 'styled-components';

export const UserLoadingIndicator = styled.span`
  display: inline-flex;
  align-items: baseline;
  width: 60%;
  position: relative;
  top: 0.25rem;

  > div {
    margin: 0;
    display: block;
  }
`;
