import { LocalStorage } from '../../utils/storage';
import { PRODUCTS_LOCALSTORAGE_KEYS, USER_LOCALSTORAGE_KEYS } from './auth.config';

/**
 * Small utility function that clears all product and user localstorage keys.
 * Used during logout.
 */
export const clearStorageOnLogout = () => {
  const storageKeys = [
    ...Object.keys(PRODUCTS_LOCALSTORAGE_KEYS).map(key => PRODUCTS_LOCALSTORAGE_KEYS[key]),
    ...Object.keys(USER_LOCALSTORAGE_KEYS).map(key => USER_LOCALSTORAGE_KEYS[key]),
  ];

  storageKeys.forEach(key => LocalStorage.remove(key));
};
