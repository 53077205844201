import { Color, Heading, Spacing, media } from '@vodafoneziggo/sandwich';
import styled, { css } from 'styled-components';
import { ListItem } from '../LinkList/link-list.styles';

const tileBorder = css`
  border: solid 1px ${props => props.theme.colors.grayL20};
`;

const tileSelectedBorder = css`
  border: solid 1px ${props => props.theme.colors.primary};
`;

const tileShadow = css`
  box-shadow: ${props => props.theme.shadows.base};
`;

export const Tile = styled.div<{ hasBorder?: boolean; hasShadow?: boolean; isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  ${props => props.hasBorder && (props.isSelected ? tileSelectedBorder : tileBorder)};
  ${props => (props.hasShadow ? tileShadow : '')};
  transition: box-shadow ${props => props.theme.transitions.default};
  border-radius: 0.375rem;
`;

export const TileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${Spacing.S};
  border-bottom: 1px solid ${Color.GRAY_300};
`;

export const TileHeaderIcon = styled.div`
  margin-right: 0.5rem;
`;

export const TileHeaderTitle = styled(Heading.H5)`
  width: 100%;
  font-weight: ${props => props.theme.fonts.weights.bold};
  padding-top: 0.125rem;
  margin: 0;
`;

export const TileContent = styled.div`
  padding: ${Spacing.S};
  height: 100%;

  & p:last-child {
    margin-bottom: ${Spacing.OFF};
  }

  ${ListItem} {
    &:first-child {
      padding-top: ${Spacing.OFF};
    }
    &:last-child {
      padding-bottom: ${Spacing.OFF};
    }
  }
  & + & {
    border-top: 1px solid ${Color.GRAY_300};
  }
`;

export const TileFooter = styled.div`
  padding: ${Spacing.S};
  border-top: 1px solid ${Color.GRAY_300};

  ${media.up('md')`
    display: flex;
  `}
`;

export const TileMessage = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 3.1875rem;
  font-size: 1.125rem;

  ${media.up('md')`
    /* Additional padding for the icon */
    padding-left: 2.75rem;
  `}
`;

export const TileMessageIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  width: 2.125rem;
  height: 2.125rem;

  .svg-icon {
    width: 100%;
    height: 100%;
  }

  display: none;

  ${media.up('md')`
    display: block;
  `}
`;
