import { Roles } from 'models';
import { none } from 'utils/permissions/permission-conditions';

export const ADMIN_POSTPAID_ONLY = [[Roles.POSTPAID], [Roles.ADMIN]];
export const ADMIN_ONLY = [[Roles.ADMIN]];

export const POSTPAID_WITHOUT_BILL = [[Roles.POSTPAID], none([Roles.BILL])];
export const WITHOUT_BILL = [[Roles.PREPAID, Roles.POSTPAID], none([Roles.BILL])];
export const WITHOUT_USER = [[Roles.POSTPAID], none([Roles.USER])];
export const USER_POSTPAID_ONLY = [[Roles.POSTPAID], [Roles.USER]];

export const EVERYONE = [
  [Roles.POSTPAID, Roles.PREPAID],
  [Roles.ADMIN, Roles.USER, Roles.USER_AND_BILL, Roles.BILL],
];
export const NOBODY = [];

export const CONSUMER_ONLY = {
  consumer: EVERYONE,
  business: NOBODY,
};

export const BUSINESS_ONLY = {
  consumer: NOBODY,
  business: EVERYONE,
};
