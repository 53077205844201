import { PermissionSettings, Roles } from 'models';
import { none } from 'utils/permissions/permission-conditions';
import { WITHOUT_USER } from './_default-settings';

// Base profile permissions which are applied on the main route.
export const PROFILE: PermissionSettings = [[Roles.POSTPAID]];

export const GET_INFO_BILLING_ARRANGEMENTS: PermissionSettings = WITHOUT_USER;
export const CONTACT_ADDRESS: PermissionSettings = [[Roles.ADMIN], none([Roles.PREPAID])];
export const INVOICE_ADDRESS: PermissionSettings = [none([Roles.PREPAID, Roles.USER, Roles.CEASED_BC])];
export const IBAN_DETAILS: PermissionSettings = [none([Roles.USER, Roles.PREPAID])];
export const RED_TOGETHER: PermissionSettings = [
  [Roles.ADMIN],
  none([Roles.CEASED_BC, Roles.PREPAID, Roles.DELAYED_PAYMENT]),
];
export const INVITE_USERS: PermissionSettings = [
  [Roles.ADMIN],
  none([Roles.CEASED_BC, Roles.PREPAID, Roles.DELAYED_PAYMENT]),
];
