import { JWT, LogUtil, OidcService, Token } from '@hawaii-framework/oidc-implicit-core';

export declare type Domain = 'vz_sav' | 'vz_saz' | 'selected_salesforce_id';
export interface CcamContext {
  domain: Domain;
  commonId: string;
}
type ValueOf<Obj> = Obj[keyof Obj];
type OneOnly<Obj, Key extends keyof Obj> = { [key in Exclude<keyof Obj, Key>]: null } & Pick<Obj, Key>;
type OneOfByKey<Obj> = { [key in keyof Obj]: OneOnly<Obj, key> };
export type OneOfType<Obj> = ValueOf<OneOfByKey<Obj>>;

type JWTWithDomain = JWT &
  OneOfType<{
    vz_sav: string;
    vz_saz: string;
    selected_salesforce_id: string;
  }>;

function validateTokenContext(context: CcamContext) {
  return (token: Readonly<Token>) => {
    if (!token.access_token) return false;

    const accessToken = OidcService.parseJwt(token.access_token) as JWTWithDomain;

    // rove june 2024: i think this is wrong, commonId == the contact of the BC, but i think CIAM always returns the contactId of the login which can be different
    const isValid = accessToken[context.domain] === context.commonId;

    LogUtil.debug(
      'OidcPluginCcam: validateTokenContext:',
      isValid,
      accessToken,
      accessToken[context.domain],
      context.commonId,
      context.domain
    );

    return isValid;
  };
}

/**
 *
 * @param scopes a list of scopes
 * @param context
 * @returns A Promise
 */
export function checkCcamSession(scopes: string[], context: CcamContext): Promise<Token> {
  return OidcService.checkSession({
    scopes,
    customTokenValidator: validateTokenContext(context),
  });
}

export function getStoredCcamToken(scopes: string[], context: CcamContext): Token | null {
  return OidcService.getStoredToken({
    scopes,
    customTokenValidator: validateTokenContext(context),
  });
}

export function checkIfCcamTokenExpiresAndRefreshWhenNeeded(
  token: Token,
  scopes: string[],
  context: CcamContext
): Promise<void> {
  return OidcService.checkIfTokenExpiresAndRefreshWhenNeeded(token, {
    customTokenValidator: validateTokenContext(context),
    scopes,
  });
}

/**
 * deletes all CCAM tokens (any token with the `ccam` scope).
 */
export function deleteCcamTokens(): void {
  LogUtil.debug('OidcPluginCcam: deleteCcamTokens');
  OidcService.deleteStoredTokens(token => {
    // delete tokens without access_token
    if (!token.access_token) return false;

    const accessToken = OidcService.parseJwt(token.access_token);

    // anything with the ccam scope should NOT remain in the store.
    return !accessToken.scope.includes('ccam');
  });
}

export const determineCorrectCCAMHost = () => {
  if (!window) return '';

  const { hostname } = window.location;

  switch (true) {
    // case hostname.includes('test'):
    //   return 'https://api.dev.aws.ziggo.io/v1/api/my-care/graphql';

    // case hostname.includes('acc'):
    //   return 'https://api.acc.aws.ziggo.io/v1/api/my-care/graphql';

    case hostname.includes('prv'):
      return 'https://auth.prv.vodafoneziggo.nl';

    // in dev we don't check CCAM headers
    case hostname.includes('localhost'):
    case hostname.includes('kahuna'):
      return '';

    default:
      return 'https://auth.vodafoneziggo.nl';
  }
};
