import { Spacing } from '@vodafoneziggo/sandwich';
import { TileContent } from 'components/Tiles/tiles.styles';
import styled from 'styled-components';

const bgImgDesktop = require('@images/girl-in-red-coat-with-phone3.jpg');

export const MaintenanceContainer = styled.div`
  background-image: url(${bgImgDesktop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 600px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledTileContent = styled(TileContent)`
  padding: ${Spacing.XL};
`;
