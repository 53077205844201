import { UserContext } from 'components/User';
import { Roles } from 'models';
import React, { useContext } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import Restricted, { RestrictedSettings } from './Restricted';

type RestrictedRouteProps = RestrictedSettings & RouteProps;

const RestrictedRoute = (props: RestrictedRouteProps) => {
  const { children, feature, permissions, noPermissionRedirectUrl } = props;
  const { state: userState } = useContext(UserContext);

  /**
   * Determine the url where the customer needs to be redirected to. This is bases on
   * the role(s) the user has.
   */
  const getMyRedirectUrl = (): { path: string } | undefined => {
    if (!userState.activeBc || !userState.roles) {
      return;
    }

    switch (true) {
      case userState.roles.includes(Roles.PREPAID):
        return { path: '/prepaid' };

      // A BILL user, ceased BC, or an active BC with a delayed payment, should always be redirected to 'rekeningen'
      case userState.roles.includes(Roles.BILL):
      case userState.roles.includes(Roles.DELAYED_PAYMENT):
      case userState.roles.includes(Roles.CEASED_BC):
        return { path: '/rekeningen' };

      default:
        return { path: '/' };
    }
  };

  return (
    <Restricted feature={feature} permissions={permissions}>
      {viewAllowed => {
        // The user has access to this restriction, so just render the route
        if (viewAllowed) {
          return children;
        }

        // Allow to redirect outside the My environment
        // For example; to redirect the user to a support page explaining why he has no access...
        // ...or how to access certain parts of My Vodafone.
        if (noPermissionRedirectUrl) {
          window.location.href = noPermissionRedirectUrl;

          return null;
        }

        // If the user does not have access, we will try to redirect them to a different destination.
        // If no redirectUrl could be determined, then just do nothing.
        const myRedirectUrl = getMyRedirectUrl();

        if (!myRedirectUrl) {
          return null;
        }

        return <Navigate to={myRedirectUrl.path} replace />;
      }}
    </Restricted>
  );
};

export default RestrictedRoute;
