import { UserContext } from 'components/User';
import { NEXT_BEST_ACTIVITY } from 'config/permissions';
import { ProductSummariesContext } from 'containers/products/productSummariesContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { isUserAllowed } from 'utils/permissions';
import { DisplayModes, INBA, NBA } from './package';

interface Props {
  /**
   * When provided, will load the NBA that matches the value. E.g. 'MCC_RET_123'
   */
  forceNba?: string;

  /**
   * Sets the display mode of the NBA. Defaults to 'LANDSCAPE'
   */
  displayMode?: DisplayModes;

  /**
   * If true, the NBA will only be shown if the user has only one product
   */
  singleProductOnly?: boolean;
}

const useNba = ({
  // Note: displayMode is set to 0 because when running unit tests, they fail when we use the enum value 'LANDSCAPE'
  displayMode = 0,
  singleProductOnly,
  forceNba,
}: Props) => {
  const userContext = useContext(UserContext);
  const { state: productSummariesState } = useContext(ProductSummariesContext);
  const [state, setState] = useState<{ nba: INBA; markup: string | null } | undefined>();
  const nbaInstance = useRef<NBA | null>(null);

  const { state: userState } = userContext;

  // Update NBA whenever billing customer or current product changes
  useEffect(() => {
    async function getNba() {
      // Only continue if we have a resolved billing customer
      if (!userState.activeBc) {
        return;
      }

      // Check if this NBA only should be visible for BC's with 1 product. If that option is set,
      // we check if the current BC has indeed 1 product. If that is false, we don't render any NBA.
      if (singleProductOnly && userState.activeBc.nr_products !== 1) {
        return;
      }

      // If the NBA feature is off, or the user does not have the permissions to view them, then don't continue
      if (!isUserAllowed(userState.activeBc.is_consumer, userState.roles, NEXT_BEST_ACTIVITY)) {
        return;
      }

      // Initializes the NBA package class. We assign this to a ref so we're sure that we use the same instance
      // during rerenders.
      nbaInstance.current = new NBA();

      nbaInstance.current.setOptions({
        isConsumer: userState.activeBc.is_consumer,
        templateVars: userState.activeBc.primary_contact.address,
        displayMode,
      });

      // Collect the markup and content from the NBA package and set them to state
      const result = await nbaInstance.current.getNba(forceNba ? [forceNba] : undefined);

      if (!result) return;

      setState({
        ...result,

        // If markup could not be resolved it is set to 'null' so it can be rendered in JSX
        markup: result.markup || null,
      });
    }

    getNba();
  }, [userState.activeBc, productSummariesState.currentProduct]);

  return state;
};

export default useNba;
