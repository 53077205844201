import { IConversation } from 'models/conversation.model';
import {
  CustomerInvoiceStatus,
  IDirectDebit,
  IInvoiceCycle,
  InvoiceStatus,
  IPayment,
  IVatAmounts,
} from 'models/invoice.model';

export enum InvoicesConversations {
  RECURRING_CHANGED_BILL = 'recurring_changed_bill',
  FIRST_BILL = 'first_bill',
  USAGE_CHARGES_BILL = 'usage_charges_bill',
  SPECIAL_BILL = 'special_bill',
}

export enum PegaCategories {
  DIVERGENT_BILL_ACQUISITION = 'MCC_SRV_SRV_1070', // Divergent bill acquisition
  DIVERGENT_BILL_INLIFEMIGRATION = 'MCC_SRV_SRV_1071', // Divergent bill in-life migration
  DIVERGENT_BILL_RETENTION = 'MCC_SRV_SRV_1072', // Divergent bill retention
  CONTRACT_TERMINATION = 'MCC_SRV_SRV_1073', // Cancellation confirmation
}

// Combining all Pega Categories related to divergent bills in an array, so we can check easily without too much hard coding
export const divergentBillPegaCategories = [
  PegaCategories.DIVERGENT_BILL_ACQUISITION,
  PegaCategories.DIVERGENT_BILL_INLIFEMIGRATION,
  PegaCategories.DIVERGENT_BILL_RETENTION,
];

export interface IInvoiceConversationBase extends IConversation<InvoicesConversations> {
  invoice_data?: IConversationInvoiceBase[];
}

export interface IInvoiceConversation extends IConversation<InvoicesConversations> {
  invoice_data?: IConversationInvoice[];
}

export interface IConversationInvoiceBase {
  key_invoice_id: string; // Invoice number
  billing_arrangement_id: string;
  customer_invoice_status: CustomerInvoiceStatus;
  cycle: IInvoiceCycle;
  due_date: number;
  dd_due_date: number;
  direct_debits: IDirectDebit[];
  paid_date: number;
  payment_pending: boolean;
  payments: IPayment[];
  show_pay_deck: boolean;
  status: InvoiceStatus;
  total_cost: IVatAmounts;
}

export interface IConversationInvoice extends IConversationInvoiceBase {
  url: string;
}
