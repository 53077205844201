import { DisplayModes, INBA } from '../types/types';
import './template.css';

class NBAtemplate_template {
  public static getTemplate = (nba: INBA, displayMode: DisplayModes): string => {
    switch (displayMode) {
      case DisplayModes.LANDSCAPE:
        return NBAtemplate_template.getLandscape(nba);
      case DisplayModes.PORTRAIT:
        return NBAtemplate_template.getPortrait(nba);
      default:
        throw new Error(`Displaymode '${displayMode}' is not supported for this template in NBA package.`);
    }
  };

  /**
   * @param { nba } INBA
   * @returns { string }
   */
  private static getLandscape = (nba: INBA): string => {
    return `<div class="vfz-nba vfz-nba--${nba.background_color} vfz-nba--variant-landscape" id="nba-${nba.id}">
      <div class="container vfz-nba__container">
        ${
          nba.icon_src
            ? `
          <div class="vfz-nba__icon ${nba.large_icon ? `vfz-nba__icon--large` : ``}">
            <img class="vfz-nba__icon-src" src="${nba.icon_src}" alt="${nba.image_alt}" />
          </div>`
            : ``
        }
        <div class="vfz-nba__body">
          ${nba.pre_title ? `<div class="vfz-nba__body-pre-title vfz-nba__aem-rte-styles">${nba.pre_title}</div>` : ``}
          <h2 class="vfz-nba__body-title"><a href="${nba.primary_button_url}" data-click-event="title">${
      nba.title
    }</a></h2>
          <div class="vfz-nba__body-content vfz-nba__aem-rte-styles">${nba.content}</div>
        </div>
        ${
          nba.image_src
            ? `
          <div class="vfz-nba__image">
            <a href="${nba.primary_button_url}" data-click-event="center_image">
              <img class="vfz-nba__image-src" src="${nba.image_src}" alt="${nba.image_alt}" />
            </a>
          </div>`
            : ``
        }
        <div class="vfz-nba__actions">
          ${nba.pricing ? `<div class="vfz-nba__pricing vfz-nba__aem-rte-styles">${nba.pricing}</div>` : ``}
          <a class="vfz-nba__button vfz-nba__button--primary" data-click-event="primary" href="${
            nba.primary_button_url
          }">${nba.primary_button_text}</a>
          ${
            nba.secondary_button_url && nba.secondary_button_text
              ? `<a class="vfz-nba__button vfz-nba__button--secondary" data-click-event="secondary" href="${nba.secondary_button_url}">${nba.secondary_button_text}</a>`
              : ``
          }
          <a class="vfz-nba__dismiss" href="#" data-click-event="dismiss">Verberg deze melding</a>
        </div>
      </div>
    </div>`;
  };

  /**
   * The portrait mode of a NBA.
   *
   * Apart from styling changes, the following programmatic changes apply.
   * 1. We always show a white NBA
   * 2. If there is a image, that will be used as the icon and shown in its large variant
   *
   * @param { nba } INBA
   * @returns { string }
   */
  private static getPortrait = (_nba: INBA): string => {
    const nba = _nba.image_src
      ? Object.assign(_nba, {
          large_icon: true,
          icon_src: _nba.image_src,
          icon_alt: _nba.image_alt,
        })
      : _nba;

    return `<div class="vfz-nba vfz-nba--white vfz-nba--variant-portrait" id="nba-${nba.id}">
      <div class="container vfz-nba__container">
        ${
          nba.icon_src
            ? `
          <div class="vfz-nba__icon ${nba.large_icon ? `vfz-nba__icon--large` : ``}">
            <img class="vfz-nba__icon-src" src="${nba.icon_src}" alt="${nba.icon_alt}" />
          </div>`
            : ``
        }
        <div class="vfz-nba__body">
          ${nba.pre_title ? `<div class="vfz-nba__body-pre-title vfz-nba__aem-rte-styles">${nba.pre_title}</div>` : ``}
          <h2 class="vfz-nba__body-title"><a href="${nba.primary_button_url}" data-click-event="title">${
      nba.title
    }</a></h2>
          <div class="vfz-nba__body-content vfz-nba__aem-rte-styles">${nba.content}</div>
        </div>
        <div class="vfz-nba__actions">
          ${nba.pricing ? `<div class="vfz-nba__pricing vfz-nba__aem-rte-styles">${nba.pricing}</div>` : ``}
          <a class="vfz-nba__button vfz-nba__button--primary" data-click-event="primary" href="${
            nba.primary_button_url
          }">${nba.primary_button_text}</a>
          ${
            nba.secondary_button_url && nba.secondary_button_text
              ? `<a class="vfz-nba__button vfz-nba__button--secondary" data-click-event="secondary" href="${nba.secondary_button_url}">${nba.secondary_button_text}</a>`
              : ``
          }
          <a class="vfz-nba__dismiss" href="#" data-click-event="dismiss">Verberg deze melding</a>
        </div>
      </div>
    </div>`;
  };
}

export default NBAtemplate_template;
