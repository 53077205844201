export const getQueryStringParams = (query: string): { [key: string]: string } => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params: { [key: string]: string }, param: string) => {
          const [key, value] = param.split('=');

          return {
            ...params,
            [key]: value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '',
          };
        }, {})
    : {};
};

/**
 * Get a query string parameter by its name
 *
 * @param name The QueryString parameter to search for
 */
export const getQueryStringParamByName = (name: string): string | undefined => {
  const params = getQueryStringParams(decodeURIComponent(window.location.search));

  return name in params ? params[name] : undefined;
};

/**
 * Returns true if the url provided is not part of this module, so does
 * not start with, or contain, /my
 *
 * @param url is the url to check
 */
export const isExternalUrl = (url: string): boolean => {
  const anchor = window.document.createElement('a');

  anchor.href = url;

  return !anchor.pathname.startsWith('/my');
};

/**
 * Returns true if the url provided is not external and includes `.shtml`
 * which is a sufficient indication that the url is for my-old.
 *
 * @param url is the url to check
 */
export const isMyOldUrl = (url: string): boolean => {
  const isExternal = isExternalUrl(url);

  if (isExternal) return false;

  return url.indexOf('.shtml') !== -1;
};
