import {
  ApolloClient,
  ApolloLink,
  InMemoryCache, // Reference,
  createHttpLink, // defaultDataIdFromObject,
} from '@apollo/client';
import { authLink } from './links/auth.link';
import { errorLink } from './links/error.link';
import { networkErrorLinks } from './links/networkError.link';
import { uriLink } from './links/uri.link';

export const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([...networkErrorLinks(2), errorLink, uriLink, authLink, createHttpLink()]),
  cache: new InMemoryCache({}),
});
