import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import axios, { AxiosRequestConfig } from 'axios';
import { DEFAULT_TOKEN_VALIDATION_OPTS } from 'containers/auth/scopes';
import { IErrorMessages, mapErrorsToErrorMessages } from './error';

const API_BASE_PATH = '/rest/';

export interface ApiResponse {
  data?: any;
  status?: number;
  error?: {
    request_validation_errors: any;
    item_validation_errors: any;
  };
  headers?: {
    [key: string]: string;
  };
}

/*
 * Since axios 0.19 extending the axios config is broken. There are numerous
 * pr's and efforts to fix it, but it hasn't been fixed for now..
 *
 * Therefore this custom apiconfig interface is created and used as an object
 * passed to our api() method.
 */
export interface ApiConfig {
  getRawData?: boolean;
  refreshOidcToken?: boolean;
  omitAuthHeader?: boolean;
  getDefaultResponse?: boolean;
}

/**
 * Combined axios config with axios defaults and custom properties.
 */
export type ExtendedAxiosRequestConfig = AxiosRequestConfig & ApiConfig;

/**
 * Default custom Apiconfig properties. Variable is exported so mocking is easier.
 */
export const defaultApiConfig: ApiConfig = {
  refreshOidcToken: true,
  getRawData: false,
  omitAuthHeader: false,
  getDefaultResponse: false,
};

export const apiInstance = axios.create({
  baseURL: API_BASE_PATH,
});

export const setAuthHeader = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const token = OidcService.getStoredToken(DEFAULT_TOKEN_VALIDATION_OPTS);

  if (token) {
    config.headers.Authorization = OidcService.getAuthHeader(token);

    if ((token.expires || 0) - Math.round(new Date().getTime() / 1000.0) < 300) {
      OidcService.silentRefreshAccessToken();
    }

    return config;
  }

  try {
    // The check session method will either return
    // that the user is indeed logged in, or redirect
    // the user to the login page. This redirection
    // will be triggered automatically by the library.
    const isLoggedIn = await OidcService.checkSession();

    if (isLoggedIn) {
      // eslint-disable-next-line no-param-reassign
      config = await setAuthHeader(config);

      return config;
    }
  } catch (err) {
    // Don't act on errors, as this already means the user is not logged in
  }

  throw new axios.Cancel('User is not logged in');
};

// Add a request interceptor
export const authHeaderInterceptor = apiInstance.interceptors.request.use(
  /* eslint-disable-next-line require-await */
  async (config: ExtendedAxiosRequestConfig) => {
    if (!config.omitAuthHeader) {
      return setAuthHeader(config);
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * API Method simply calls the Axios api, but transforms the backend status codes
 * (inside the data) to correct thens / catches.
 * @param config
 */
/* eslint-disable-next-line require-await */
export const api = async (config: ExtendedAxiosRequestConfig) => {
  const configWithDefaults: ExtendedAxiosRequestConfig = {
    ...defaultApiConfig,
    ...config,
  };

  // Check if the token expires in the next (x) seconds,
  // if so, set trigger a silent refresh of the Access Token in the OIDC Service.
  // if (configWithDefaults.refreshOidcToken) {
  //   await OidcUtil.attemptTokenRefresh();
  // }

  if (configWithDefaults.getDefaultResponse) {
    return apiInstance(configWithDefaults as AxiosRequestConfig);
  }

  return new Promise((resolve, reject) => {
    apiInstance(configWithDefaults as AxiosRequestConfig)
      .then(response => {
        if (response.data.status < 400) {
          if (configWithDefaults.getRawData) {
            resolve(response.data);
          } else {
            resolve({
              data: response.data.data.length === 1 ? response.data.data[0] : response.data.data,
              status: response.data.status,
            });
          }
        } else {
          const errorMessages: IErrorMessages = mapErrorsToErrorMessages(response.data.error);

          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            errorMessages,
            error: response.data.error,
            status: response.data.status,
            data:
              response.data.data.length === 1 && !configWithDefaults.getRawData
                ? response.data.data[0]
                : response.data.data,
          });
        }
      })
      .catch(error => {
        // Sorry for all the null checks. Tried changing this to optional chaining, but Typescript 3.7
        // had some weird bugs which we couldn't solve right now. And I don't want to introduce a lodash.get or IDX
        // only for this.

        const errorArray =
          error && error.response && error.response.data && error ? error.response.data.error : undefined;
        const errorMessages: IErrorMessages = mapErrorsToErrorMessages(errorArray);

        /* eslint-disable-next-line prefer-promise-reject-errors */
        reject({
          errorMessages,
          error: errorArray,
          status: error?.response?.data?.status ?? undefined,
          data:
            error && error.response && error.response.data && error.response.data.data
              ? error.response.data.data[0]
              : undefined,
        });
      });
  });
};

/**
 * Requests GET call from my/rest endpoint
 * @param url relative url
 * @param data optional payload
 * @returns an object or an array with more than one objects
 */
export const get = (url: string, data?: any, config: ExtendedAxiosRequestConfig = {}): Promise<any> => {
  return api({
    ...config,
    url,
    data,
    method: 'get',
  });
};

export const post = (url: string, data?: any, config: ExtendedAxiosRequestConfig = {}): Promise<any> => {
  return api({ ...config, url, data, method: 'post' });
};

export const patch = (url: string, data?: any, config: ExtendedAxiosRequestConfig = {}): Promise<any> => {
  return api({ ...config, url, data, method: 'patch' });
};

export const put = (url: string, data?: any, config: ExtendedAxiosRequestConfig = {}): Promise<any> => {
  return api({ ...config, url, data, method: 'put' });
};

export const del = (url: string, data?: any, config: ExtendedAxiosRequestConfig = {}): Promise<any> => {
  return api({ ...config, url, data, method: 'delete' });
};

export const setHeader = (key: string, value: string) => {
  apiInstance.defaults.headers.common[key] = value;
};

/**
 * Set an option of the defaultApiConfig.
 *
 * @param key string
 * @param value boolean
 */
export const setDefaultApiConfigOption = (key: keyof ApiConfig, value: boolean) => {
  defaultApiConfig[key] = value;
};

export default {
  apiInstance,
  get,
  post,
  put,
  patch,
  setHeader,
  delete: del,
};
