import RelayService from '@vodafonepub/relay-service';
import { SpacingProps } from '@vodafoneziggo/sandwich';
import {
  CORPORATE_ATTENTIONCIRCLEFILL,
  CORPORATE_CHECKMARKCIRCLEFILL,
  CORPORATE_CLOSE,
  CORPORATE_INFOCIRCLEFILL,
  CORPORATE_WARNINGTRIANGLEFILL,
  Icon,
} from '@vodafoneziggo/sandwich/icons';
import React, { useEffect, useState } from 'react';
import { childrenToText } from 'utils/children-to-text';
import * as S from './info-block.styles';

/**
 * In Vodafone Foundations this component is known as Notification
 * not as InfoBlock
 */

type InfoBlockProps = {
  children: React.ReactNode;
  className?: string;

  /**
   * An optional icon to be shown alongside the content
   */
  icon?: Icon;

  /**
   * An optional testId, if not provided `info-block-{type}` will be used instead
   */
  testId?: string;

  /**
   * Optional label, will be used as the label for the RelayService impression event. If not provided, the
   * children of the infoblock will be parsed to a string and used instead.
   */
  label?: string;

  /**
   * True if the infoblock should be closable by the user
   */
  isClosable?: boolean;

  /**
   * A function that can be called when the Info Block is dismissed
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  notifyOnClose?: Function;

  /**
   * Whether the InfoBlock should be the default or filled variant
   */
  variant?: InfoBlockVariant;
} & SpacingProps;

type InfoBlockBaseProps = {
  type: InfoBlockType;
} & InfoBlockProps;

const sendDataToDDM = (type: InfoBlockType, label: string) => {
  RelayService.trigger('ga.event', {
    ga_data: {
      event: 'notification',
      interaction_type: 'impression',
      event_location: window.location.href,
      creative_type: type,
      creative_title: '',
      creative_text: label,
    },
  });
};

export enum InfoBlockType {
  DEFAULT = 'default',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum InfoBlockVariant {
  DEFAULT = '',
  FILLED = 'filled',
}

/**
 * The base of all blocks
 * @param props
 * @constructor
 */
const InfoBlockBase = ({
  type,
  className,
  testId,
  icon,
  isClosable,
  children,
  label,
  notifyOnClose,
  variant = InfoBlockVariant.DEFAULT,
  ...rest
}: InfoBlockBaseProps) => {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    notifyOnClose && notifyOnClose();
    setIsClosed(true);
  };

  // DDM data will be send on different notifications
  useEffect(() => {
    sendDataToDDM(type, label || childrenToText(children));
  }, []);

  return isClosed ? null : (
    <S.InfoBlockBase
      {...rest}
      data-testid={testId || `info-block-${type}`}
      className={className && className}
      type={type}>
      <S.BlockHeader type={type} variant={variant}>
        <S.Spacer />
        {icon ? (
          <S.Icon type={type}>
            <Icon icon={icon} />
          </S.Icon>
        ) : (
          <S.Spacer />
        )}
        {isClosable ? (
          <S.CloseIcon onClick={handleClose}>
            <Icon icon={CORPORATE_CLOSE} />
          </S.CloseIcon>
        ) : (
          <S.Spacer />
        )}
      </S.BlockHeader>
      <S.BlockContent type={type} variant={variant}>
        {icon && (
          <S.Icon type={type}>
            <Icon icon={icon} />
          </S.Icon>
        )}

        <S.Content>{children}</S.Content>

        {isClosable && (
          <S.CloseIcon onClick={handleClose}>
            <Icon icon={CORPORATE_CLOSE} />
          </S.CloseIcon>
        )}
      </S.BlockContent>
    </S.InfoBlockBase>
  );
};

/**
 * INFO BLOCK
 * Use info block to display information as a group
 * @param props
 * @constructor
 */
export const InfoBlock = ({ className, icon, testId, children, ...rest }: InfoBlockProps) => {
  return (
    <InfoBlockBase
      {...rest}
      className={className}
      type={InfoBlockType.DEFAULT}
      icon={icon || CORPORATE_INFOCIRCLEFILL}
      testId={testId}>
      {children}
    </InfoBlockBase>
  );
};

/**
 * WARNING BLOCK
 * USE warning block to warn the user something happened or is about to happen
 * which is NOT considered something that failed or went wrong, but needs their attention.
 * @param props
 * @constructor
 */
export const WarningBlock = ({ className, icon, testId, children, ...rest }: InfoBlockProps) => {
  return (
    <InfoBlockBase
      {...rest}
      className={className}
      type={InfoBlockType.WARNING}
      icon={icon || CORPORATE_ATTENTIONCIRCLEFILL}
      testId={testId}>
      {children}
    </InfoBlockBase>
  );
};

/**
 * ERROR BLOCK
 * USE error block to inform the user something went wrong.
 * @param props
 * @constructor
 */
export const ErrorBlock = ({ className, icon, testId, children, ...rest }: InfoBlockProps) => {
  return (
    <InfoBlockBase
      {...rest}
      className={className}
      type={InfoBlockType.ERROR}
      icon={icon || CORPORATE_WARNINGTRIANGLEFILL}
      testId={testId}>
      {children}
    </InfoBlockBase>
  );
};

/**
 * SUCCESS BLOCK
 * USE success block to display information that users needs to know when an action was successful
 * @param props
 * @constructor
 */
export const SuccessBlock = ({ className, icon, testId, children, ...rest }: InfoBlockProps) => {
  return (
    <InfoBlockBase
      {...rest}
      className={className}
      type={InfoBlockType.SUCCESS}
      icon={icon || CORPORATE_CHECKMARKCIRCLEFILL}
      testId={testId}>
      {children}
    </InfoBlockBase>
  );
};
