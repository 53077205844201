import React, { useEffect } from 'react';

interface IOopsProps {
  children?: React.ReactNode;
}

const Oops = ({ children }: IOopsProps) => {
  useEffect(() => {
    // Throwing an error makes the ErrorBoundary catch it, which in turn shows the oeps page
    throw new Error('Oeps');
  }, []);

  return <div>{children}</div>;
};

export default Oops;
