import {
  CustomerInvoiceStatus,
  IBaseInvoice,
  IBillingArrangement,
  IInvoices,
  InvoiceTypes,
  IInvoiceCycle,
} from 'models/invoice.model';
import dateFNS from 'utils/date';
import { upperFirst } from 'utils/string';
import { IConversationInvoiceBase } from 'models/invoices-conversation.model';
import { IInvoice, IInvoiceData } from './invoice.model';

/**
 * Returns a humanized representation of the cycle, e.g. `April 2020`
 * @param cycle is the cycle used to format
 */
export const getHumanizedInvoiceCycle = (cycle: IInvoiceCycle) =>
  upperFirst(dateFNS.format(new Date(cycle.year, cycle.month - 1, 1), 'MMMM yyyy'));

/**
 * Returns a name for the invoice. For regular bills the name is based on the cycle
 * of the invoice, for other bills the type is used along with the formatted creation date
 * @param invoice is the invoice where the name needs to be returned for
 */
const getInvoiceName = (invoice: IBaseInvoice): string => {
  // eslint-disable-next-line no-nested-ternary
  return invoice.type.id === InvoiceTypes.BILL
    ? // A cycle is typically always present on BILL invoice types, but should it not be for whatever
      // reason we will return the type title (which is 'Ontvanger van de rekening'). This is only a
      // safe-guard and will not likely happen in the production environment
      invoice.cycle
      ? getHumanizedInvoiceCycle(invoice.cycle)
      : invoice.type.title
    : `${invoice.type.title} - ${dateFNS.format(invoice.creation_date, 'MMMM yyyy')}`;
};

/**
 * Returns the url to the invoice page
 * @param invoice is the invoice to get the url for
 */
export const getInvoiceUrl = (invoice: IBaseInvoice | IConversationInvoiceBase): string => {
  // Get the invoice number from the invoice and add it to the url. For the conversational header
  // the BE returns the invoice.number as key_invoice_id, so we add that as a fallback.
  const id = 'number' in invoice ? invoice.number : invoice.key_invoice_id;

  return `/my/rekeningen/${id}`;
};

/**
 * Extend an single invoice with additional helper keys.
 *
 * @param {invoice} IInvoice
 * @returns IInvoiceExtended
 */
export const mapInvoice = (bcId: string, billingArrangements: IBillingArrangement[]) => (
  invoice: IBaseInvoice,
  idx: number
): IInvoice => {
  // Create a temporary var to hold the old billing arrangement id
  // Then remove the key with the typo (billing_arrangment_id)
  // So we can later create a new alias without the typo.
  const billingArrangementId = invoice.billing_arrangment_id;

  delete invoice.billing_arrangment_id;

  const billingArrangement = billingArrangements.find(
    arrangement => arrangement.billing_arrangement_id === billingArrangementId
  );

  if (!billingArrangement) {
    throw new Error('Data not complete. Impossible to find a matching billing arrangement for this invoice.');
  }

  return {
    ...invoice,
    idx,

    // Add a formatted name for the invoice
    name: getInvoiceName(invoice),

    // Adds a url for the invoice detail page
    url: getInvoiceUrl(invoice),

    // Store the bcId along with each invoice so we can fetch charges and analysis data
    // based on a specific invoice
    billing_customer_id: bcId,

    // Create the alias for the 'billing_arrangment_id' key that contains a typo
    billing_arrangement_id: billingArrangementId!,

    // Add the billing arrangement to the invoice.
    billing_arrangement: billingArrangement,
  };
};

/**
 * Map the invoice backend response to our desired frontend response;
 * @param { response } IInvoices
 * @returns IInvoiceData
 */
export const mapInvoicesResponseData = (response: IInvoices, bcId: string): IInvoiceData => {
  // In the backend response the 'is_current' prop determines which BA should be active. This is
  // specific to the invoices response and not implemented in the regular billing arrangements backend response.
  const currentBillingArrangement = response.billing_arrangements.find(
    (arrangement: IBillingArrangement) => arrangement.is_current
  );

  return {
    currentBillingArrangement,
    billingArrangements: response.billing_arrangements,

    // These are arrays of regular and 'other' bills
    invoicesTypeBill: response.invoices_type_bill.map(mapInvoice(bcId, response.billing_arrangements)),
    invoicesTypeOther: response.invoices_type_other.map(mapInvoice(bcId, response.billing_arrangements)),

    // Both props are not yet used outside of the container
    averageInvoiceCosts: response.average_invoice_cost,
    subscriberCount: response.subscriber_count,
  };
};

/**
 * Get all open invoices.
 *
 * @param { invoices } IInvoice[]
 */
export const filterOpenInvoices = (invoices: IInvoice[]): IInvoice[] => {
  return invoices.filter(
    invoice =>
      invoice.customer_invoice_status === CustomerInvoiceStatus.OPEN ||
      invoice.customer_invoice_status === CustomerInvoiceStatus.NOT_OVERDUE ||
      invoice.customer_invoice_status === CustomerInvoiceStatus.NOT_PAID
  );
};

/**
 * Get all paid, or payment pending invoices.
 *
 * @param { invoices } IInvoice[]
 */
export const filterPaidInvoices = (invoices: IInvoice[]): IInvoice[] => {
  return invoices.filter(
    invoice =>
      invoice.customer_invoice_status === CustomerInvoiceStatus.PAID ||
      invoice.customer_invoice_status === CustomerInvoiceStatus.PAYMENT_PENDING
  );
};
