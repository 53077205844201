import RelayService from '@vodafonepub/relay-service';
import { useEffect } from 'react';
import { IUserContextState } from '../user.model';

export const useUpdateRelayOnUserChange = (userState: IUserContextState) => {
  useEffect(() => {
    if (userState.user?.party_id) {
      RelayService.trigger('ga.event', {
        ga_data: {
          category: 'my vodafone',
          action: 'set party_id',
          label: userState.user?.party_id,
        },
      });
    }

    RelayService.registerAuthenticatedUserResponse(userState.user);
  }, [userState]);

  useEffect(() => {
    if (!userState.activeBcId) return;
    RelayService.registerCurrentBillingCustomer(`${userState.activeBcId}`);
  }, [userState.activeBcId]);
};
