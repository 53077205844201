export enum Roles {
  // A prepaid user
  PREPAID = 'PREPAID',

  // A postpaid user
  POSTPAID = 'POSTPAID',

  // A business user
  BUSINESS = 'BUSINESS',

  // A consumer user
  CONSUMER = 'CONSUMER',

  // A prospect user, which has no BC's
  PROSPECT = 'PROSPECT',

  // An user eligible for NAW bonus
  ELEGIBLE_NAW_BONUS = 'ELEGIBLE_FOR_NAW_BONUS', // "elegible_for_naw_bonus",

  // A ceased BC
  CEASED_BC = 'CEASED_BC',

  // A BC with one or more ceased products
  CEASED_PRODUCTS = 'CEASED_PRODUCTS',

  // A BC with a delayed payment
  DELAYED_PAYMENT = 'DELAYED_PAYMENT',

  // Basic user roles
  // User always has ONE of these.
  ADMIN = 'ADMIN',
  USER = 'USER',
  USER_AND_BILL = 'USER_AND_BILL',
  BILL = 'BILL',
}

export type PermissionCombination = (currentUserRoles: Roles[]) => boolean;
export type Permissions = Roles[] | PermissionCombination;

export type PermissionSettings =
  | Permissions[]
  | { [key: string]: Permissions[]; consumer: Permissions[]; business: Permissions[] };
