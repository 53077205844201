import { useIntl } from 'react-intl';
import { translateHtml, translateHtmlWithDefaultMessage, translateText } from './intl.utils';

export const useTranslations = () => {
  const intl = useIntl();

  return {
    intl,
    translateHtml,
    translateHtmlWithDefaultMessage,
    translate: (...idsAndValues: any[]) => translateText(intl, ...idsAndValues),
  };
};
