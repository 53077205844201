import { LoadableComponent } from 'components/Loading';
import { FEATURES, FEATURE_STATES } from 'models';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getFromFeConfigs } from 'utils';

interface IFeaturesContext {
  isFeatureEnabled: (feature: FEATURES) => boolean;
  featureHasState: (featureToCheck: FEATURES | undefined, featureState: FEATURE_STATES) => boolean;
}

interface IFeaturesContextProviderProps {
  children: React.ReactNode;
}

export const FeaturesContext = createContext<IFeaturesContext>({
  isFeatureEnabled: () => {
    return false;
  },
  featureHasState: () => {
    return false;
  },
});

export const FeaturesContextProvider = ({ children }: IFeaturesContextProviderProps) => {
  const [features, setFeatures] = useState<{ [key in FEATURES]: FEATURE_STATES } | null>(null);
  const [isFeaturesLoaded, setIsFeaturesLoaded] = useState(false);

  useEffect(() => {
    getFromFeConfigs('features/feature-settings.json')
      .then(({ data }) => {
        setFeatures(data);
      })
      .catch(() => {
        throw new Error('Features not loaded');
      })
      .finally(() => {
        setIsFeaturesLoaded(true);
      });
  }, []);

  /**
   * Checks if a feature is enabled.
   * A feature is enabled if it is equal to ON or REDIRECt.
   * @param feature The feature name
   */
  const isFeatureEnabled = (feature: FEATURES) => {
    return featureHasState(feature, FEATURE_STATES.ON) || featureHasState(feature, FEATURE_STATES.REDIRECT);
  };

  /**
   * Checks if a feature has a specific state.
   * @param featureToCheck The feature name
   * @param featureState The state you want to match the current feature with
   */
  const featureHasState = (featureToCheck: FEATURES | undefined, featureState: FEATURE_STATES) => {
    const feature = featureToCheck ? getFeature(featureToCheck) : FEATURE_STATES.ON;

    return feature === featureState;
  };

  /**
   * Gets feature value of a specific feature
   * @param feature The feature name
   */
  const getFeature = (feature: FEATURES) => {
    return (features && features[feature]) || FEATURE_STATES.DEFAULT;
  };

  const value = useMemo(() => {
    return { isFeatureEnabled, featureHasState };
  }, [features]);

  return (
    <FeaturesContext.Provider value={value}>
      <LoadableComponent isLoading={!isFeaturesLoaded}>{children}</LoadableComponent>
    </FeaturesContext.Provider>
  );
};

export default FeaturesContextProvider;
