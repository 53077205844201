import { ProductStatus } from 'pages/Products/models';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProductSummariesContextState, SetProductSummariesState } from '../products.model';

/**
 * Ceased products will be redirected to a custom page telling them
 * this product is ceased.
 */
export const useRedirectCeasedProduct = (
  productSummariesState: IProductSummariesContextState,
  setProductSummariesState: SetProductSummariesState
) => {
  const navigate = useNavigate();

  useEffect(() => {
    const { currentProduct } = productSummariesState;

    if (!currentProduct || window.location.href.indexOf('/my/producten/inactief-product') > -1) return;

    try {
      if (currentProduct.product_status === ProductStatus.CEASED) {
        navigate(`/producten/inactief-product?p=${currentProduct.product_id}`);

        // Reset products back to first product in array because inactive products
        // can not have their data changed.
        setProductSummariesState(state => ({
          ...state,
          currentProduct: state.products[0] || undefined,
        }));
      }
    } catch (err) {
      // Errors are caught silently
    }
  }, [productSummariesState.currentProduct]);
};
