import { Color, FontLevels, Spacing, hoverFocus, media } from '@vodafoneziggo/sandwich';
import { Icon } from '@vodafoneziggo/sandwich/icons';
import styled, { css } from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  gap: ${Spacing.S};
  margin-top: ${Spacing.S};

  ${media.up('md')`
    margin-top: ${Spacing.L}
  `};
`;

export const ListItemContent = styled.div`
  min-width: 0;
  text-align: start;
`;

export const ListItemIcon = styled(Icon)`
  margin-left: ${Spacing.S};
  margin-right: ${Spacing.XS};

  width: 2.5em;
  height: 2rem;
`;

export const ListItemTitle = styled.span`
  display: block;
  overflow: hidden;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${FontLevels.BODY}
`;

export const ListItemSubtitle = styled.span`
  color: ${Color.GRAY_700};
  display: block;
  ${FontLevels.BODY_MOBILE}
`;

export const ListItemChevron = styled(Icon)`
  width: 1rem;
  height: 1rem;

  margin-left: auto;
  margin-right: ${Spacing.S};

  transition: transform ${props => props.theme.transitions.default};
`;

export const ListItem = styled.button<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.375rem;

  padding: ${Spacing.S} 0;

  border: 1px solid ${Color.GRAY_400};

  color: inherit;
  text-decoration: none;
  cursor: pointer;

  ${props => hoverFocus`
    background-color: ${Color.GRAY_200};
    transition: transform ${props.theme.transitions.default};
    
    ${ListItemChevron} {
      transform: translateX(0.375rem);
    }
  `}

  ${props =>
    props.isActive &&
    css`
      ${ListItemIcon},
      ${ListItemTitle},
    ${ListItemChevron} {
        color: ${Color.DARK_RED};
      }
    `}
`;
