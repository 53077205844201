import { IConversation, conversationPriorities } from 'models/conversation.model';
import { DashboardConversations } from 'pages/Dashboard/components/DashboardConversation/dashboard-conversation.model';
import { InvoicesConversations } from 'models/invoices-conversation.model';
import { UsageConversations } from 'pages/Usage/components/UsageConversation/usage-conversation.model';

/**
 * Return the conversation with the highest priority known in conversationPriorities.
 * This method will never return a conversation which is not known in conversationPriorities.
 *
 * @param conversations The conversations to use (backend response).
 */
export const determineActiveConversation = <
  T extends IConversation<DashboardConversations | InvoicesConversations | UsageConversations>
>(
  conversations: T[]
): T | never => {
  const sortedConversationsWithPrio = conversations
    // Remove conversations from the backend response which do not have a known priority in the FE.
    .filter(conversation => typeof conversationPriorities[conversation.card_type] !== 'undefined')
    // Add the priority to the backend response.
    .map(conversation => {
      const priority = conversationPriorities[conversation.card_type] as number;

      return { ...conversation, priority };
    })
    .sort((a, b) => (a.priority > b.priority ? -1 : 1));

  return sortedConversationsWithPrio[0];
};
