import { UserContext } from 'components/User';
import { ProductSummariesContext } from 'containers/products/productSummariesContext';
import { Roles } from 'models';
import { useContext, useEffect, useReducer } from 'react';

type ISubscribeMethod = (
  bcId: string,
  productId: string | null,
  productMsisdn: string | null,
  isConsumer: boolean,
  roles: Roles[],
  contactId: string | null
) => void;

interface State {
  /**
   * This prop is intentionally kept as string | null as to keep it the same as its value from UserContext
   */
  bcId: string | null;
  productId: string | null;
  productMsisdn: string | null;
  isConsumer: boolean;
  roles: Roles[];
  /**
   * The contactId of the logged-in user. Currently used to find which product belongs to the logged-in user.
   */
  contactId: string | null;
}

export const useAuthSubscription = (subscriberFn?: ISubscribeMethod) => {
  const { state: productSummariesState, setCurrentProduct } = useContext(ProductSummariesContext);
  const { state: userState, setBillingCustomer } = useContext(UserContext);

  const [state, dispatch] = useReducer(
    (currentState: State, payload: Partial<State>) => ({
      ...currentState,
      ...payload,
    }),
    {
      bcId: userState.activeBcId,
      productId: productSummariesState.currentProduct?.product_id ?? null,
      productMsisdn: productSummariesState.currentProduct?.primary_msisdn ?? null,
      isConsumer: userState.activeBc?.is_consumer ?? true,
      roles: userState.roles,
      contactId: userState.user?.contact?.contact_id ?? null,
    }
  );

  const { bcId, contactId, productId, productMsisdn, isConsumer, roles } = state;

  /**
   * Values from context are put in their own state, so we can utilize React's own memoization
   * functionality in the second useEffect to only call the subscriber if at least one of
   * the state properties change.
   */
  useEffect(() => {
    // If the products fetched are NOT for the current BC, don't update the state because products still need to be fetched
    if (productSummariesState.forBc !== userState.activeBcId) return;

    dispatch({
      bcId: userState.activeBcId,
      productId: productSummariesState.currentProduct?.product_id ?? null,
      productMsisdn: productSummariesState.currentProduct?.primary_msisdn ?? null,
      isConsumer: userState.activeBc?.is_consumer ?? true,
      roles: userState.roles,
      contactId: userState.user?.contact?.contact_id ?? null,
    });
  }, [productSummariesState, userState]);

  /**
   * Whenever one of the state variables change, the subscriber function is called. An important distinction
   * with the returned state from this hook is that the subscriber function is only called when bcId is resolved.
   * This prevents us from having to perform null checks everywhere.
   */
  useEffect(() => {
    if (!bcId) return;

    // Call the subscriber with the data we have
    subscriberFn && subscriberFn(bcId, productId, productMsisdn, isConsumer, roles, contactId);
  }, [bcId, productId, productMsisdn, isConsumer, roles, contactId]);

  return {
    bcId,
    productId,
    productMsisdn,
    isConsumer,
    contactId,
    roles,

    /**
     * These methods are exposed to aid in testing components that use this hook. It allows for setting the bcId and
     * productId without mocking user context and product summaries. In production code you would not use this method.
     */
    // eslint-disable-next-line no-shadow
    setBcId: (_bcId: string) => {
      dispatch({ bcId: _bcId });
    },

    // eslint-disable-next-line no-shadow
    setBcAndProductId: (_bcId: string, _productId: string) => {
      dispatch({ bcId: _bcId, productId: _productId });
    },
    setBillingCustomer,
    setCurrentProduct,
  };
};
