import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import * as S from './nba.styles';
import useNba from './useNba';

interface Props {
  /**
   * When provided, will load the NBA that matches the value. E.g. 'MCC_RET_123'
   */
  forceNba?: string;

  /**
   * If true, the NBA will only be shown if the user has only one product
   */
  singleProductOnly?: boolean;
}

export const Nba = ({ singleProductOnly, forceNba }: Props) => {
  const nba = useNba({ singleProductOnly, forceNba });

  if (!nba || !nba.markup) return null;

  return (
    <S.Wrapper>
      <Container dangerouslySetInnerHTML={{ __html: nba.markup }} data-testid="nba" />
    </S.Wrapper>
  );
};

export default Nba;
