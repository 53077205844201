import { useContext } from 'react';
import { UserContext } from 'components/User';
import { ProductSummariesContext } from 'containers/products/productSummariesContext';

type HashedUserInfo = { hashedCtn?: string; hashedBan?: string; hashedEmail?: string };

export const useHashedUserInfo = (): HashedUserInfo => {
  const { state: userState } = useContext(UserContext);
  const { state: productSummariesState } = useContext(ProductSummariesContext);

  return {
    hashedCtn: productSummariesState.currentProduct?.hashed_primary_msisdn,
    hashedBan: userState.activeBc?.hashed_billing_customer_id,
    hashedEmail: userState.user?.contact.hashed_email,
  };
};
