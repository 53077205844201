import { useEffect, useState } from 'react';
import api, { getFromFeConfigs } from 'utils/api';
import { IMaintenanceMessage } from './maintenance-message.model';

/**
 * Collects the server time from the api
 */
const getServerTime = async () => {
  const currentTime = await api.get('/date-util/current-time', undefined, { omitAuthHeader: true });

  return new Date(currentTime.data.timestamp);
};

/**
 * Collects the maintenance messages from the static assets
 */
const getMaintenanceMessagesFromFeConfigs = async () => {
  const response = await getFromFeConfigs('generic/maintenance-messages.json');

  return response.data.data as IMaintenanceMessage[];
};

/**
 * Convert date to an acceptable format for different browsers
 * 1. Trim spaces
 * 2. Search for spaces and replace with T
 * New format: "yyyy-MM-DDThh:mm"
 */
const convertDateFormat = (date: string) => {
  return date.trim().replace(/ /g, 'T');
};

/**
 * Filters messages by checking if the current serverTime is between the given maintenance message start date and end date.
 */
/**
 * Filters messages by checking if the current serverTime is between the given maintenance message start date and end date.
 * If the end date is not provided, the message is considered active indefinitely.
 */
const filterNonActiveMessages = (serverTime: Date) => (maintenanceMessage: IMaintenanceMessage) => {
  if (!serverTime) return false;

  const startDate = convertDateFormat(maintenanceMessage.start);
  const endDate = maintenanceMessage.end ? convertDateFormat(maintenanceMessage.end) : null;

  const startTimeBeforeServerTime = new Date(startDate) < serverTime;
  const endTimeAfterServerTime = endDate ? new Date(endDate) > serverTime : true;

  return startTimeBeforeServerTime && endTimeAfterServerTime;
};

/**
 * Modifies a string so it can be used in a regular expression.
 * @param paths are the paths to convert
 */
const convertPathToRegex = (paths: string[]) => {
  return (
    paths
      // If there is trailing slash, replace that slash with a optional slash (used in regex).
      .map(path => (path.slice(-1) === '/' ? `${path.slice(0, -1)}\\/?` : path))
      // Loop over all paths, and convert it to a valid regex.
      .map(path => path.replace('/*', '\\/?.*?'))
      // Add a $ at the end of the regex to only map exact matches.
      .map(path => `${path}$`)
  );
};

/**
 * Checks if the given maintenance message should be shown on the current page.
 */
const filterMessagesByPathForCurrentPage = (currentPage: string) => (maintenanceMessage: IMaintenanceMessage) => {
  if (!currentPage) return false;

  // If paths is only a string, convert it to an array.
  let paths: string[] =
    typeof maintenanceMessage.paths === 'string' ? [maintenanceMessage.paths] : maintenanceMessage.paths;

  // Changed string urls to regex
  paths = convertPathToRegex(paths);

  let messagePathMatchesWithUrl = false;

  // Test each pathRegex to see if it matches with current url. Keep in mind that only exact matches are valid
  paths.forEach(pathRegex => {
    if (new RegExp(pathRegex).test(currentPage || '')) {
      messagePathMatchesWithUrl = true;
    }
  });

  return messagePathMatchesWithUrl;
};

const useMaintenanceMessages = () => {
  const [activeMessage, setActiveMessage] = useState<IMaintenanceMessage | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function setMaintenanceMessages() {
      setIsLoading(true);

      try {
        const serverTime = process.env.NODE_ENV === 'test' ? new Date() : await getServerTime();
        const currentPage = window.location.pathname;
        const maintenanceMessages = await getMaintenanceMessagesFromFeConfigs();

        const activeMaintenanceMessages = maintenanceMessages
          .filter(filterNonActiveMessages(serverTime))
          .filter(filterMessagesByPathForCurrentPage(currentPage));

        setActiveMessage(activeMaintenanceMessages ? activeMaintenanceMessages[0] : undefined);
      } catch (err) {
        console.error('Failed to fetch maintenance messages or server time', err);
        setActiveMessage(undefined);
      } finally {
        setIsLoading(false);
      }
    }

    setMaintenanceMessages();
  }, []);

  return { activeMessage, isLoading };
};

export default useMaintenanceMessages;
