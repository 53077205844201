import { format, isFuture } from 'date-fns';
import nlLocale from 'date-fns/locale/nl';

/**
 * Default date formats used to display dates
 */
export const DATE_FORMAT = 'dd-MM-yyyy';
export const MONTH_FORMAT = 'MM-yyyy';
export const TIME_FORMAT = 'HH.mm';

// These strings are used to get the correct translation.
export enum DayTimes {
  NIGHT = 'night',
  EVENING = 'evening',
  AFTERNOON = 'afternoon',
  MORNING = 'morning',
}

// ❤ feel free to enrich this util ❤.
// date-fns docs: https://date-fns.org/v1.30.0/docs

class DateFNS {
  // Regex for parsing time strings
  rTime = /^([01]\d|2[0-3])(:|\.)?([0-5]\d)$/;

  public format = (date: Date | number | string, form = 'd MMMM yyyy') => {
    const formatInput = Number(date) || new Date(date);

    return format(formatInput, form, {
      locale: nlLocale,
    });
  };

  /**
   * Get the total remaining days in the current month
   */
  public getRemainingDays = (): number => {
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const totalDays = new Date(currentYear, currentMonth, 0).getDate();

    return totalDays - (currentDay - 1);
  };

  /**
   * Checks if the given date is equal to the current date
   */
  public isDateCurrentDate = (date: Date | number): boolean => {
    const f = 'ddMMyyyy';
    const currentDate = this.format(new Date(), f);
    const givenDate = this.format(date, f);

    return currentDate === givenDate;
  };

  /**
   * Sets the supplied time to the date
   * @param timeStr is a time string in one of the following formats: HH:mm, HH.mm
   * @param date is a Date object on which the time will be applied
   */
  public setTimeToDate(timeStr: string, date: Date): Date {
    // If we couldn't parse the time string, then just return the
    if (!this.rTime.test(timeStr)) {
      throw new Error(
        'An unsupported time string was supplied. Supply a time string in one of the following formats: HH:mm, hh.mm'
      );
    }

    // Work out which delimiter the time string has. This can be a `.` or a `:`.
    const timeDelimiter = timeStr.substr(2, 1);

    // Parse the hours and minutes from the time string
    const [hours, minutes] = timeStr.split(timeDelimiter);

    // Set the hours and minutes to the date object
    date.setHours(+hours);
    date.setMinutes(+minutes);

    return date;
  }

  /**
   * Checks if the given date is in future
   * @param date is a either date object or date string
   */
  public isFutureDate(date: Date | string): boolean {
    const dateToCheck = new Date(date);

    if (dateToCheck) return isFuture(dateToCheck);

    return false;
  }

  /**
   * Retun the part of the day based on the hour in 24-hour format.
   * Default is current hour of the day.
   * @param hour
   */
  public getPartOfDay = (hour: number = new Date().getHours()) => {
    if (hour < 6) return DayTimes.NIGHT;

    if (hour < 12) return DayTimes.MORNING;

    if (hour < 18) return DayTimes.AFTERNOON;

    return DayTimes.EVENING;
  };
}

export default new DateFNS();
