import { setContext } from '@apollo/client/link/context';
import { determineCorrectGQLApiEndpoint } from './uri.util';

/**
 * This link will enable setting the URI based on a promise
 */

/* eslint-disable-next-line require-await */
export const uriLink = setContext(async () => {
  return { uri: determineCorrectGQLApiEndpoint() };
});
