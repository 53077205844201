import { IConversation } from 'models/conversation.model';

export enum UsageConversations {
  OUT_OF_DATA = 'out-of-data',
  DATA_USAGE_BLOCKED = 'data-usage-blocked',
  ROAMING_DATA_USAGE_BLOCKED = 'roaming-data-usage-blocked',
  LOCATION_IS_ROAMING = 'location-is-roaming',
}

export interface IUsageConversationBase extends IConversation<UsageConversations> {
  data: Data;
}

export interface Data {
  is_throttled?: boolean;
  notification_id: string;
  country?: string;
  country_iso2?: string;
  is_roaming_unlimited?: boolean;
}
