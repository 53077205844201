import { IContact } from 'models';
import { ProductServiceType } from '../products.container';
import { IDeviceRc } from './product.model';

/**
 * A mapping to product statusses used in the backend. Do not change any of these values!
 */
export enum ProductStatus {
  ACTIVE = 'Actief',
  SUSPENDED = 'Opgeschort',
  CEASED = 'Stopgezet',
}

export interface IProductSummary {
  product_id: string;
  service_type?: ProductServiceType;
  product_msisdn: string;
  product_description: string;
  product_status: ProductStatus;
  has_vast_op_mobiel: boolean;
  is_data_only: boolean;
  is_hybrid: boolean;
  is_prepaid: boolean;
  is_primary_bc_contact: boolean;
  is_you: boolean;
  is_you_auto_pay: boolean;
  label: string;
  primary_contact: IContact;
  primary_msisdn: string;
  reason: string;
  bank_account_number?: string;
  hashed_primary_msisdn?: string;
  device_rc: IDeviceRc;
}
