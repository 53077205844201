import Restricted from 'components/Authentication/Restricted';
import { FeaturesContext } from 'containers/featuresContext';
import { FEATURES, FEATURE_STATES, PermissionSettings } from 'models';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

interface NavigationLinkProps {
  href: string | any;
  className?: string;
  target?: string;
  testId?: string;
  children: React.ReactNode;
  feature?: FEATURES;
  permissions?: PermissionSettings;
}

const NavigationLink = (props: NavigationLinkProps) => {
  const { href, className, children, target, testId, feature, permissions, ...restProps } = props;
  const { featureHasState } = useContext(FeaturesContext);

  // when feature is tagged with a feature and the feature is set to redirect.
  const featureIsOff = featureHasState(feature, FEATURE_STATES.OFF);
  const featureIsRedirect = featureHasState(feature, FEATURE_STATES.REDIRECT);

  if (featureIsOff) return null;

  // href can be an translate react component
  if (!featureIsRedirect && href && href.props && href.props.children.startsWith('/my')) {
    return (
      <Restricted permissions={permissions}>
        <NavLink {...restProps} to={href.props.children.substr(3)} className={className} data-testid={testId}>
          {children}
        </NavLink>
      </Restricted>
    );
  }

  if (!featureIsRedirect && typeof href === 'string' && href.startsWith('/my')) {
    return (
      <Restricted permissions={permissions}>
        <NavLink {...restProps} to={href.substr(3)} className={className} data-testid={testId}>
          {children}
        </NavLink>
      </Restricted>
    );
  }

  return (
    <Restricted permissions={permissions}>
      <a {...restProps} href={href} className={className} target={target} data-testid={testId}>
        {children}
      </a>
    </Restricted>
  );
};

export default NavigationLink;
