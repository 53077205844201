import { Loader, Spinner } from '@vodafoneziggo/sandwich/components/loading/loader/loader.styles';
import styled from 'styled-components';

export const Wrapper = styled.div<{ contentVisible: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;

  > ${Loader} {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 400ms 50ms ease-in-out;
    opacity: ${props => (props.contentVisible ? '0' : '1')};
    pointer-events: ${props => (props.contentVisible ? 'none' : 'initial')};

    > ${Spinner} {
      opacity: ${props => (props.contentVisible ? '0' : '1')};
      transition: ${props => (props.contentVisible ? 'none' : '250ms ease-in-out')};
      transition-property: opacity, transform;
      transform: ${props => (props.contentVisible ? ' translate3d(0, -1.25rem, 0);' : ' translate3d(0, 0, 0);')};
    }
  }
`;
