import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type Avg = {
  __typename?: 'AVG';
  AVG_EU_D_OOB_REV: Scalars['Float'];
  AVG_EU_TOTAL_OOB_REV: Scalars['Float'];
  AVG_EU_V_OOB_REV: Scalars['Float'];
};

export type AccountStatus = {
  __typename?: 'AccountStatus';
  isAccountBarrLocked: Scalars['Boolean'];
  perMonthSpendingLimit: Scalars['Float'];
  status: Scalars['String'];
};

export type Adv = {
  __typename?: 'Adv';
  blox: Scalars['String'];
  conv_data: Scalars['String'];
  conv_disc: Scalars['String'];
  d_allow: Scalars['String'];
  d_old_allow: Scalars['Float'];
  desc: Scalars['String'];
  label: Scalars['String'];
  maf_inc_vat: Scalars['String'];
  pp: Scalars['String'];
  pp_ubuy: Scalars['String'];
  type: Scalars['String'];
  v_allow: Scalars['String'];
};

export type Advice = {
  __typename?: 'Advice';
  advice?: Maybe<SubscriptionData>;
  adviceDown?: Maybe<SubscriptionData>;
  adviceUp?: Maybe<SubscriptionData>;
  billing?: Maybe<SubscriberChargesSummary>;
  bundleAdvice?: Maybe<FormattedAdviceData>;
};

export type Amount = {
  __typename?: 'Amount';
  resource?: Maybe<Scalars['String']>;
  resourceCode?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  undiscountedPrice?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type AverageInfo = {
  __typename?: 'AverageInfo';
  AVG_EU_TOTAL_OOB_REV: Scalars['Float'];
  EU_D_OOB_REV: Scalars['Float'];
  EU_V_OOB_REV: Scalars['Float'];
};

export type B2BPartner = {
  __typename?: 'B2BPartner';
  contactInfo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  partnerName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ChargesWithDiscountType = {
  __typename?: 'ChargesWithDiscountType';
  discountedPrice: VatPriceType;
  discounts: Array<DiscountType>;
  normalPrice: VatPriceType;
};

export type Ctn = {
  __typename?: 'Ctn';
  ctn: Scalars['String'];
  retainable: Scalars['String'];
};

export type Customer = {
  __typename?: 'Customer';
  retainable: Scalars['String'];
};

export enum CustomerInvoiceStatus {
  NOT_OVERDUE = 'NOT_OVERDUE',
  NOT_PAID = 'NOT_PAID',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
}

export type DateWithCsrId = {
  __typename?: 'DateWithCsrId';
  csrId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type DefaultSuccessResponse = {
  __typename?: 'DefaultSuccessResponse';
  success: Scalars['Boolean'];
};

export enum DeliveryOptions {
  EBILL = 'EBILL',
  PAPER = 'PAPER',
}

export type DiscountType = {
  __typename?: 'DiscountType';
  discountName: Scalars['String'];
  discountPrice: VatPriceType;
  discountType: DiscountTypeEnum;
  duration?: Maybe<Scalars['Int']>;
};

export enum DiscountTypeEnum {
  OVERRULE = 'OVERRULE',
  PERCENTAGE = 'PERCENTAGE',
  SUBTRACT = 'SUBTRACT',
}

export enum DistributionMethod {
  BindToMobileDevice = 'BindToMobileDevice',
  MyVFApp = 'MyVFApp',
  QRCodeVoucher = 'QRCodeVoucher',
  UNKNOWN = 'UNKNOWN',
}

export enum Domain {
  selected_salesforce_id = 'selected_salesforce_id',
  vz_sav = 'vz_sav',
  vz_saz = 'vz_saz',
}

export type EsimActivationCode = {
  __typename?: 'EsimActivationCode';
  activationCode: Scalars['String'];
  confirmationCode: Scalars['String'];
  matchingId: Scalars['String'];
  smdpFqdn: Scalars['String'];
};

export enum EsimActivationStatus {
  Allocated = 'Allocated',
  Available = 'Available',
  Downloaded = 'Downloaded',
  Error = 'Error',
  Installed = 'Installed',
  Installed_Disabled = 'Installed_Disabled',
  Installed_Enabled = 'Installed_Enabled',
  Linked = 'Linked',
  Released = 'Released',
  Released_Linked = 'Released_Linked',
}

export type EsimStatus = {
  __typename?: 'EsimStatus';
  description: Scalars['String'];
  lastUpdated: Scalars['Date'];
  value: EsimActivationStatus;
};

export type Example = {
  __typename?: 'Example';
  success: Scalars['Boolean'];
};

export type FamilyType = {
  __typename?: 'FamilyType';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type FormattedAdviceData = {
  __typename?: 'FormattedAdviceData';
  averageInfo: AverageInfo;
  convergedData: Scalars['String'];
  convergedDiscount: Scalars['String'];
  dataAllowance: Scalars['String'];
  dataUsage: Array<UsageInfo>;
  label: Scalars['String'];
  oldAllowance?: Maybe<Scalars['String']>;
  oldPrice?: Maybe<Scalars['String']>;
  pp: Scalars['String'];
  ppUbuy: Scalars['String'];
  voiceAllowance: Scalars['String'];
  voiceUsage: Array<UsageInfo>;
};

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  MALE_FEMALE = 'MALE_FEMALE',
  UNKNOWN = 'UNKNOWN',
  VROUW = 'VROUW',
}

export type GenericTransaction = {
  __typename?: 'GenericTransaction';
  b2BPartner?: Maybe<B2BPartner>;
  category: Scalars['String'];
  chargingMethod?: Maybe<Scalars['Float']>;
  contentName?: Maybe<Scalars['String']>;
  discountedAmount?: Maybe<Scalars['Float']>;
  errorDescription?: Maybe<Scalars['String']>;
  errorId?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  merchantName?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['Float']>;
  paymentInfo?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refunds?: Maybe<Array<RefundTransaction>>;
  status?: Maybe<TransactionStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  transactionAmount?: Maybe<Scalars['Float']>;
  transactionDate: Scalars['String'];
  type: Scalars['String'];
};

export type GenericTransactions = {
  __typename?: 'GenericTransactions';
  list: Array<GenericTransaction>;
};

export type HouseHoldStatus = {
  __typename?: 'HouseHoldStatus';
  has_ziggo: Scalars['Boolean'];
  has_ziggo_benefits: Scalars['Boolean'];
  household_type: HouseHoldType;
};

export enum HouseHoldType {
  CONVERGED = 'CONVERGED',
  ELIGIBLE = 'ELIGIBLE',
  VODAFONE = 'VODAFONE',
  ZIGGO = 'ZIGGO',
}

export type IAmountIncludingVat = {
  __typename?: 'IAmountIncludingVAT';
  amount_including_vat: Scalars['Float'];
};

export type IAverageInvoiceCosts = {
  __typename?: 'IAverageInvoiceCosts';
  average_total_invoice: IVatAmounts;
  average_usage_charges: IVatAmounts;
};

export type IBaseInvoice = {
  __typename?: 'IBaseInvoice';
  billing_arrangment_id: Scalars['String'];
  close_date: Scalars['Float'];
  creation_date: Scalars['Float'];
  credit_amount?: Maybe<IVatAmounts>;
  credits?: Maybe<Array<ICredit>>;
  customer_invoice_status: CustomerInvoiceStatus;
  cycle?: Maybe<IInvoiceCycle>;
  dd_due_date: Scalars['Float'];
  details_available: Scalars['Boolean'];
  direct_debits?: Maybe<Array<IDirectDebit>>;
  discount_amount?: Maybe<IVatAmounts>;
  due_amount: IVatAmounts;
  due_date: Scalars['Float'];
  hardware_amount?: Maybe<IVatAmounts>;
  has_recurring_charges_changed: Scalars['Boolean'];
  is_first_bill: Scalars['Boolean'];
  is_pdf_available: Scalars['Boolean'];
  last_direct_debit_extract_date?: Maybe<Scalars['Float']>;
  non_disclosed_amount?: Maybe<IVatAmounts>;
  number: Scalars['String'];
  oc_amount?: Maybe<IVatAmounts>;
  paid_date?: Maybe<Scalars['Float']>;
  payment_pending: Scalars['Boolean'];
  payments?: Maybe<Array<IPayment>>;
  rc_amount?: Maybe<IVatAmounts>;
  show_pay_deck: Scalars['Boolean'];
  status: InvoiceStatus;
  total_amount?: Maybe<IVatAmounts>;
  total_amount_excluding_non_disclosed: IVatAmounts;
  type: IInvoiceType;
  uc_amount?: Maybe<IVatAmounts>;
};

export type IBillingArrangement = {
  __typename?: 'IBillingArrangement';
  billing_arrangement_id: Scalars['String'];
  delivery_options: IDeliveryOptions;
  invoice_address: IInvoiceAddress;
  is_active: Scalars['Boolean'];
  is_current: Scalars['Boolean'];
  label: Scalars['String'];
  pay_means: IPayMeans;
  primary_contact: IPrimaryContact;
  subscriber_count: Scalars['Float'];
};

export type ICharge = {
  __typename?: 'ICharge';
  amount: IChargeAmount;
  description: Scalars['String'];
  is_discount: Scalars['Boolean'];
};

export type IChargeAmount = {
  __typename?: 'IChargeAmount';
  amount_excluding_vat?: Maybe<Scalars['Float']>;
  amount_including_vat: Scalars['Float'];
  vat_amount?: Maybe<Scalars['Float']>;
  vat_percentage?: Maybe<Scalars['Float']>;
};

export type ICharges = {
  __typename?: 'ICharges';
  credit_charges?: Maybe<Array<ICreditCharge>>;
  credit_charges_total?: Maybe<IChargeAmount>;
  discount_charges?: Maybe<Array<ICharge>>;
  discount_charges_total?: Maybe<IChargeAmount>;
  hardware_charges?: Maybe<Array<ICharge>>;
  has_broken_month: Scalars['Boolean'];
  invoice: InvoiceBase;
  invoice_usage_charges?: Maybe<Array<InvoiceDetailsCharge>>;
  invoice_usage_charges_total?: Maybe<IChargeAmount>;
  is_retention_invoice: Scalars['Boolean'];
  one_time_charges?: Maybe<Array<OneTimeCharge>>;
  one_time_charges_total?: Maybe<IChargeAmount>;
  other_charges?: Maybe<Array<ICharge>>;
  recurring_charges?: Maybe<Array<IRecurringCharge>>;
  recurring_charges_by_period?: Maybe<Array<RecurringChargesByPeriod>>;
  recurring_charges_period?: Maybe<RecurringChargesPeriod>;
  recurring_charges_total?: Maybe<IChargeAmount>;
  subscriber_charges_summaries: Array<SubscriberChargesSummary>;
  tax_charges?: Maybe<Array<ICharge>>;
  tax_charges_total?: Maybe<IChargeAmount>;
  total?: Maybe<IAmountIncludingVat>;
  undisclosed_charges?: Maybe<Array<ICharge>>;
  undisclosed_charges_total?: Maybe<IChargeAmount>;
};

export type IContract = {
  __typename?: 'IContract';
  end_date: Scalars['Float'];
  start_date: Scalars['Float'];
};

export type ICredit = {
  __typename?: 'ICredit';
  allocated_amount: Scalars['Float'];
  amount: Scalars['Float'];
  balance_impact_code: Scalars['String'];
  credit_level_code: Scalars['String'];
  credit_reason: Scalars['String'];
  memo_text: Scalars['String'];
  restricted_charge_id: Scalars['String'];
  restricted_invoice_number: Scalars['String'];
  tax_amount: Scalars['Float'];
};

export type ICreditCharge = {
  __typename?: 'ICreditCharge';
  amount: IChargeAmount;
  corrected_invoice_id: Scalars['String'];
  description: Scalars['String'];
  is_discount: Scalars['Boolean'];
  po_number: Scalars['String'];
};

export type IDeliveryOptions = {
  __typename?: 'IDeliveryOptions';
  bill_delivery_method: DeliveryOptions;
};

export type IDeviceRc = {
  __typename?: 'IDeviceRc';
  end_date: Scalars['Float'];
  id: Scalars['String'];
  monthly_amount: Scalars['Float'];
  start_date: Scalars['Float'];
};

export type IDirectDebit = {
  __typename?: 'IDirectDebit';
  actual_amount: Scalars['Float'];
  bank_account_number: Scalars['String'];
  bank_code: Scalars['String'];
  cc_expiry_date: Scalars['String'];
  credit_card_number: Scalars['String'];
  credit_card_type: Scalars['String'];
  ddr_source: Scalars['String'];
  direct_debit_voucher: Scalars['String'];
  extract_date: Scalars['Float'];
  payment_method: Scalars['String'];
  request_status: Scalars['String'];
  request_type: Scalars['String'];
  requested_amount: Scalars['Float'];
  status_reason_code: Scalars['String'];
  status_update_date: Scalars['Float'];
};

export type IEligibilityUnavailabilityReason = {
  __typename?: 'IEligibilityUnavailabilityReason';
  code: IValue;
  description: IValue;
};

export type IInvoiceAddress = {
  __typename?: 'IInvoiceAddress';
  address_id: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  house_number: Scalars['String'];
  house_number_extension?: Maybe<Scalars['String']>;
  street_name: Scalars['String'];
  zip_code: Scalars['String'];
};

export type IInvoiceCycle = {
  __typename?: 'IInvoiceCycle';
  code: Scalars['String'];
  end_date: Scalars['Float'];
  month: Scalars['Float'];
  start_date: Scalars['Float'];
  year: Scalars['Float'];
};

export type IInvoiceType = {
  __typename?: 'IInvoiceType';
  id: InvoiceTypes;
  title: Scalars['String'];
};

export type IInvoices = {
  __typename?: 'IInvoices';
  average_invoice_cost: IAverageInvoiceCosts;
  billing_arrangements: Array<IBillingArrangement>;
  invoices_type_bill: Array<IBaseInvoice>;
  invoices_type_other: Array<IBaseInvoice>;
  subscriber_count: Scalars['Float'];
};

export type IPayMeans = {
  __typename?: 'IPayMeans';
  bank_account_name: Scalars['String'];
  bank_account_number: Scalars['String'];
  payment_type: PaymentTypes;
};

export type IPayment = {
  __typename?: 'IPayment';
  allocated_amount: Scalars['Float'];
  amount: Scalars['Float'];
  date: Scalars['Float'];
  original_amount: Scalars['Float'];
  payment_id: Scalars['String'];
};

export type IPrimaryContact = {
  __typename?: 'IPrimaryContact';
  first_name: Scalars['String'];
  full_name: Scalars['String'];
  gender: Gender;
  last_name: Scalars['String'];
};

export type IProduct = {
  __typename?: 'IProduct';
  contract?: Maybe<IContract>;
  device_rc?: Maybe<Array<IDeviceRc>>;
  product_description: Scalars['String'];
  product_msisdn: Scalars['String'];
  product_status: Scalars['String'];
  retainable?: Maybe<Scalars['Boolean']>;
};

export type IRecurringCharge = {
  __typename?: 'IRecurringCharge';
  amount: IChargeAmount;
  description: Scalars['String'];
  end_date: Scalars['Float'];
  is_discount: Scalars['Boolean'];
  po_number: Scalars['String'];
  product_id: Scalars['String'];
  quantity: Scalars['String'];
  start_date: Scalars['Float'];
};

export type IValue = {
  __typename?: 'IValue';
  value: Scalars['String'];
};

export type IVatAmounts = {
  __typename?: 'IVatAmounts';
  amountExcludingVat: Scalars['Float'];
  amountIncludingVat: Scalars['Float'];
  vatAmount?: Maybe<Scalars['Float']>;
};

export type InvoiceBase = {
  __typename?: 'InvoiceBase';
  billing_arrangment_id?: Maybe<Scalars['String']>;
  cycle?: Maybe<IInvoiceCycle>;
  number: Scalars['String'];
  type: IInvoiceType;
};

export type InvoiceDetailsCharge = {
  __typename?: 'InvoiceDetailsCharge';
  amount: IChargeAmount;
  description: Scalars['String'];
  event_description: Scalars['String'];
  event_type: UsageEventTypes;
  is_discount: Scalars['Boolean'];
  quantity: Scalars['String'];
  unit_of_measurement: Scalars['String'];
};

export enum InvoiceStatus {
  CLOSED = 'CLOSED',
  FINALIZED = 'FINALIZED',
  OPEN = 'OPEN',
}

export enum InvoiceTypes {
  BILL = 'BILL',
  CREDIT_NOTA = 'CREDIT_NOTA',
  DEBIT_NOTA = 'DEBIT_NOTA',
  DEPOSIT = 'DEPOSIT',
  DISHONORED_CHECK = 'DISHONORED_CHECK',
  IMMEDIATE_CHANGE = 'IMMEDIATE_CHANGE',
}

export enum LifecycleEnum {
  ACQUISITION = 'ACQUISITION',
  RETENTION = 'RETENTION',
}

export type MaintenanceMessages = {
  __typename?: 'MaintenanceMessages';
  businessMessage?: Maybe<Array<Scalars['String']>>;
  consumerMessage?: Maybe<Array<Scalars['String']>>;
  container: Array<MaintenanceMessagesContainer>;
  end: Scalars['String'];
  excludePaths: Array<Scalars['String']>;
  image: Scalars['String'];
  message?: Maybe<Array<Scalars['String']>>;
  paths: Array<Scalars['String']>;
  start: Scalars['String'];
  type: Scalars['String'];
};

export type MaintenanceMessagesContainer = {
  __typename?: 'MaintenanceMessagesContainer';
  class: Scalars['String'];
  position: Scalars['String'];
  target: Scalars['String'];
};

export type ModifyTransaction = {
  __typename?: 'ModifyTransaction';
  id: Scalars['Float'];
  reason?: Maybe<Scalars['String']>;
  timestamp: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type ModifyTransactions = {
  __typename?: 'ModifyTransactions';
  modify?: Maybe<Array<ModifyTransaction>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateSim: DefaultSuccessResponse;
  blockSim: DefaultSuccessResponse;
  cancelSubscription: DefaultSuccessResponse;
  changeSpendingLimit: DefaultSuccessResponse;
  modifySubscription: DefaultSuccessResponse;
  terminateContract: TerminateContractStatus;
  toggleContentBar: DefaultSuccessResponse;
  unblockSim: DefaultSuccessResponse;
  updateDefaultSelectedAccount: DefaultSuccessResponse;
  updateNotificationSettings: DefaultSuccessResponse;
  updatePrivacySettings: DefaultSuccessResponse;
};

export type MutationActivateSimArgs = {
  iccId: Scalars['String'];
  mobileProductId: Scalars['String'];
};

export type MutationBlockSimArgs = {
  assigned_product_id: Scalars['String'];
  billing_customer_id: Scalars['String'];
  reason_code: Scalars['String'];
  sim_id: Scalars['String'];
};

export type MutationCancelSubscriptionArgs = {
  msisdn: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type MutationChangeSpendingLimitArgs = {
  msisdn: Scalars['String'];
  perMonthLimit?: InputMaybe<Scalars['Float']>;
};

export type MutationModifySubscriptionArgs = {
  chargingMethod: Scalars['Int'];
  msisdn: Scalars['String'];
  subscriptionId: Scalars['String'];
};

export type MutationTerminateContractArgs = {
  attachment_id_1: Scalars['String'];
  attachment_id_2?: InputMaybe<Scalars['String']>;
  attachment_id_3?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  date_of_birth: Scalars['Float'];
  family_birthdate?: InputMaybe<Scalars['Float']>;
  family_city?: InputMaybe<Scalars['String']>;
  family_customer_id?: InputMaybe<Scalars['String']>;
  family_email: Scalars['String'];
  family_first_name: Scalars['String'];
  family_house_number?: InputMaybe<Scalars['String']>;
  family_house_number_extension?: InputMaybe<Scalars['String']>;
  family_iban?: InputMaybe<Scalars['String']>;
  family_iban_name?: InputMaybe<Scalars['String']>;
  family_identification_expiry_date?: InputMaybe<Scalars['Float']>;
  family_identification_number?: InputMaybe<Scalars['String']>;
  family_identification_type?: InputMaybe<Scalars['String']>;
  family_last_name: Scalars['String'];
  family_middle_name?: InputMaybe<Scalars['String']>;
  family_phone?: InputMaybe<Scalars['String']>;
  family_postal_code?: InputMaybe<Scalars['String']>;
  family_salutation: Scalars['String'];
  family_street?: InputMaybe<Scalars['String']>;
  first_name: Scalars['String'];
  house_number: Scalars['String'];
  house_number_extension?: InputMaybe<Scalars['String']>;
  last_name: Scalars['String'];
  middle_name?: InputMaybe<Scalars['String']>;
  mobile_1: Scalars['String'];
  mobile_2?: InputMaybe<Scalars['String']>;
  mobile_3?: InputMaybe<Scalars['String']>;
  salutation: Scalars['String'];
  send_voicemail_message?: InputMaybe<Scalars['Boolean']>;
  street: Scalars['String'];
  transfer_to_other_customer?: InputMaybe<Scalars['Boolean']>;
  transfer_to_prepaid?: InputMaybe<Scalars['Boolean']>;
  zipcode: Scalars['String'];
};

export type MutationToggleContentBarArgs = {
  accountBar?: InputMaybe<Scalars['Boolean']>;
  msisdn: Scalars['String'];
};

export type MutationUnblockSimArgs = {
  assigned_product_id: Scalars['String'];
  billing_customer_id: Scalars['String'];
  sim_id: Scalars['String'];
};

export type MutationUpdateDefaultSelectedAccountArgs = {
  commonId: Scalars['String'];
  domain: Domain;
  sub: Scalars['String'];
};

export type MutationUpdateNotificationSettingsArgs = {
  bcId: Scalars['String'];
  each_call: Scalars['Boolean'];
  productId: Scalars['String'];
};

export type MutationUpdatePrivacySettingsArgs = {
  bcId: Scalars['String'];
  commercial_sms: Scalars['Boolean'];
  customer_data_analysis: Scalars['Boolean'];
  customer_research: Scalars['Boolean'];
  location_data: Scalars['Boolean'];
  newsletter_via_email: Scalars['Boolean'];
  postal_mail: Scalars['Boolean'];
  productId: Scalars['String'];
  profiling: Scalars['Boolean'];
  telemarketing: Scalars['Boolean'];
  up_sell_via_email: Scalars['Boolean'];
};

export enum NetworkStatus {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  TO_BE_ACTIVATED = 'TO_BE_ACTIVATED',
  UNKNOWN = 'UNKNOWN',
}

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  each_call: Scalars['Boolean'];
};

export type OneNumberEligibility = {
  __typename?: 'OneNumberEligibility';
  eligibilityResult: IValue;
  eligibilityUnavailabilityReason?: Maybe<IEligibilityUnavailabilityReason>;
};

export type OneTimeCharge = {
  __typename?: 'OneTimeCharge';
  amount: IChargeAmount;
  description: Scalars['String'];
  is_discount: Scalars['Boolean'];
  po_number: Scalars['String'];
  quantity: Scalars['String'];
};

export type PaymentTransaction = {
  __typename?: 'PaymentTransaction';
  b2BPartner?: Maybe<B2BPartner>;
  chargingMethod?: Maybe<Scalars['Float']>;
  contentName?: Maybe<Scalars['String']>;
  discountedAmount?: Maybe<Scalars['Float']>;
  errorDescription?: Maybe<Scalars['String']>;
  errorId?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  merchantName?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  paymentInfo?: Maybe<Scalars['String']>;
  purchaseDate: DateWithCsrId;
  refunds?: Maybe<Array<RefundTransaction>>;
  status?: Maybe<TransactionStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};

export type PaymentTransactions = {
  __typename?: 'PaymentTransactions';
  transaction?: Maybe<Array<PaymentTransaction>>;
};

export enum PaymentTypes {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export type PriceType = {
  __typename?: 'PriceType';
  oneTimeCharge: VatPriceType;
  recurringCharge: VatPriceType;
};

export type PriceWithDiscountsType = {
  __typename?: 'PriceWithDiscountsType';
  oneTimeCharge: ChargesWithDiscountType;
  recurringCharge: ChargesWithDiscountType;
};

export type PrivacySettings = {
  __typename?: 'PrivacySettings';
  commercial_sms: Scalars['Boolean'];
  customer_data_analysis: Scalars['Boolean'];
  customer_research: Scalars['Boolean'];
  location_data: Scalars['Boolean'];
  newsletter_via_email: Scalars['Boolean'];
  postal_mail: Scalars['Boolean'];
  profiling: Scalars['Boolean'];
  telemarketing: Scalars['Boolean'];
  up_sell_via_email: Scalars['Boolean'];
};

export type PromotionType = {
  __typename?: 'PromotionType';
  id: Scalars['String'];
  name: Scalars['String'];
  price: PriceType;
  promotionType: PromotionTypeEnum;
  sku: Scalars['String'];
};

export enum PromotionTypeEnum {
  CONNECTION_COSTS_WAIVER = 'CONNECTION_COSTS_WAIVER',
  FESTIVAL = 'FESTIVAL',
  FRIENDS_FAMILY = 'FRIENDS_FAMILY',
  INVOICE_DISCOUNT = 'INVOICE_DISCOUNT',
  NON_ZIGGO = 'NON_ZIGGO',
  ONLINE = 'ONLINE',
  PRIORITY = 'PRIORITY',
  REGULAR = 'REGULAR',
  RETENTIE = 'RETENTIE',
  RUNNERS = 'RUNNERS',
  SEGMENTED_FMC = 'SEGMENTED_FMC',
  YOUNG = 'YOUNG',
}

export type Query = {
  __typename?: 'Query';
  check: Example;
  getAccountStatus: AccountStatus;
  getAdvice: Advice;
  getEsimActivationCode: EsimActivationCode;
  getEsimStatus: EsimStatus;
  getHouseHoldStatus: HouseHoldStatus;
  getInvoiceBill: ICharges;
  getMaintenanceMessages: Array<MaintenanceMessages>;
  getMyInvoices: IInvoices;
  getNotificationSettings: NotificationSettings;
  getOneNumberEligibility: OneNumberEligibility;
  getPrivacySettings: PrivacySettings;
  getRetainableCustomers: RetentionIndication;
  getSimOnlyBundles: Array<SimOnlyBundleType>;
  getSims: Array<Sim>;
  getSubscriptionInfo: SubscriptionInfo;
  getTransactions: Transactions;
};

export type QueryGetAccountStatusArgs = {
  forceRefreshCustomer?: InputMaybe<Scalars['Boolean']>;
  msisdn: Scalars['String'];
};

export type QueryGetAdviceArgs = {
  bcId: Scalars['String'];
  isBusiness: Scalars['Boolean'];
  msisdn: Scalars['String'];
};

export type QueryGetEsimActivationCodeArgs = {
  challengeTokenId?: InputMaybe<Scalars['String']>;
  iccId: Scalars['String'];
  mobileProductId: Scalars['String'];
};

export type QueryGetEsimStatusArgs = {
  iccId: Scalars['String'];
  mobileProductId: Scalars['String'];
};

export type QueryGetHouseHoldStatusArgs = {
  bcId: Scalars['String'];
};

export type QueryGetMaintenanceMessagesArgs = {
  currentPage: Scalars['String'];
};

export type QueryGetMyInvoicesArgs = {
  bcId: Scalars['String'];
  productId?: InputMaybe<Scalars['String']>;
};

export type QueryGetNotificationSettingsArgs = {
  bcId: Scalars['String'];
  productId: Scalars['String'];
};

export type QueryGetOneNumberEligibilityArgs = {
  customerId: Scalars['String'];
  msisdn: Scalars['String'];
};

export type QueryGetPrivacySettingsArgs = {
  bcId: Scalars['String'];
  productId: Scalars['String'];
};

export type QueryGetRetainableCustomersArgs = {
  bcId: Scalars['String'];
  msisdn: Scalars['String'];
};

export type QueryGetSimOnlyBundlesArgs = {
  id?: InputMaybe<Scalars['String']>;
  isZiggoCustomer: Scalars['Boolean'];
  lifecycle: LifecycleEnum;
  promotionType: PromotionTypeEnum;
  segment: SegmentEnum;
};

export type QueryGetSimsArgs = {
  mobileProductId: Scalars['String'];
};

export type QueryGetSubscriptionInfoArgs = {
  bcId: Scalars['String'];
  productId: Scalars['String'];
  productMsisdn: Scalars['String'];
  roles: Array<Roles>;
};

export type QueryGetTransactionsArgs = {
  descendingOrder?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['String']>;
  maxEvents?: InputMaybe<Scalars['Float']>;
  msisdn: Scalars['String'];
  orderBy?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type RecurringChargesByPeriod = {
  __typename?: 'RecurringChargesByPeriod';
  amount: IChargeAmount;
  end_date: Scalars['Float'];
  start_date: Scalars['Float'];
};

export type RecurringChargesPeriod = {
  __typename?: 'RecurringChargesPeriod';
  end_date: Scalars['Float'];
  start_date: Scalars['Float'];
};

export type RefundTransaction = {
  __typename?: 'RefundTransaction';
  amount?: Maybe<Amount>;
  id: Scalars['Float'];
  paymentId: Scalars['Float'];
  reason?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  timestamp: Scalars['String'];
  type: Scalars['String'];
};

export type RefundTransactions = {
  __typename?: 'RefundTransactions';
  refund?: Maybe<Array<RefundTransaction>>;
};

export type RetentionIndication = {
  __typename?: 'RetentionIndication';
  ctns: Array<Ctn>;
  customer: Customer;
};

/** User roles */
export enum Roles {
  ADMIN = 'ADMIN',
  BILL = 'BILL',
  BUSINESS = 'BUSINESS',
  CEASED_BC = 'CEASED_BC',
  CEASED_PRODUCTS = 'CEASED_PRODUCTS',
  CONSUMER = 'CONSUMER',
  DELAYED_PAYMENT = 'DELAYED_PAYMENT',
  ELEGIBLE_NAW_BONUS = 'ELEGIBLE_NAW_BONUS',
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
  PROSPECT = 'PROSPECT',
  USER = 'USER',
  USER_AND_BILL = 'USER_AND_BILL',
}

export enum SegmentEnum {
  BUSINESS = 'BUSINESS',
  CONSUMER = 'CONSUMER',
}

export type Sim = {
  __typename?: 'Sim';
  distributionMethod?: Maybe<DistributionMethod>;
  iccId: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  networkStatus?: Maybe<NetworkStatus>;
  primary?: Maybe<Scalars['Boolean']>;
  pukCode?: Maybe<Scalars['String']>;
  type?: Maybe<SimType>;
};

export type SimOnlyBundleType = {
  __typename?: 'SimOnlyBundleType';
  convergedPromoPrice?: Maybe<Scalars['Float']>;
  convergedSubscriptionData?: Maybe<Scalars['Float']>;
  convergedSubscriptionPrice: Scalars['Float'];
  defaultPrice: Scalars['Float'];
  id: Scalars['String'];
  price: PriceWithDiscountsType;
  sku: Scalars['String'];
  subscription: Subscription;
  subscriptionData?: Maybe<Scalars['Float']>;
  subscriptionPrice: Scalars['Float'];
  youthPromoPrice?: Maybe<Scalars['Float']>;
  youthSubscriptionPrice?: Maybe<Scalars['Float']>;
};

export enum SimType {
  ESIM = 'ESIM',
  MICRO = 'MICRO',
  NANO = 'NANO',
  NORMAL = 'NORMAL',
  UNKNOWN = 'UNKNOWN',
}

export type SubscriberChargesSummary = {
  __typename?: 'SubscriberChargesSummary';
  primary_msisdn?: Maybe<Scalars['String']>;
  subscriber_id?: Maybe<Scalars['String']>;
  subscriber_name?: Maybe<Scalars['String']>;
  subscriber_subtotal?: Maybe<IChargeAmount>;
  total_credit_amount?: Maybe<IChargeAmount>;
  total_discount_amount?: Maybe<IChargeAmount>;
  total_one_time_charge_amount?: Maybe<IChargeAmount>;
  total_recurring_charge_amount?: Maybe<IChargeAmount>;
  total_undisclosed_charge_amount?: Maybe<IAmountIncludingVat>;
  total_usage_charge_amount?: Maybe<IChargeAmount>;
};

export type Subscription = {
  __typename?: 'Subscription';
  durationMonths: Scalars['Int'];
  family: FamilyType;
  hasUnlimitedInternet: Scalars['Boolean'];
  hasUnlimitedSMS: Scalars['Boolean'];
  hasUnlimitedVoice: Scalars['Boolean'];
  id: Scalars['String'];
  internetGB?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  numberSMS?: Maybe<Scalars['Int']>;
  price: PriceWithDiscountsType;
  promotions: Array<PromotionType>;
  sku: Scalars['String'];
  voiceMinutes?: Maybe<Scalars['Int']>;
};

export type SubscriptionData = {
  __typename?: 'SubscriptionData';
  convergedDataAllowance?: Maybe<Scalars['Float']>;
  convergedPrice?: Maybe<Scalars['Float']>;
  dataAllowance?: Maybe<Scalars['Float']>;
  hasUnlimitedCalls?: Maybe<Scalars['Boolean']>;
  hasUnlimitedInternet?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  subscriptionId: Scalars['String'];
  subscriptionName: Scalars['String'];
  subscriptionYears: Scalars['Float'];
  voiceAllowance?: Maybe<Scalars['Float']>;
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  canAddRedTogether?: Maybe<Scalars['Boolean']>;
  contract?: Maybe<IContract>;
  isRedTogetherCustomer?: Maybe<Scalars['Boolean']>;
  isRetainable?: Maybe<Scalars['Boolean']>;
  product: IProduct;
};

export type TerminateContractStatus = {
  __typename?: 'TerminateContractStatus';
  ticket_id: Scalars['String'];
};

export enum TransactionStatus {
  Canceled = 'Canceled',
  Completed = 'Completed',
  PaymentDenied = 'PaymentDenied',
  PaymentError = 'PaymentError',
  PaymentRejected = 'PaymentRejected',
  Reserved = 'Reserved',
  RolledBack = 'RolledBack',
}

export type Transactions = {
  __typename?: 'Transactions';
  amount?: Maybe<Scalars['Float']>;
  modifyTransactions?: Maybe<ModifyTransactions>;
  paymentTransactions?: Maybe<PaymentTransactions>;
  refundTransactions?: Maybe<RefundTransactions>;
  transactions?: Maybe<GenericTransactions>;
  usageTransactions?: Maybe<UsageTransactions>;
};

export enum UsageEventTypes {
  DATA = 'DATA',
  MONEY = 'MONEY',
  OTHER = 'OTHER',
  OTHER_HYBRID = 'OTHER_HYBRID',
  TEXT = 'TEXT',
  VOICE = 'VOICE',
}

export type UsageInfo = {
  __typename?: 'UsageInfo';
  key: Scalars['Float'];
  month: Scalars['String'];
  value: Scalars['Float'];
};

export type UsageTransaction = {
  __typename?: 'UsageTransaction';
  b2BPartner?: Maybe<B2BPartner>;
  chargingMethod?: Maybe<Scalars['Float']>;
  contentName?: Maybe<Scalars['String']>;
  errorDescription?: Maybe<Scalars['String']>;
  errorId?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  merchantName?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  paymentInfo?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['String'];
  refunds?: Maybe<Array<RefundTransaction>>;
  status?: Maybe<TransactionStatus>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  type: Scalars['String'];
};

export type UsageTransactions = {
  __typename?: 'UsageTransactions';
  transaction?: Maybe<Array<UsageTransaction>>;
};

export type VatPriceType = {
  __typename?: 'VatPriceType';
  excludingVat: Scalars['Float'];
  includingVat: Scalars['Float'];
};

export type GetTransactionsQueryVariables = Exact<{
  msisdn: Scalars['String'];
  maxEvents?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;

export type GetTransactionsQuery = {
  __typename?: 'Query';
  getTransactions: {
    __typename?: 'Transactions';
    transactions?: {
      __typename?: 'GenericTransactions';
      list: Array<{
        __typename?: 'GenericTransaction';
        category: string;
        status?: TransactionStatus | null;
        transactionDate: string;
        transactionAmount?: number | null;
        chargingMethod?: number | null;
        contentName?: string | null;
        partnerId?: string | null;
        subscriptionId?: string | null;
        subscriptionStatus?: number | null;
        id: number;
        paymentId?: number | null;
        type: string;
        b2BPartner?: {
          __typename?: 'B2BPartner';
          id: string;
          contactInfo?: string | null;
          email?: string | null;
          partnerName?: string | null;
          url?: string | null;
        } | null;
      }>;
    } | null;
  };
};

export type CancelSubscriptionMutationVariables = Exact<{
  msisdn: Scalars['String'];
  subscriptionId: Scalars['String'];
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type GetAccountStatusQueryVariables = Exact<{
  msisdn: Scalars['String'];
  forceRefreshCustomer: Scalars['Boolean'];
}>;

export type GetAccountStatusQuery = {
  __typename?: 'Query';
  getAccountStatus: {
    __typename?: 'AccountStatus';
    status: string;
    perMonthSpendingLimit: number;
    isAccountBarrLocked: boolean;
  };
};

export type ChangeSpendingLimitMutationVariables = Exact<{
  msisdn: Scalars['String'];
  perMonthLimit: Scalars['Float'];
}>;

export type ChangeSpendingLimitMutation = {
  __typename?: 'Mutation';
  changeSpendingLimit: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type ToggleContentBarMutationVariables = Exact<{
  msisdn: Scalars['String'];
  accountBar: Scalars['Boolean'];
}>;

export type ToggleContentBarMutation = {
  __typename?: 'Mutation';
  toggleContentBar: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type GetHouseHoldStatusQueryVariables = Exact<{
  bcId: Scalars['String'];
}>;

export type GetHouseHoldStatusQuery = {
  __typename?: 'Query';
  getHouseHoldStatus: {
    __typename?: 'HouseHoldStatus';
    has_ziggo: boolean;
    has_ziggo_benefits: boolean;
    household_type: HouseHoldType;
  };
};

export type SubscriptionFragmentFragment = {
  __typename?: 'Subscription';
  id: string;
  sku: string;
  name: string;
  hasUnlimitedInternet: boolean;
  internetGB?: number | null;
  hasUnlimitedVoice: boolean;
  voiceMinutes?: number | null;
  hasUnlimitedSMS: boolean;
  numberSMS?: number | null;
  durationMonths: number;
  family: { __typename?: 'FamilyType'; id: string; name: string };
  price: {
    __typename?: 'PriceWithDiscountsType';
    oneTimeCharge: {
      __typename?: 'ChargesWithDiscountType';
      normalPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      discountedPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      discounts: Array<{
        __typename?: 'DiscountType';
        duration?: number | null;
        discountName: string;
        discountType: DiscountTypeEnum;
        discountPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      }>;
    };
    recurringCharge: {
      __typename?: 'ChargesWithDiscountType';
      discountedPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      normalPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      discounts: Array<{
        __typename?: 'DiscountType';
        discountName: string;
        discountType: DiscountTypeEnum;
        duration?: number | null;
        discountPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      }>;
    };
  };
  promotions: Array<{
    __typename?: 'PromotionType';
    name: string;
    id: string;
    sku: string;
    promotionType: PromotionTypeEnum;
    price: {
      __typename?: 'PriceType';
      oneTimeCharge: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
      recurringCharge: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
    };
  }>;
};

export type GetSubscriptionByIdQueryVariables = Exact<{
  segment: SegmentEnum;
  lifecycle: LifecycleEnum;
  promotionType: PromotionTypeEnum;
  id?: InputMaybe<Scalars['String']>;
  isZiggoCustomer: Scalars['Boolean'];
}>;

export type GetSubscriptionByIdQuery = {
  __typename?: 'Query';
  getSimOnlyBundles: Array<{
    __typename?: 'SimOnlyBundleType';
    id: string;
    sku: string;
    convergedPromoPrice?: number | null;
    youthPromoPrice?: number | null;
    defaultPrice: number;
    subscriptionData?: number | null;
    convergedSubscriptionData?: number | null;
    subscriptionPrice: number;
    convergedSubscriptionPrice: number;
    youthSubscriptionPrice?: number | null;
    subscription: {
      __typename?: 'Subscription';
      id: string;
      sku: string;
      name: string;
      hasUnlimitedInternet: boolean;
      internetGB?: number | null;
      hasUnlimitedVoice: boolean;
      voiceMinutes?: number | null;
      hasUnlimitedSMS: boolean;
      numberSMS?: number | null;
      durationMonths: number;
      family: { __typename?: 'FamilyType'; id: string; name: string };
      price: {
        __typename?: 'PriceWithDiscountsType';
        oneTimeCharge: {
          __typename?: 'ChargesWithDiscountType';
          normalPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          discountedPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          discounts: Array<{
            __typename?: 'DiscountType';
            duration?: number | null;
            discountName: string;
            discountType: DiscountTypeEnum;
            discountPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          }>;
        };
        recurringCharge: {
          __typename?: 'ChargesWithDiscountType';
          discountedPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          normalPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          discounts: Array<{
            __typename?: 'DiscountType';
            discountName: string;
            discountType: DiscountTypeEnum;
            duration?: number | null;
            discountPrice: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          }>;
        };
      };
      promotions: Array<{
        __typename?: 'PromotionType';
        name: string;
        id: string;
        sku: string;
        promotionType: PromotionTypeEnum;
        price: {
          __typename?: 'PriceType';
          oneTimeCharge: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
          recurringCharge: { __typename?: 'VatPriceType'; includingVat: number; excludingVat: number };
        };
      }>;
    };
  }>;
};

export type GetPrivacySettingsQueryVariables = Exact<{
  bcId: Scalars['String'];
  productId: Scalars['String'];
}>;

export type GetPrivacySettingsQuery = {
  __typename?: 'Query';
  getPrivacySettings: {
    __typename?: 'PrivacySettings';
    commercial_sms: boolean;
    telemarketing: boolean;
    newsletter_via_email: boolean;
    up_sell_via_email: boolean;
    postal_mail: boolean;
    customer_data_analysis: boolean;
    profiling: boolean;
    location_data: boolean;
    customer_research: boolean;
  };
};

export type UpdatePrivacySettingsMutationVariables = Exact<{
  bcId: Scalars['String'];
  commercial_sms: Scalars['Boolean'];
  customer_data_analysis: Scalars['Boolean'];
  customer_research: Scalars['Boolean'];
  location_data: Scalars['Boolean'];
  newsletter_via_email: Scalars['Boolean'];
  postal_mail: Scalars['Boolean'];
  productId: Scalars['String'];
  profiling: Scalars['Boolean'];
  telemarketing: Scalars['Boolean'];
  up_sell_via_email: Scalars['Boolean'];
}>;

export type UpdatePrivacySettingsMutation = {
  __typename?: 'Mutation';
  updatePrivacySettings: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type GetAdviceQueryVariables = Exact<{
  bcId: Scalars['String'];
  isBusiness: Scalars['Boolean'];
  msisdn: Scalars['String'];
}>;

export type GetAdviceQuery = {
  __typename?: 'Query';
  getAdvice: {
    __typename?: 'Advice';
    advice?: {
      __typename?: 'SubscriptionData';
      convergedDataAllowance?: number | null;
      convergedPrice?: number | null;
      dataAllowance?: number | null;
      price?: number | null;
      subscriptionId: string;
      subscriptionName: string;
      subscriptionYears: number;
      voiceAllowance?: number | null;
      hasUnlimitedInternet?: boolean | null;
      hasUnlimitedCalls?: boolean | null;
    } | null;
    adviceUp?: {
      __typename?: 'SubscriptionData';
      convergedDataAllowance?: number | null;
      convergedPrice?: number | null;
      dataAllowance?: number | null;
      price?: number | null;
      subscriptionId: string;
      subscriptionName: string;
      subscriptionYears: number;
      voiceAllowance?: number | null;
      hasUnlimitedInternet?: boolean | null;
      hasUnlimitedCalls?: boolean | null;
    } | null;
    adviceDown?: {
      __typename?: 'SubscriptionData';
      convergedDataAllowance?: number | null;
      convergedPrice?: number | null;
      dataAllowance?: number | null;
      price?: number | null;
      subscriptionId: string;
      subscriptionName: string;
      subscriptionYears: number;
      voiceAllowance?: number | null;
      hasUnlimitedInternet?: boolean | null;
      hasUnlimitedCalls?: boolean | null;
    } | null;
    bundleAdvice?: {
      __typename?: 'FormattedAdviceData';
      label: string;
      pp: string;
      ppUbuy: string;
      dataAllowance: string;
      voiceAllowance: string;
      convergedData: string;
      convergedDiscount: string;
      oldPrice?: string | null;
      oldAllowance?: string | null;
      averageInfo: {
        __typename?: 'AverageInfo';
        EU_D_OOB_REV: number;
        EU_V_OOB_REV: number;
        AVG_EU_TOTAL_OOB_REV: number;
      };
      dataUsage: Array<{ __typename?: 'UsageInfo'; key: number; value: number; month: string }>;
      voiceUsage: Array<{ __typename?: 'UsageInfo'; key: number; value: number; month: string }>;
    } | null;
    billing?: {
      __typename?: 'SubscriberChargesSummary';
      total_recurring_charge_amount?: { __typename?: 'IChargeAmount'; amount_including_vat: number } | null;
    } | null;
  };
};

export type GetRetainableCustomersQueryVariables = Exact<{
  bcId: Scalars['String'];
  msisdn: Scalars['String'];
}>;

export type GetRetainableCustomersQuery = {
  __typename?: 'Query';
  getRetainableCustomers: {
    __typename?: 'RetentionIndication';
    customer: { __typename?: 'Customer'; retainable: string };
    ctns: Array<{ __typename?: 'Ctn'; ctn: string; retainable: string }>;
  };
};

export type GetSubscriptionInfoQueryVariables = Exact<{
  bcId: Scalars['String'];
  productId: Scalars['String'];
  productMsisdn: Scalars['String'];
  roles: Array<Roles> | Roles;
}>;

export type GetSubscriptionInfoQuery = {
  __typename?: 'Query';
  getSubscriptionInfo: {
    __typename?: 'SubscriptionInfo';
    isRetainable?: boolean | null;
    canAddRedTogether?: boolean | null;
    isRedTogetherCustomer?: boolean | null;
    product: {
      __typename?: 'IProduct';
      product_msisdn: string;
      product_description: string;
      product_status: string;
      retainable?: boolean | null;
      device_rc?: Array<{
        __typename?: 'IDeviceRc';
        id: string;
        monthly_amount: number;
        start_date: number;
        end_date: number;
      }> | null;
    };
    contract?: { __typename?: 'IContract'; start_date: number; end_date: number } | null;
  };
};

export type GetSimsQueryVariables = Exact<{
  mobileProductId: Scalars['String'];
}>;

export type GetSimsQuery = {
  __typename?: 'Query';
  getSims: Array<{
    __typename?: 'Sim';
    distributionMethod?: DistributionMethod | null;
    iccId: string;
    location?: string | null;
    msisdn?: string | null;
    networkStatus?: NetworkStatus | null;
    primary?: boolean | null;
    pukCode?: string | null;
    type?: SimType | null;
  }>;
};

export type GetEsimStatusQueryVariables = Exact<{
  mobileProductId: Scalars['String'];
  iccId: Scalars['String'];
}>;

export type GetEsimStatusQuery = {
  __typename?: 'Query';
  getEsimStatus: { __typename?: 'EsimStatus'; description: string; lastUpdated: any; value: EsimActivationStatus };
};

export type GetEsimActivationCodeQueryVariables = Exact<{
  mobileProductId: Scalars['String'];
  iccId: Scalars['String'];
  challengeTokenId?: InputMaybe<Scalars['String']>;
}>;

export type GetEsimActivationCodeQuery = {
  __typename?: 'Query';
  getEsimActivationCode: {
    __typename?: 'EsimActivationCode';
    activationCode: string;
    confirmationCode: string;
    matchingId: string;
    smdpFqdn: string;
  };
};

export type ActivateSimMutationVariables = Exact<{
  mobileProductId: Scalars['String'];
  iccId: Scalars['String'];
}>;

export type ActivateSimMutation = {
  __typename?: 'Mutation';
  activateSim: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type BlockSimMutationVariables = Exact<{
  billing_customer_id: Scalars['String'];
  assigned_product_id: Scalars['String'];
  sim_id: Scalars['String'];
  reason_code: Scalars['String'];
}>;

export type BlockSimMutation = {
  __typename?: 'Mutation';
  blockSim: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type UnblockSimMutationVariables = Exact<{
  billing_customer_id: Scalars['String'];
  assigned_product_id: Scalars['String'];
  sim_id: Scalars['String'];
}>;

export type UnblockSimMutation = {
  __typename?: 'Mutation';
  unblockSim: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type GetNotificationSettingsQueryVariables = Exact<{
  bcId: Scalars['String'];
  productId: Scalars['String'];
}>;

export type GetNotificationSettingsQuery = {
  __typename?: 'Query';
  getNotificationSettings: { __typename?: 'NotificationSettings'; each_call: boolean };
};

export type UpdateNotificationSettingsMutationVariables = Exact<{
  bcId: Scalars['String'];
  productId: Scalars['String'];
  each_call: Scalars['Boolean'];
}>;

export type UpdateNotificationSettingsMutation = {
  __typename?: 'Mutation';
  updateNotificationSettings: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export type TerminateContractMutationVariables = Exact<{
  salutation: Scalars['String'];
  first_name: Scalars['String'];
  middle_name?: InputMaybe<Scalars['String']>;
  last_name: Scalars['String'];
  date_of_birth: Scalars['Float'];
  street: Scalars['String'];
  house_number: Scalars['String'];
  house_number_extension?: InputMaybe<Scalars['String']>;
  zipcode: Scalars['String'];
  city: Scalars['String'];
  mobile_1: Scalars['String'];
  mobile_2?: InputMaybe<Scalars['String']>;
  mobile_3?: InputMaybe<Scalars['String']>;
  transfer_to_prepaid?: InputMaybe<Scalars['Boolean']>;
  attachment_id_1: Scalars['String'];
  attachment_id_2?: InputMaybe<Scalars['String']>;
  attachment_id_3?: InputMaybe<Scalars['String']>;
  send_voicemail_message?: InputMaybe<Scalars['Boolean']>;
  family_salutation: Scalars['String'];
  family_first_name: Scalars['String'];
  family_middle_name?: InputMaybe<Scalars['String']>;
  family_last_name: Scalars['String'];
  family_street?: InputMaybe<Scalars['String']>;
  family_city?: InputMaybe<Scalars['String']>;
  family_house_number?: InputMaybe<Scalars['String']>;
  family_house_number_extension?: InputMaybe<Scalars['String']>;
  family_postal_code?: InputMaybe<Scalars['String']>;
  family_email: Scalars['String'];
  family_phone: Scalars['String'];
  family_customer_id?: InputMaybe<Scalars['String']>;
  transfer_to_other_customer?: InputMaybe<Scalars['Boolean']>;
  family_birthdate?: InputMaybe<Scalars['Float']>;
  family_iban?: InputMaybe<Scalars['String']>;
  family_iban_name?: InputMaybe<Scalars['String']>;
  family_identification_type?: InputMaybe<Scalars['String']>;
  family_identification_number?: InputMaybe<Scalars['String']>;
  family_identification_expiry_date?: InputMaybe<Scalars['Float']>;
}>;

export type TerminateContractMutation = {
  __typename?: 'Mutation';
  terminateContract: { __typename?: 'TerminateContractStatus'; ticket_id: string };
};

export type UpdateDefaultSelectedAccountMutationVariables = Exact<{
  commonId: Scalars['String'];
  domain: Domain;
  sub: Scalars['String'];
}>;

export type UpdateDefaultSelectedAccountMutation = {
  __typename?: 'Mutation';
  updateDefaultSelectedAccount: { __typename?: 'DefaultSuccessResponse'; success: boolean };
};

export const SubscriptionFragmentFragmentDoc = gql`
  fragment SubscriptionFragment on Subscription {
    id
    sku
    name
    hasUnlimitedInternet
    internetGB
    hasUnlimitedVoice
    voiceMinutes
    hasUnlimitedSMS
    numberSMS
    durationMonths
    family {
      id
      name
    }
    price {
      oneTimeCharge {
        normalPrice {
          includingVat
          excludingVat
        }
        discountedPrice {
          includingVat
          excludingVat
        }
        discounts {
          duration
          discountName
          discountType
          discountPrice {
            includingVat
            excludingVat
          }
        }
      }
      recurringCharge {
        discountedPrice {
          includingVat
          excludingVat
        }
        normalPrice {
          includingVat
          excludingVat
        }
        discounts {
          discountPrice {
            includingVat
            excludingVat
          }
          discountName
          discountType
          duration
        }
      }
    }
    promotions {
      name
      id
      sku
      promotionType
      price {
        oneTimeCharge {
          includingVat
          excludingVat
        }
        recurringCharge {
          includingVat
          excludingVat
        }
      }
    }
  }
`;
export const GetTransactionsDocument = gql`
  query getTransactions($msisdn: String!, $maxEvents: Float, $startDate: String, $endDate: String) {
    getTransactions(msisdn: $msisdn, maxEvents: $maxEvents, startDate: $startDate, endDate: $endDate) {
      transactions {
        list {
          category
          status
          transactionDate
          transactionAmount
          chargingMethod
          contentName
          b2BPartner {
            id
            contactInfo
            email
            partnerName
            url
          }
          partnerId
          subscriptionId
          subscriptionStatus
          id
          paymentId
          type
        }
      }
    }
  }
`;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      msisdn: // value for 'msisdn'
 *      maxEvents: // value for 'maxEvents'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
}
export function useGetTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, options);
}
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription($msisdn: String!, $subscriptionId: String!) {
    cancelSubscription(msisdn: $msisdn, subscriptionId: $subscriptionId) {
      success
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      msisdn: // value for 'msisdn'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(
    CancelSubscriptionDocument,
    options
  );
}
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const GetAccountStatusDocument = gql`
  query getAccountStatus($msisdn: String!, $forceRefreshCustomer: Boolean!) {
    getAccountStatus(msisdn: $msisdn, forceRefreshCustomer: $forceRefreshCustomer) {
      status
      perMonthSpendingLimit
      isAccountBarrLocked
    }
  }
`;

/**
 * __useGetAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountStatusQuery({
 *   variables: {
 *      msisdn: // value for 'msisdn'
 *      forceRefreshCustomer: // value for 'forceRefreshCustomer'
 *   },
 * });
 */
export function useGetAccountStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountStatusQuery, GetAccountStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountStatusQuery, GetAccountStatusQueryVariables>(GetAccountStatusDocument, options);
}
export function useGetAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountStatusQuery, GetAccountStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountStatusQuery, GetAccountStatusQueryVariables>(GetAccountStatusDocument, options);
}
export type GetAccountStatusQueryHookResult = ReturnType<typeof useGetAccountStatusQuery>;
export type GetAccountStatusLazyQueryHookResult = ReturnType<typeof useGetAccountStatusLazyQuery>;
export type GetAccountStatusQueryResult = Apollo.QueryResult<GetAccountStatusQuery, GetAccountStatusQueryVariables>;
export const ChangeSpendingLimitDocument = gql`
  mutation changeSpendingLimit($msisdn: String!, $perMonthLimit: Float!) {
    changeSpendingLimit(msisdn: $msisdn, perMonthLimit: $perMonthLimit) {
      success
    }
  }
`;
export type ChangeSpendingLimitMutationFn = Apollo.MutationFunction<
  ChangeSpendingLimitMutation,
  ChangeSpendingLimitMutationVariables
>;

/**
 * __useChangeSpendingLimitMutation__
 *
 * To run a mutation, you first call `useChangeSpendingLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSpendingLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSpendingLimitMutation, { data, loading, error }] = useChangeSpendingLimitMutation({
 *   variables: {
 *      msisdn: // value for 'msisdn'
 *      perMonthLimit: // value for 'perMonthLimit'
 *   },
 * });
 */
export function useChangeSpendingLimitMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeSpendingLimitMutation, ChangeSpendingLimitMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeSpendingLimitMutation, ChangeSpendingLimitMutationVariables>(
    ChangeSpendingLimitDocument,
    options
  );
}
export type ChangeSpendingLimitMutationHookResult = ReturnType<typeof useChangeSpendingLimitMutation>;
export type ChangeSpendingLimitMutationResult = Apollo.MutationResult<ChangeSpendingLimitMutation>;
export type ChangeSpendingLimitMutationOptions = Apollo.BaseMutationOptions<
  ChangeSpendingLimitMutation,
  ChangeSpendingLimitMutationVariables
>;
export const ToggleContentBarDocument = gql`
  mutation toggleContentBar($msisdn: String!, $accountBar: Boolean!) {
    toggleContentBar(msisdn: $msisdn, accountBar: $accountBar) {
      success
    }
  }
`;
export type ToggleContentBarMutationFn = Apollo.MutationFunction<
  ToggleContentBarMutation,
  ToggleContentBarMutationVariables
>;

/**
 * __useToggleContentBarMutation__
 *
 * To run a mutation, you first call `useToggleContentBarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleContentBarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleContentBarMutation, { data, loading, error }] = useToggleContentBarMutation({
 *   variables: {
 *      msisdn: // value for 'msisdn'
 *      accountBar: // value for 'accountBar'
 *   },
 * });
 */
export function useToggleContentBarMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleContentBarMutation, ToggleContentBarMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleContentBarMutation, ToggleContentBarMutationVariables>(
    ToggleContentBarDocument,
    options
  );
}
export type ToggleContentBarMutationHookResult = ReturnType<typeof useToggleContentBarMutation>;
export type ToggleContentBarMutationResult = Apollo.MutationResult<ToggleContentBarMutation>;
export type ToggleContentBarMutationOptions = Apollo.BaseMutationOptions<
  ToggleContentBarMutation,
  ToggleContentBarMutationVariables
>;
export const GetHouseHoldStatusDocument = gql`
  query getHouseHoldStatus($bcId: String!) {
    getHouseHoldStatus(bcId: $bcId) {
      has_ziggo
      has_ziggo_benefits
      household_type
    }
  }
`;

/**
 * __useGetHouseHoldStatusQuery__
 *
 * To run a query within a React component, call `useGetHouseHoldStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseHoldStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseHoldStatusQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *   },
 * });
 */
export function useGetHouseHoldStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetHouseHoldStatusQuery, GetHouseHoldStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHouseHoldStatusQuery, GetHouseHoldStatusQueryVariables>(
    GetHouseHoldStatusDocument,
    options
  );
}
export function useGetHouseHoldStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHouseHoldStatusQuery, GetHouseHoldStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHouseHoldStatusQuery, GetHouseHoldStatusQueryVariables>(
    GetHouseHoldStatusDocument,
    options
  );
}
export type GetHouseHoldStatusQueryHookResult = ReturnType<typeof useGetHouseHoldStatusQuery>;
export type GetHouseHoldStatusLazyQueryHookResult = ReturnType<typeof useGetHouseHoldStatusLazyQuery>;
export type GetHouseHoldStatusQueryResult = Apollo.QueryResult<
  GetHouseHoldStatusQuery,
  GetHouseHoldStatusQueryVariables
>;
export const GetSubscriptionByIdDocument = gql`
  query getSubscriptionById(
    $segment: SegmentEnum!
    $lifecycle: LifecycleEnum!
    $promotionType: PromotionTypeEnum!
    $id: String
    $isZiggoCustomer: Boolean!
  ) {
    getSimOnlyBundles(
      segment: $segment
      lifecycle: $lifecycle
      id: $id
      isZiggoCustomer: $isZiggoCustomer
      promotionType: $promotionType
    ) {
      id
      sku
      convergedPromoPrice
      youthPromoPrice
      defaultPrice
      subscriptionData
      convergedSubscriptionData
      subscriptionPrice
      convergedSubscriptionPrice
      youthSubscriptionPrice
      subscription {
        ...SubscriptionFragment
      }
    }
  }
  ${SubscriptionFragmentFragmentDoc}
`;

/**
 * __useGetSubscriptionByIdQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionByIdQuery({
 *   variables: {
 *      segment: // value for 'segment'
 *      lifecycle: // value for 'lifecycle'
 *      promotionType: // value for 'promotionType'
 *      id: // value for 'id'
 *      isZiggoCustomer: // value for 'isZiggoCustomer'
 *   },
 * });
 */
export function useGetSubscriptionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>(
    GetSubscriptionByIdDocument,
    options
  );
}
export function useGetSubscriptionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionByIdQuery, GetSubscriptionByIdQueryVariables>(
    GetSubscriptionByIdDocument,
    options
  );
}
export type GetSubscriptionByIdQueryHookResult = ReturnType<typeof useGetSubscriptionByIdQuery>;
export type GetSubscriptionByIdLazyQueryHookResult = ReturnType<typeof useGetSubscriptionByIdLazyQuery>;
export type GetSubscriptionByIdQueryResult = Apollo.QueryResult<
  GetSubscriptionByIdQuery,
  GetSubscriptionByIdQueryVariables
>;
export const GetPrivacySettingsDocument = gql`
  query getPrivacySettings($bcId: String!, $productId: String!) {
    getPrivacySettings(bcId: $bcId, productId: $productId) {
      commercial_sms
      telemarketing
      newsletter_via_email
      up_sell_via_email
      postal_mail
      customer_data_analysis
      profiling
      location_data
      customer_research
    }
  }
`;

/**
 * __useGetPrivacySettingsQuery__
 *
 * To run a query within a React component, call `useGetPrivacySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivacySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivacySettingsQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetPrivacySettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrivacySettingsQuery, GetPrivacySettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPrivacySettingsQuery, GetPrivacySettingsQueryVariables>(
    GetPrivacySettingsDocument,
    options
  );
}
export function useGetPrivacySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrivacySettingsQuery, GetPrivacySettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPrivacySettingsQuery, GetPrivacySettingsQueryVariables>(
    GetPrivacySettingsDocument,
    options
  );
}
export type GetPrivacySettingsQueryHookResult = ReturnType<typeof useGetPrivacySettingsQuery>;
export type GetPrivacySettingsLazyQueryHookResult = ReturnType<typeof useGetPrivacySettingsLazyQuery>;
export type GetPrivacySettingsQueryResult = Apollo.QueryResult<
  GetPrivacySettingsQuery,
  GetPrivacySettingsQueryVariables
>;
export const UpdatePrivacySettingsDocument = gql`
  mutation updatePrivacySettings(
    $bcId: String!
    $commercial_sms: Boolean!
    $customer_data_analysis: Boolean!
    $customer_research: Boolean!
    $location_data: Boolean!
    $newsletter_via_email: Boolean!
    $postal_mail: Boolean!
    $productId: String!
    $profiling: Boolean!
    $telemarketing: Boolean!
    $up_sell_via_email: Boolean!
  ) {
    updatePrivacySettings(
      bcId: $bcId
      commercial_sms: $commercial_sms
      customer_data_analysis: $customer_data_analysis
      customer_research: $customer_research
      location_data: $location_data
      newsletter_via_email: $newsletter_via_email
      postal_mail: $postal_mail
      productId: $productId
      profiling: $profiling
      telemarketing: $telemarketing
      up_sell_via_email: $up_sell_via_email
    ) {
      success
    }
  }
`;
export type UpdatePrivacySettingsMutationFn = Apollo.MutationFunction<
  UpdatePrivacySettingsMutation,
  UpdatePrivacySettingsMutationVariables
>;

/**
 * __useUpdatePrivacySettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePrivacySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrivacySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrivacySettingsMutation, { data, loading, error }] = useUpdatePrivacySettingsMutation({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      commercial_sms: // value for 'commercial_sms'
 *      customer_data_analysis: // value for 'customer_data_analysis'
 *      customer_research: // value for 'customer_research'
 *      location_data: // value for 'location_data'
 *      newsletter_via_email: // value for 'newsletter_via_email'
 *      postal_mail: // value for 'postal_mail'
 *      productId: // value for 'productId'
 *      profiling: // value for 'profiling'
 *      telemarketing: // value for 'telemarketing'
 *      up_sell_via_email: // value for 'up_sell_via_email'
 *   },
 * });
 */
export function useUpdatePrivacySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePrivacySettingsMutation, UpdatePrivacySettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePrivacySettingsMutation, UpdatePrivacySettingsMutationVariables>(
    UpdatePrivacySettingsDocument,
    options
  );
}
export type UpdatePrivacySettingsMutationHookResult = ReturnType<typeof useUpdatePrivacySettingsMutation>;
export type UpdatePrivacySettingsMutationResult = Apollo.MutationResult<UpdatePrivacySettingsMutation>;
export type UpdatePrivacySettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrivacySettingsMutation,
  UpdatePrivacySettingsMutationVariables
>;
export const GetAdviceDocument = gql`
  query getAdvice($bcId: String!, $isBusiness: Boolean!, $msisdn: String!) {
    getAdvice(bcId: $bcId, isBusiness: $isBusiness, msisdn: $msisdn) {
      advice {
        convergedDataAllowance
        convergedPrice
        dataAllowance
        price
        subscriptionId
        subscriptionName
        subscriptionYears
        voiceAllowance
        hasUnlimitedInternet
        hasUnlimitedCalls
      }
      adviceUp {
        convergedDataAllowance
        convergedPrice
        dataAllowance
        price
        subscriptionId
        subscriptionName
        subscriptionYears
        voiceAllowance
        hasUnlimitedInternet
        hasUnlimitedCalls
      }
      adviceDown {
        convergedDataAllowance
        convergedPrice
        dataAllowance
        price
        subscriptionId
        subscriptionName
        subscriptionYears
        voiceAllowance
        hasUnlimitedInternet
        hasUnlimitedCalls
      }
      bundleAdvice {
        label
        pp
        ppUbuy
        dataAllowance
        voiceAllowance
        convergedData
        convergedDiscount
        oldPrice
        oldAllowance
        averageInfo {
          EU_D_OOB_REV
          EU_V_OOB_REV
          AVG_EU_TOTAL_OOB_REV
        }
        dataUsage {
          key
          value
          month
        }
        voiceUsage {
          key
          value
          month
        }
      }
      billing {
        total_recurring_charge_amount {
          amount_including_vat
        }
      }
    }
  }
`;

/**
 * __useGetAdviceQuery__
 *
 * To run a query within a React component, call `useGetAdviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdviceQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      isBusiness: // value for 'isBusiness'
 *      msisdn: // value for 'msisdn'
 *   },
 * });
 */
export function useGetAdviceQuery(baseOptions: Apollo.QueryHookOptions<GetAdviceQuery, GetAdviceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdviceQuery, GetAdviceQueryVariables>(GetAdviceDocument, options);
}
export function useGetAdviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdviceQuery, GetAdviceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdviceQuery, GetAdviceQueryVariables>(GetAdviceDocument, options);
}
export type GetAdviceQueryHookResult = ReturnType<typeof useGetAdviceQuery>;
export type GetAdviceLazyQueryHookResult = ReturnType<typeof useGetAdviceLazyQuery>;
export type GetAdviceQueryResult = Apollo.QueryResult<GetAdviceQuery, GetAdviceQueryVariables>;
export const GetRetainableCustomersDocument = gql`
  query getRetainableCustomers($bcId: String!, $msisdn: String!) {
    getRetainableCustomers(bcId: $bcId, msisdn: $msisdn) {
      customer {
        retainable
      }
      ctns {
        ctn
        retainable
      }
    }
  }
`;

/**
 * __useGetRetainableCustomersQuery__
 *
 * To run a query within a React component, call `useGetRetainableCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetainableCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetainableCustomersQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      msisdn: // value for 'msisdn'
 *   },
 * });
 */
export function useGetRetainableCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<GetRetainableCustomersQuery, GetRetainableCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRetainableCustomersQuery, GetRetainableCustomersQueryVariables>(
    GetRetainableCustomersDocument,
    options
  );
}
export function useGetRetainableCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRetainableCustomersQuery, GetRetainableCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRetainableCustomersQuery, GetRetainableCustomersQueryVariables>(
    GetRetainableCustomersDocument,
    options
  );
}
export type GetRetainableCustomersQueryHookResult = ReturnType<typeof useGetRetainableCustomersQuery>;
export type GetRetainableCustomersLazyQueryHookResult = ReturnType<typeof useGetRetainableCustomersLazyQuery>;
export type GetRetainableCustomersQueryResult = Apollo.QueryResult<
  GetRetainableCustomersQuery,
  GetRetainableCustomersQueryVariables
>;
export const GetSubscriptionInfoDocument = gql`
  query getSubscriptionInfo($bcId: String!, $productId: String!, $productMsisdn: String!, $roles: [Roles!]!) {
    getSubscriptionInfo(bcId: $bcId, productId: $productId, productMsisdn: $productMsisdn, roles: $roles) {
      product {
        product_msisdn
        product_description
        product_status
        device_rc {
          id
          monthly_amount
          start_date
          end_date
        }
        retainable
      }
      contract {
        start_date
        end_date
      }
      isRetainable
      canAddRedTogether
      isRedTogetherCustomer
    }
  }
`;

/**
 * __useGetSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionInfoQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      productId: // value for 'productId'
 *      productMsisdn: // value for 'productMsisdn'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetSubscriptionInfoQuery(
  baseOptions: Apollo.QueryHookOptions<GetSubscriptionInfoQuery, GetSubscriptionInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSubscriptionInfoQuery, GetSubscriptionInfoQueryVariables>(
    GetSubscriptionInfoDocument,
    options
  );
}
export function useGetSubscriptionInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionInfoQuery, GetSubscriptionInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSubscriptionInfoQuery, GetSubscriptionInfoQueryVariables>(
    GetSubscriptionInfoDocument,
    options
  );
}
export type GetSubscriptionInfoQueryHookResult = ReturnType<typeof useGetSubscriptionInfoQuery>;
export type GetSubscriptionInfoLazyQueryHookResult = ReturnType<typeof useGetSubscriptionInfoLazyQuery>;
export type GetSubscriptionInfoQueryResult = Apollo.QueryResult<
  GetSubscriptionInfoQuery,
  GetSubscriptionInfoQueryVariables
>;
export const GetSimsDocument = gql`
  query getSims($mobileProductId: String!) {
    getSims(mobileProductId: $mobileProductId) {
      distributionMethod
      iccId
      location
      msisdn
      networkStatus
      primary
      pukCode
      type
    }
  }
`;

/**
 * __useGetSimsQuery__
 *
 * To run a query within a React component, call `useGetSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimsQuery({
 *   variables: {
 *      mobileProductId: // value for 'mobileProductId'
 *   },
 * });
 */
export function useGetSimsQuery(baseOptions: Apollo.QueryHookOptions<GetSimsQuery, GetSimsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSimsQuery, GetSimsQueryVariables>(GetSimsDocument, options);
}
export function useGetSimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimsQuery, GetSimsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSimsQuery, GetSimsQueryVariables>(GetSimsDocument, options);
}
export type GetSimsQueryHookResult = ReturnType<typeof useGetSimsQuery>;
export type GetSimsLazyQueryHookResult = ReturnType<typeof useGetSimsLazyQuery>;
export type GetSimsQueryResult = Apollo.QueryResult<GetSimsQuery, GetSimsQueryVariables>;
export const GetEsimStatusDocument = gql`
  query getEsimStatus($mobileProductId: String!, $iccId: String!) {
    getEsimStatus(mobileProductId: $mobileProductId, iccId: $iccId) {
      description
      lastUpdated
      value
    }
  }
`;

/**
 * __useGetEsimStatusQuery__
 *
 * To run a query within a React component, call `useGetEsimStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEsimStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEsimStatusQuery({
 *   variables: {
 *      mobileProductId: // value for 'mobileProductId'
 *      iccId: // value for 'iccId'
 *   },
 * });
 */
export function useGetEsimStatusQuery(
  baseOptions: Apollo.QueryHookOptions<GetEsimStatusQuery, GetEsimStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEsimStatusQuery, GetEsimStatusQueryVariables>(GetEsimStatusDocument, options);
}
export function useGetEsimStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEsimStatusQuery, GetEsimStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEsimStatusQuery, GetEsimStatusQueryVariables>(GetEsimStatusDocument, options);
}
export type GetEsimStatusQueryHookResult = ReturnType<typeof useGetEsimStatusQuery>;
export type GetEsimStatusLazyQueryHookResult = ReturnType<typeof useGetEsimStatusLazyQuery>;
export type GetEsimStatusQueryResult = Apollo.QueryResult<GetEsimStatusQuery, GetEsimStatusQueryVariables>;
export const GetEsimActivationCodeDocument = gql`
  query getEsimActivationCode($mobileProductId: String!, $iccId: String!, $challengeTokenId: String) {
    getEsimActivationCode(mobileProductId: $mobileProductId, iccId: $iccId, challengeTokenId: $challengeTokenId) {
      activationCode
      confirmationCode
      matchingId
      smdpFqdn
    }
  }
`;

/**
 * __useGetEsimActivationCodeQuery__
 *
 * To run a query within a React component, call `useGetEsimActivationCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEsimActivationCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEsimActivationCodeQuery({
 *   variables: {
 *      mobileProductId: // value for 'mobileProductId'
 *      iccId: // value for 'iccId'
 *      challengeTokenId: // value for 'challengeTokenId'
 *   },
 * });
 */
export function useGetEsimActivationCodeQuery(
  baseOptions: Apollo.QueryHookOptions<GetEsimActivationCodeQuery, GetEsimActivationCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEsimActivationCodeQuery, GetEsimActivationCodeQueryVariables>(
    GetEsimActivationCodeDocument,
    options
  );
}
export function useGetEsimActivationCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEsimActivationCodeQuery, GetEsimActivationCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEsimActivationCodeQuery, GetEsimActivationCodeQueryVariables>(
    GetEsimActivationCodeDocument,
    options
  );
}
export type GetEsimActivationCodeQueryHookResult = ReturnType<typeof useGetEsimActivationCodeQuery>;
export type GetEsimActivationCodeLazyQueryHookResult = ReturnType<typeof useGetEsimActivationCodeLazyQuery>;
export type GetEsimActivationCodeQueryResult = Apollo.QueryResult<
  GetEsimActivationCodeQuery,
  GetEsimActivationCodeQueryVariables
>;
export const ActivateSimDocument = gql`
  mutation activateSim($mobileProductId: String!, $iccId: String!) {
    activateSim(mobileProductId: $mobileProductId, iccId: $iccId) {
      success
    }
  }
`;
export type ActivateSimMutationFn = Apollo.MutationFunction<ActivateSimMutation, ActivateSimMutationVariables>;

/**
 * __useActivateSimMutation__
 *
 * To run a mutation, you first call `useActivateSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSimMutation, { data, loading, error }] = useActivateSimMutation({
 *   variables: {
 *      mobileProductId: // value for 'mobileProductId'
 *      iccId: // value for 'iccId'
 *   },
 * });
 */
export function useActivateSimMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateSimMutation, ActivateSimMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateSimMutation, ActivateSimMutationVariables>(ActivateSimDocument, options);
}
export type ActivateSimMutationHookResult = ReturnType<typeof useActivateSimMutation>;
export type ActivateSimMutationResult = Apollo.MutationResult<ActivateSimMutation>;
export type ActivateSimMutationOptions = Apollo.BaseMutationOptions<ActivateSimMutation, ActivateSimMutationVariables>;
export const BlockSimDocument = gql`
  mutation blockSim(
    $billing_customer_id: String!
    $assigned_product_id: String!
    $sim_id: String!
    $reason_code: String!
  ) {
    blockSim(
      billing_customer_id: $billing_customer_id
      assigned_product_id: $assigned_product_id
      sim_id: $sim_id
      reason_code: $reason_code
    ) {
      success
    }
  }
`;
export type BlockSimMutationFn = Apollo.MutationFunction<BlockSimMutation, BlockSimMutationVariables>;

/**
 * __useBlockSimMutation__
 *
 * To run a mutation, you first call `useBlockSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockSimMutation, { data, loading, error }] = useBlockSimMutation({
 *   variables: {
 *      billing_customer_id: // value for 'billing_customer_id'
 *      assigned_product_id: // value for 'assigned_product_id'
 *      sim_id: // value for 'sim_id'
 *      reason_code: // value for 'reason_code'
 *   },
 * });
 */
export function useBlockSimMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockSimMutation, BlockSimMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockSimMutation, BlockSimMutationVariables>(BlockSimDocument, options);
}
export type BlockSimMutationHookResult = ReturnType<typeof useBlockSimMutation>;
export type BlockSimMutationResult = Apollo.MutationResult<BlockSimMutation>;
export type BlockSimMutationOptions = Apollo.BaseMutationOptions<BlockSimMutation, BlockSimMutationVariables>;
export const UnblockSimDocument = gql`
  mutation unblockSim($billing_customer_id: String!, $assigned_product_id: String!, $sim_id: String!) {
    unblockSim(billing_customer_id: $billing_customer_id, assigned_product_id: $assigned_product_id, sim_id: $sim_id) {
      success
    }
  }
`;
export type UnblockSimMutationFn = Apollo.MutationFunction<UnblockSimMutation, UnblockSimMutationVariables>;

/**
 * __useUnblockSimMutation__
 *
 * To run a mutation, you first call `useUnblockSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockSimMutation, { data, loading, error }] = useUnblockSimMutation({
 *   variables: {
 *      billing_customer_id: // value for 'billing_customer_id'
 *      assigned_product_id: // value for 'assigned_product_id'
 *      sim_id: // value for 'sim_id'
 *   },
 * });
 */
export function useUnblockSimMutation(
  baseOptions?: Apollo.MutationHookOptions<UnblockSimMutation, UnblockSimMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnblockSimMutation, UnblockSimMutationVariables>(UnblockSimDocument, options);
}
export type UnblockSimMutationHookResult = ReturnType<typeof useUnblockSimMutation>;
export type UnblockSimMutationResult = Apollo.MutationResult<UnblockSimMutation>;
export type UnblockSimMutationOptions = Apollo.BaseMutationOptions<UnblockSimMutation, UnblockSimMutationVariables>;
export const GetNotificationSettingsDocument = gql`
  query getNotificationSettings($bcId: String!, $productId: String!) {
    getNotificationSettings(bcId: $bcId, productId: $productId) {
      each_call
    }
  }
`;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(
    GetNotificationSettingsDocument,
    options
  );
}
export function useGetNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(
    GetNotificationSettingsDocument,
    options
  );
}
export type GetNotificationSettingsQueryHookResult = ReturnType<typeof useGetNotificationSettingsQuery>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingsLazyQuery>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<
  GetNotificationSettingsQuery,
  GetNotificationSettingsQueryVariables
>;
export const UpdateNotificationSettingsDocument = gql`
  mutation updateNotificationSettings($bcId: String!, $productId: String!, $each_call: Boolean!) {
    updateNotificationSettings(bcId: $bcId, productId: $productId, each_call: $each_call) {
      success
    }
  }
`;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      bcId: // value for 'bcId'
 *      productId: // value for 'productId'
 *      each_call: // value for 'each_call'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationSettingsMutation,
    UpdateNotificationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(
    UpdateNotificationSettingsDocument,
    options
  );
}
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationSettingsMutation,
  UpdateNotificationSettingsMutationVariables
>;
export const TerminateContractDocument = gql`
  mutation terminateContract(
    $salutation: String!
    $first_name: String!
    $middle_name: String
    $last_name: String!
    $date_of_birth: Float!
    $street: String!
    $house_number: String!
    $house_number_extension: String
    $zipcode: String!
    $city: String!
    $mobile_1: String!
    $mobile_2: String
    $mobile_3: String
    $transfer_to_prepaid: Boolean
    $attachment_id_1: String!
    $attachment_id_2: String
    $attachment_id_3: String
    $send_voicemail_message: Boolean
    $family_salutation: String!
    $family_first_name: String!
    $family_middle_name: String
    $family_last_name: String!
    $family_street: String
    $family_city: String
    $family_house_number: String
    $family_house_number_extension: String
    $family_postal_code: String
    $family_email: String!
    $family_phone: String!
    $family_customer_id: String
    $transfer_to_other_customer: Boolean
    $family_birthdate: Float
    $family_iban: String
    $family_iban_name: String
    $family_identification_type: String
    $family_identification_number: String
    $family_identification_expiry_date: Float
  ) {
    terminateContract(
      salutation: $salutation
      first_name: $first_name
      middle_name: $middle_name
      last_name: $last_name
      date_of_birth: $date_of_birth
      street: $street
      house_number: $house_number
      house_number_extension: $house_number_extension
      zipcode: $zipcode
      city: $city
      mobile_1: $mobile_1
      mobile_2: $mobile_2
      mobile_3: $mobile_3
      transfer_to_prepaid: $transfer_to_prepaid
      attachment_id_1: $attachment_id_1
      attachment_id_2: $attachment_id_2
      attachment_id_3: $attachment_id_3
      send_voicemail_message: $send_voicemail_message
      family_salutation: $family_salutation
      family_first_name: $family_first_name
      family_middle_name: $family_middle_name
      family_last_name: $family_last_name
      family_street: $family_street
      family_city: $family_city
      family_house_number: $family_house_number
      family_house_number_extension: $family_house_number_extension
      family_postal_code: $family_postal_code
      family_email: $family_email
      family_phone: $family_phone
      transfer_to_other_customer: $transfer_to_other_customer
      family_birthdate: $family_birthdate
      family_iban: $family_iban
      family_iban_name: $family_iban_name
      family_identification_type: $family_identification_type
      family_identification_number: $family_identification_number
      family_identification_expiry_date: $family_identification_expiry_date
      family_customer_id: $family_customer_id
    ) {
      ticket_id
    }
  }
`;
export type TerminateContractMutationFn = Apollo.MutationFunction<
  TerminateContractMutation,
  TerminateContractMutationVariables
>;

/**
 * __useTerminateContractMutation__
 *
 * To run a mutation, you first call `useTerminateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateContractMutation, { data, loading, error }] = useTerminateContractMutation({
 *   variables: {
 *      salutation: // value for 'salutation'
 *      first_name: // value for 'first_name'
 *      middle_name: // value for 'middle_name'
 *      last_name: // value for 'last_name'
 *      date_of_birth: // value for 'date_of_birth'
 *      street: // value for 'street'
 *      house_number: // value for 'house_number'
 *      house_number_extension: // value for 'house_number_extension'
 *      zipcode: // value for 'zipcode'
 *      city: // value for 'city'
 *      mobile_1: // value for 'mobile_1'
 *      mobile_2: // value for 'mobile_2'
 *      mobile_3: // value for 'mobile_3'
 *      transfer_to_prepaid: // value for 'transfer_to_prepaid'
 *      attachment_id_1: // value for 'attachment_id_1'
 *      attachment_id_2: // value for 'attachment_id_2'
 *      attachment_id_3: // value for 'attachment_id_3'
 *      send_voicemail_message: // value for 'send_voicemail_message'
 *      family_salutation: // value for 'family_salutation'
 *      family_first_name: // value for 'family_first_name'
 *      family_middle_name: // value for 'family_middle_name'
 *      family_last_name: // value for 'family_last_name'
 *      family_street: // value for 'family_street'
 *      family_city: // value for 'family_city'
 *      family_house_number: // value for 'family_house_number'
 *      family_house_number_extension: // value for 'family_house_number_extension'
 *      family_postal_code: // value for 'family_postal_code'
 *      family_email: // value for 'family_email'
 *      family_phone: // value for 'family_phone'
 *      family_customer_id: // value for 'family_customer_id'
 *      transfer_to_other_customer: // value for 'transfer_to_other_customer'
 *      family_birthdate: // value for 'family_birthdate'
 *      family_iban: // value for 'family_iban'
 *      family_iban_name: // value for 'family_iban_name'
 *      family_identification_type: // value for 'family_identification_type'
 *      family_identification_number: // value for 'family_identification_number'
 *      family_identification_expiry_date: // value for 'family_identification_expiry_date'
 *   },
 * });
 */
export function useTerminateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<TerminateContractMutation, TerminateContractMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TerminateContractMutation, TerminateContractMutationVariables>(
    TerminateContractDocument,
    options
  );
}
export type TerminateContractMutationHookResult = ReturnType<typeof useTerminateContractMutation>;
export type TerminateContractMutationResult = Apollo.MutationResult<TerminateContractMutation>;
export type TerminateContractMutationOptions = Apollo.BaseMutationOptions<
  TerminateContractMutation,
  TerminateContractMutationVariables
>;
export const UpdateDefaultSelectedAccountDocument = gql`
  mutation updateDefaultSelectedAccount($commonId: String!, $domain: Domain!, $sub: String!) {
    updateDefaultSelectedAccount(commonId: $commonId, domain: $domain, sub: $sub) {
      success
    }
  }
`;
export type UpdateDefaultSelectedAccountMutationFn = Apollo.MutationFunction<
  UpdateDefaultSelectedAccountMutation,
  UpdateDefaultSelectedAccountMutationVariables
>;

/**
 * __useUpdateDefaultSelectedAccountMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultSelectedAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultSelectedAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultSelectedAccountMutation, { data, loading, error }] = useUpdateDefaultSelectedAccountMutation({
 *   variables: {
 *      commonId: // value for 'commonId'
 *      domain: // value for 'domain'
 *      sub: // value for 'sub'
 *   },
 * });
 */
export function useUpdateDefaultSelectedAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDefaultSelectedAccountMutation,
    UpdateDefaultSelectedAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDefaultSelectedAccountMutation, UpdateDefaultSelectedAccountMutationVariables>(
    UpdateDefaultSelectedAccountDocument,
    options
  );
}
export type UpdateDefaultSelectedAccountMutationHookResult = ReturnType<typeof useUpdateDefaultSelectedAccountMutation>;
export type UpdateDefaultSelectedAccountMutationResult = Apollo.MutationResult<UpdateDefaultSelectedAccountMutation>;
export type UpdateDefaultSelectedAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateDefaultSelectedAccountMutation,
  UpdateDefaultSelectedAccountMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
