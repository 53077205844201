import { PiiMask } from '@vodafoneziggo/sandwich';
import { Heading } from '@vodafoneziggo/sandwich/components/heading';
import { VFWS10SYSTEM_ADMIN, VFWS10SYSTEM_CHEVRONRIGHT, VFWS10SYSTEM_SIGNEDIN } from '@vodafoneziggo/sandwich/icons';
import { useTranslations } from 'components/Intl/useTranslations';
import Page from 'components/Page';
import { UserContext } from 'components/User';
import { IBillingCustomer } from 'models';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container } from 'styled-bootstrap-grid';
import * as S from './choose-billing-customer.styles';
import ProspectCustomerOverview from './components/ProspectCustomerOverview';

const ChooseBillingCustomer = () => {
  const { intl, translate } = useTranslations();
  const navigate = useNavigate();
  const { state: userState, setBillingCustomer } = useContext(UserContext);

  if (!userState.user) return null;

  /**
   * Redirect enterprise users to the correct module
   */
  if (userState.user && userState.user.enterprise && userState.user.enterprise.nr_accounts) {
    window.location.href = '/zakelijk/my/v2';

    return null;
  }

  return (
    <Page.Page
      title={intl.formatMessage({ id: 'page-titles.maak-een-keuze' })}
      metaTitle={intl.formatMessage({ id: 'meta-titles.maak-een-keuze' })}>
      <Container>
        {userState.userIsProspect ? (
          <ProspectCustomerOverview />
        ) : (
          <Page.Wrapper>
            <Col>
              <Heading.H2>{translate('pages.choose-billing-customer.title')}</Heading.H2>
              <span>{translate('pages.choose-billing-customer.subtext')}</span>
            </Col>
            <Col md={6} lg={4}>
              <S.List data-testid="maak-een-keuze">
                {userState.user.billing_customers &&
                  userState.user.billing_customers.map((billingCustomer: IBillingCustomer, index) => (
                    <S.ListItem
                      key={billingCustomer.billing_customer_id}
                      id={`bc_menu_item_${index}`}
                      data-testid={`bc_${billingCustomer.billing_customer_id}`}
                      isActive={billingCustomer.billing_customer_id === `${userState.activeBcId}`}
                      onClick={() => {
                        setBillingCustomer(billingCustomer);
                        navigate('/');
                      }}>
                      <S.ListItemIcon
                        icon={
                          billingCustomer.billing_customer_id === `${userState.activeBcId}`
                            ? VFWS10SYSTEM_SIGNEDIN
                            : VFWS10SYSTEM_ADMIN
                        }
                      />
                      <S.ListItemContent>
                        <S.ListItemTitle>{billingCustomer.billing_customer_label}</S.ListItemTitle>
                        <PiiMask>
                          <S.ListItemSubtitle>
                            {translate('pages.choose-billing-customer.id')}: {billingCustomer.billing_customer_id}
                          </S.ListItemSubtitle>
                        </PiiMask>
                      </S.ListItemContent>
                      <S.ListItemChevron
                        className="billing-customer-list__chevron"
                        icon={VFWS10SYSTEM_CHEVRONRIGHT}
                        noDefaultSize
                      />
                    </S.ListItem>
                  ))}
              </S.List>
            </Col>
          </Page.Wrapper>
        )}
      </Container>
    </Page.Page>
  );
};

export default ChooseBillingCustomer;
