import { relayService } from '@vodafonepub/relay-service';
import NBAtemplate_template from './template/template';
import { DisplayModes, EventTypes, INBA, INBACache, INBAOptions, INBATemplateVars } from './types/types';
import { closest } from './utils/element-utils';
import { getNbaTypeFromId } from './utils/nba';

/**
 * @class NBA
 */
class NBA {
  /** The options for this class */
  private options: INBAOptions = {
    // If true, click listeners are added to the resulting template, when calling the `getNba` method
    addEventListeners: true,

    // A fixed endpoint pointing to the AEM service that provides the JSON
    apiEndpoint: 'https://origin.vodafone.nl/services/nbas?channel=myVodafoneWeb',

    // The default display mode, which can be overridden
    displayMode: DisplayModes.LANDSCAPE,
  };

  /**
   * NBA's that have already been retrieved will be cached internally, to prevent unnneeded requests
   */
  private _nbaCache: INBACache = {};

  /** The key on which disabled nba's are stored. */
  private _disabledLocalStorageKey = 'disabled_nbas';

  /**
   * When tracking a click, this boolean is flipped to false and future tracking will not occur.
   * Effectively preventing it from firing double events.
   */
  isOutClicked = false;

  /**
   * Class constructor
   * @param { options } IOptions
   */
  constructor(options?: Partial<INBAOptions>) {
    this.setOptions(options);
  }

  /**
   * As the main method of this class this will return the NBA and its markup for the first applicable NBA to the user,
   * or for a custom NBA id that can be provided to this method. If this method is not provided with an array of ids, then
   * it will consult the Datalayer for applicable NBA's.
   */
  // : Promise<{ nba: INBA; markup: string } | undefined>
  public getNba = async (nbaIds?: string[]): Promise<{ nba: INBA; markup: string } | undefined> => {
    const ids = nbaIds || (await this.getNbaIdsForCurrentUser());
    const nba = await this.getFirstApplicableNba(ids);

    if (!nba) {
      return;
    }

    let markup = '';

    try {
      markup = this.getNbaMarkup(nba, this.options.displayMode);

      if (this.options.addEventListeners) {
        this.addEventListeners(nba);
      }
    } catch (e) {
      console.log(e);
    }

    return { nba, markup };
  };

  /**
   * Set the options for this class
   * @param { options } IOptions
   */
  public setOptions = (options?: Partial<INBAOptions>) => {
    this.options = { ...this.options, ...options };
  };

  /**
   * Returns all available NBA's that are provided by the AEM service, as a promise
   * @returns { Promise<INBA[]> }
   */
  public getAllNbas = async (): Promise<INBA[]> => {
    const response = await this.fetchNba();

    // Should the endpoint fail to fetch the NBA's and return undefined, then we always return an array
    if (!response || !response.length) {
      return [];
    }

    // Store each NBA to cache so we don't have to fetch them from the API again
    this._nbaCache = response.reduce((acc, nba) => ({ ...acc, [nba.id]: nba }), {});

    return response;
  };

  /**
   * Returns all NBA types currently in use. Filters out certain types if they are provided as an argument
   * @param { excludedTypes } string[] are the types that should not filtered out
   * @returns { string[] }
   */
  public getNbaTypes = async (excludedTypes: string[] = []): Promise<string[]> => {
    const nbas = await this.getAllNbas();

    return nbas
      .reduce<string[]>((acc, nba) => {
        const type = getNbaTypeFromId(nba.id);

        if (!acc.includes(type)) acc.push(type);

        return acc;
      }, [])
      .filter(nbaType => !excludedTypes.includes(nbaType));
  };

  /**
   * Get the NBA either from cache or from the AEM service. When fetched the response
   * is written to cache.
   * @param { nbaId } string
   * @returns { Promise<INBA | undefined> }
   */
  public getByNbaId = async (nbaId: string): Promise<INBA | undefined> => {
    // First try to collect the nba from cache
    if (this._nbaCache && this._nbaCache[nbaId]) {
      return this._nbaCache[nbaId];
    }

    const response = await this.fetchNba(nbaId);

    if (!response || !Object.keys(response).length) {
      return;
    }

    // Once found, it is stored in cache before it is returned
    this._nbaCache = Object.assign(this._nbaCache, { [nbaId]: response });

    return response;
  };

  /**
   * Returns a HTML template for the provided NBA model
   * @param { INBA } nba
   * @returns { string }
   */
  public getNbaMarkup = (nba: INBA, displayMode: DisplayModes): string => {
    let html = NBAtemplate_template.getTemplate(nba, displayMode);

    // Hydrate the template with the templateVars
    if (this.options.templateVars) {
      html = this.hydrateTemplate(html, this.options.templateVars);
    }

    // Replace urls to the business shop if business.
    if (this.options.isConsumer === false) {
      html = this.replaceConsumerUrlWithBusinessUrl(html);
    }

    return html;
  };

  /**
   * Consults the Datalayer for one ore more NBA's that applicable to the user, which are then
   * assigned to the private class variable `nbaIds`. Based on this array, NBA's will be resolved
   * from the AEM service.
   */
  /* eslint-disable-next-line require-await */
  private getNbaIdsForCurrentUser = async () => relayService.getNbaIds(true);

  /**
   * Returns the first applicable NBA from a list of NBA ids
   * @param { nbaIds } string[] An array with NBAid's ['MCC_RET1234', 'MCC_RET2345', 'MCC_RET3456']
   */
  private getFirstApplicableNba = async (nbaIds: string[]): Promise<INBA | undefined> => {
    let nba;
    let nbaId;

    // Loop trough NBA's untill we've got one with nba, or stop when we have no NBA's left.
    while (!nba && nbaIds.length) {
      nbaId = this.getFirstApplicableNbaId(nbaIds);

      // Cant select any NBA. Break;
      if (!nbaId) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop
      nba = await this.getByNbaId(nbaId);

      if (!nba) {
        // Remove the first nbaId and try again.
        nbaIds.shift();
      }
    }

    return nba;
  };

  /**
   * Select an applicable NBA from a list of NBA ids. It checks if the NBA is not disabled, or should not be returned based on
   * the `onlyOfType` option that can be provided to this class.
   * @param { nbaIds } string[] An array with NBAid's ['MBC_RET1234', 'MBC_RET2345', 'MBC_RET3456']
   * @returns { string | undefined }
   */
  private getFirstApplicableNbaId = (nbaIds: string[]): string | undefined => {
    if (!nbaIds.length) return;

    // Remove any NBA ids that are disabled
    const filteredNbas = nbaIds.filter(nbaId => !this.isNbaDisabled(nbaId));

    // If the `onlyOfType` array is defined, then return the first NBA id that matches of of the types
    if (Array.isArray(this.options.onlyOfType) && this.options.onlyOfType.length > 0) {
      return filteredNbas.find(nbaId => this.isNbaOneOfTypes(nbaId, this.options.onlyOfType || []));
    }

    return filteredNbas[0];
  };

  /**
   * Check if an NBA matches one or more of the supplied types
   * @param { string } nbaId
   * @param { types } NBATypes[]
   * @returns { boolean }
   */
  private isNbaOneOfTypes = (nbaId: string, types: string[]): boolean =>
    types.some(type => getNbaTypeFromId(nbaId) === type);

  /**
   * Returns if an NBA is disabled by checking if it is set as such in localstorage
   * @param { nbaId } string
   * @returns { boolean }
   */
  private isNbaDisabled = (nbaId: string): boolean => this.getDisabledNbaIds().some(id => id === nbaId);

  /**
   * Get all disabled NBA's from localStorage
   * @returns { string[] } An array with NBA-ids ['MBC_RET123','MBC_RET234','MBC_RET345']
   */
  private getDisabledNbaIds = (): string[] => JSON.parse(localStorage.getItem(this._disabledLocalStorageKey) || '[]');

  /**
   * Fetch the nba from the AEM service. If an id is provided, the AEM service is called for only that
   * particular NBA id. If not found, the endpoint returns a 404 and the response from this method can be undefined.
   * When no id is provided, all NBA's will be fetched, which will always result in an array response (even when it's empty)
   * @param { string } nbaId
   * @returns { Promise<INBA | undefined> }
   * @returns { Promise<INBA[]> }
   */
  private async fetchNba<T>(nbaId?: T): Promise<(T extends string ? INBA : INBA[]) | undefined> {
    const url = `${this.options.apiEndpoint}${nbaId ? `&id=${nbaId}` : ''}`;

    let response: T extends string ? INBA : INBA[];

    try {
      if ('fetch' in window) {
        response = await fetch(url).then(res => res.json());
      } else {
        // IE11 support w/o polyfill.
        const xhttp = new XMLHttpRequest();

        xhttp.open('GET', `${this.options.apiEndpoint}&id=${nbaId}`, false);
        xhttp.send();
        response = JSON.parse(xhttp.responseText);
      }

      return response;
    } catch (error) {
      // eslint-disable-next-line no-useless-return
      return;
    }
  }

  /**
   * Hydrate the template with our custom Template vars.
   * @param { html } string - The HTML from the tempalte
   * @param { vars } INBATemplateVars - The template variables.
   * @returns { string }
   */
  private hydrateTemplate = (html: string, vars: INBATemplateVars): string => {
    if (!vars) return html;

    const replacedHtml = html.replace(/{[^{}]+}/g, key => {
      // Replace {address} with vars.address or if non-existent, replace it with an empty string.
      return vars[key.replace(/[{}]+/g, '')] || '';
    });

    return replacedHtml;
  };

  /**
   * Replace all consumer urls to shop with business urls
   * @param { html } string - The HTML from the tempalte
   * @returns { string }
   */
  private replaceConsumerUrlWithBusinessUrl = (html: string): string => {
    return html.replace(/\/shop\/verlengen\//g, '/zakelijk/shop/verlengen/');
  };

  /**
   * Add click event listeners to the NBA and immediately track an impression
   * @param { nba } INBA
   */
  private addEventListeners = (nba: INBA) => {
    // Always track an impression
    this.trackEvent(EventTypes.IMPRESSION, nba, nba.title);

    document.body.addEventListener('click', async (event: MouseEvent) => {
      if (this.isOutClicked || !event.target) {
        return;
      }

      // First, make sure we got the anchor, even when clicking an element within an anchor (like the center image).
      const element = event.target as Element;
      const a: HTMLAnchorElement = (element.tagName === 'a' ? element : closest(element, 'a')) as HTMLAnchorElement;

      // Stop if it's not a link or does not match our NBA selector.
      if (!a || !a.href || !closest(a, `#nba-${nba.id}`)) {
        return;
      }

      event.preventDefault();
      a.classList.add('loading');

      // Prevent firing double events
      this.isOutClicked = true;

      // Track it's conversion
      const eventName = a.getAttribute('data-click-event') || 'content';

      await this.waitForPegaClickProcessing();

      // Dismiss events will hide the nba from the user instantly and tracks dismiss event
      if (eventName === 'dismiss') {
        const nbaParentContainer = closest(a, `#nba-${nba.id}`);

        if (nbaParentContainer) {
          nbaParentContainer.remove();
        }

        this.trackEvent(EventTypes.DISMISS, nba, nba.title);
      } else this.trackEvent(EventTypes.CLICK, nba, eventName);

      this.finishClickEvent(a);
    });
  };

  /**
   * Finish the onClick event listener.
   * @param { element } HTMLAnchorElement
   */
  private finishClickEvent = (element: HTMLAnchorElement) => {
    element.classList.remove('loading');
    window.location.href = element.href;
  };

  /**
   * Track a NBA conversion event and send it to the datalayer.
   * @param { type } EventTypes - The type of the event.
   * @param { nba } INBA
   */
  private trackEvent = (type: EventTypes, nba: INBA, name: string) => {
    try {
      window._ddm.trigger(`nba.${type}`, {
        data: {
          name,
          id: nba.id,
          event: type,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Since Relay scripts will do additional processing for click events, we need to make sure all events are ready, before sending
   * off the user to a new location.
   */
  private waitForPegaClickProcessing = () =>
    new Promise<void>(resolve => {
      const start = Date.now();

      try {
        // In case we don't receive the click_processed event, we don't want to keep
        // the user waiting any longer.
        const fallbackTimeout = setTimeout(() => {
          // Track an error, to be able to monitor the callbacks we are waiting for.
          this.trackError(
            'callback NBA click VF my || no callback received',
            'No pega.nba.click_processed event was received after nba.click was sent, user was forwarded with a delay!'
          );
          // Resolve the promise, so the user can be redirected.
          resolve();
        }, 1000);

        // Listen for the event fired by Relay, to make sure all processing of the click has been done.
        window._ddm.listen('pega.nba.click_processed', () => {
          clearTimeout(fallbackTimeout);

          if (Date.now() - start > 500) {
            // Track an error, to be able to monitor the callback performance.
            this.trackError(
              'callback NBA click VF my || slow response',
              'pega.nba.click_processed event was received after 500 ms, user was forwarded with a delay!'
            );
          }

          resolve();
        });
      } catch (error) {
        // In case something goes wrong, just ignore the error and allow the user to be redirected.
        resolve();
      }
    });

  /**
   * Track an error we can identify in GA.
   */
  private trackError = (action: string, verbose: string) => {
    window._ddm.trigger('ga_event', {
      action,
      category: 'ddm_error',
      label: verbose,
      nonInteraction: true,
    });
  };
}

export default NBA;
