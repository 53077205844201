import { DashboardConversations } from 'pages/Dashboard/components/DashboardConversation/dashboard-conversation.model';
import { InvoicesConversations, PegaCategories } from 'models/invoices-conversation.model';
import { UsageConversations } from 'pages/Usage/components/UsageConversation/usage-conversation.model';

export interface IConversation<ConversationType> {
  category: string;
  card_type: ConversationType;
  priority: number;
}

/**
 * The priority of available conversation types (a higher number is more important).
 * Priorities are used only by the corresponding enum and the respective IConversation base interface,
 * ie. IUsageConversationBase, IDashboardConversationBase and IInvoiceConversationBase.
 */
export const conversationPriorities: {
  [key in DashboardConversations | InvoicesConversations | UsageConversations]: number;
} = {
  [DashboardConversations.RETENTION]: 60,
  [DashboardConversations.ROAMING]: 50,
  [DashboardConversations.ZIGGO_DATA_BUNDLES]: 15,
  [UsageConversations.OUT_OF_DATA]: 80,
  [UsageConversations.DATA_USAGE_BLOCKED]: 60,
  [UsageConversations.ROAMING_DATA_USAGE_BLOCKED]: 40,
  [UsageConversations.LOCATION_IS_ROAMING]: 20,
  [InvoicesConversations.RECURRING_CHANGED_BILL]: 55,
  [InvoicesConversations.FIRST_BILL]: 80,
  [InvoicesConversations.USAGE_CHARGES_BILL]: 30,
  [InvoicesConversations.SPECIAL_BILL]: 10,
};

/**
 * The priority of conversations which are based on Pega only, and don't need any additional backend logic.
 * Additional Pega conversations need to be added to this list, with the appropriate priority. A higher number means more priority.
 */
export const PegaConversationPriorities: {
  [key in PegaCategories]?: number;
} = {
  [PegaCategories.CONTRACT_TERMINATION]: 70,
};
