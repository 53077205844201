import { Color, Loader } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { FeaturesContext } from 'containers/featuresContext';
import { FEATURES } from 'models';
import React, { useContext } from 'react';
import * as S from './header-space.styles';

const AsyncSnow = React.lazy(() => import('components/Snow' /* webpackChunkName: "Snow" */));

interface HeaderSpaceProps {
  title?: string;
  selector?: React.ReactNode;
  conversation?: React.ReactNode;
  backgroundColor?: Color;
}

const HeaderSpace = (props: HeaderSpaceProps) => {
  const { title, selector, conversation, backgroundColor } = props;
  const { isFeatureEnabled } = useContext(FeaturesContext);

  return (
    <S.HeaderSpace backgroundColor={backgroundColor}>
      <S.Container>
        <S.Breadcrumbs withoutCurrentRoute />
        {title && <S.Title as={HeadingTags.H1}>{title}</S.Title>}
        {selector && <S.Selector>{selector}</S.Selector>}
        {conversation && <S.Conversation>{conversation}</S.Conversation>}
      </S.Container>

      {isFeatureEnabled(FEATURES.SNOW) && (
        <React.Suspense fallback={<Loader />}>
          <AsyncSnow />
        </React.Suspense>
      )}
    </S.HeaderSpace>
  );
};

export default HeaderSpace;
