import { ProductSummariesContext } from 'containers/products';
import { ProductServiceType } from 'pages/Products/products.container';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

interface IOnlyLoanRedirectionProps {
  children: React.ReactNode;
}

const OnlyLoanRedirection = ({ children }: IOnlyLoanRedirectionProps) => {
  const {
    state: { hasMobileOffer, currentProduct },
  } = useContext(ProductSummariesContext);

  if (typeof hasMobileOffer === 'boolean' && !hasMobileOffer) return <Navigate to="/producten" />;

  if (currentProduct?.service_type === ProductServiceType.LOAN_DEVICE) return <Navigate to="/producten" />;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default OnlyLoanRedirection;
