import RelayService from '@vodafonepub/relay-service';
import { useAuthSubscription } from 'containers/auth';
import { ContainerStatuses, defaultLoadingStatus } from 'models/container-statuses.model';
import {
  IConversationInvoiceBase,
  IInvoiceConversation,
  IInvoiceConversationBase,
  InvoicesConversations,
  PegaCategories,
} from 'models/invoices-conversation.model';
import { getInvoiceUrl } from 'pages/Invoices/invoice.utils';
import { useEffect, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import api from 'utils/api';
import { makeRequestReducer } from 'utils/api/useApiRequest';
import { determineActiveConversation } from 'utils/conversation';
import { wrapWithCancellation } from 'utils/function';
import { triggerApiErrorEvent } from 'utils/tracking';

type InvoiceConversationState = {
  conversation?: IInvoiceConversation;
  pegaCategory?: PegaCategories;
};

const mapConversation = (invoiceConversation: IInvoiceConversationBase): IInvoiceConversation => {
  if (!invoiceConversation.invoice_data) {
    // Since invoice_data is not available, it is a valid IInvoiceConversation.
    return invoiceConversation as IInvoiceConversation;
  }

  return {
    ...invoiceConversation,

    // Since invoice_data is availabe, we need to add the url property in invoice_data to make it a valid IInvoiceConversation.
    invoice_data: invoiceConversation.invoice_data.map((invoice: IConversationInvoiceBase) => {
      return {
        ...invoice,
        url: getInvoiceUrl(invoice),
      };
    }),
  };
};

const mapResponseToState = (response: IInvoiceConversationBase): IInvoiceConversation | undefined => {
  // Making sure the conversation response is an array
  const invoiceConversations: IInvoiceConversationBase[] = Array.isArray(response) ? response : [response];
  const conversation = determineActiveConversation(invoiceConversations);

  if (!conversation) {
    return;
  }

  return mapConversation(conversation);
};

const useInvoicesConversationContainer = () => {
  const reducer = makeRequestReducer<InvoiceConversationState>();
  const [state, dispatch] = useReducer(reducer, defaultLoadingStatus);

  // We need both bcId and productId to make sure the pega nba response we retrieve is applicable for the current user.
  // Using only bcId we get an outdated nba response.
  const { bcId, productId } = useAuthSubscription();

  useEffect(() => {
    if (!bcId || !productId) {
      dispatch({ type: ContainerStatuses.READY, data: {} });

      return;
    }

    dispatch({ type: ContainerStatuses.LOADING });

    const cancellation = wrapWithCancellation();

    Promise.all([api.get(`/my/portal-cards/${bcId}/dashboard-header?section=bill`), RelayService.getNbaIds(true)])
      .then(
        cancellation.wrapper(response => {
          const [backendResponse, nbaResponse] = response;

          const conversation = mapResponseToState(backendResponse.data);

          const pegaCategory: PegaCategories | undefined = Object.values(PegaCategories).find(pega =>
            nbaResponse.includes(pega)
          );

          dispatch({
            type: ContainerStatuses.READY,
            data: {
              conversation,
              pegaCategory,
            },
          });
        })
      )
      .catch(
        cancellation.wrapper(errors => {
          dispatch({ type: ContainerStatuses.FAILED, errors: errors.errorMessages });
          triggerApiErrorEvent('invoices-conversation.container', errors.status, errors.errorMessages);
        })
      );

    return cancellation.cancel;
  }, [bcId, productId]);

  return {
    state,

    closeConversation: (cardType: InvoicesConversations, invoiceId: string | undefined) =>
      api.delete(`/my/notifications/${bcId}/${cardType.toUpperCase()}${invoiceId ? `?invoice_id=${invoiceId}` : ''}`),
  };
};

export default createContainer(useInvoicesConversationContainer);
