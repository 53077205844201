import { Heading, ThemeProvider, Themes } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { CORPORATEMIDCOLORED_HELPATHANDMIDCOLORED, Icon } from '@vodafoneziggo/sandwich/icons';
import { Button, ButtonVariant } from 'components/Button';
import { useTranslations } from 'components/Intl/useTranslations';
import Page from 'components/Page';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'styled-bootstrap-grid';
import * as S from './ErrorBoundaryFallback.styles';
import { TinyHeader } from './components/TinyHeader';

const ErrorBoundaryFallback = ({ resetErrorBoundary }: any) => {
  const navigate = useNavigate();
  const { translate } = useTranslations();

  const goToPreviousPage = () => {
    navigate(-1);
    resetErrorBoundary();
  };

  return (
    <ThemeProvider theme={Themes.VODAFONE_WS10}>
      <TinyHeader />
      <Page.Page title="Oeps" hideHeader forceVisible>
        <Container>
          <S.Container data-testid="oeps">
            <S.ImageContainer>
              <S.Image>
                {/* TODO: REPLACE AMBULANCE SVG WHEN NEW VERSION AVAILABLE */}
                <Icon icon={CORPORATEMIDCOLORED_HELPATHANDMIDCOLORED} height="100%" width="100px" />
              </S.Image>
            </S.ImageContainer>
            <Heading.H3 color="primary" as={HeadingTags.H1} paddingHorizontal="small">
              {translate('pages.oops.title')}
            </Heading.H3>
            <S.ButtonWrapper>
              <Button variant={ButtonVariant.SECONDARY} onClick={goToPreviousPage}>
                {translate('pages.oops.goback')}
              </Button>
              <Button href="/account/uitloggen" variant={ButtonVariant.DARK}>
                {translate('pages.oops.button')}
              </Button>
            </S.ButtonWrapper>
          </S.Container>
        </Container>
      </Page.Page>
    </ThemeProvider>
  );
};

export default ErrorBoundaryFallback;
