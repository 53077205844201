import { useContext } from 'react';
import { Roles } from 'models';
import { UserContext } from 'components/User';

type UserType = Roles.POSTPAID | Roles.PREPAID;

export const useUserType = (): UserType => {
  const { state: userState } = useContext(UserContext);

  if (userState.roles.includes(Roles.PREPAID)) return Roles.PREPAID;

  return Roles.POSTPAID;
};
