import React from 'react';
import { useOidc } from 'containers/auth/oidcProvider';

interface IAuthProviderContainerProps {
  /**
   * If true (which is the default) the provider will redirect to `/account` if the user is not logged in.
   * If false, nothing will happen when the user is not authenticated.
   */
  loginRequiredToViewApp?: boolean;

  children: React.ReactNode;
}

export interface IAuthProviderProps {
  authState: boolean;
  loginRequiredToViewApp?: boolean;
}

/**
 * Will contain the authentication state, to be consumed by other application.
 */
export const AuthContext = React.createContext<boolean>(false);

export const AuthProvider: React.FC<IAuthProviderProps> = ({ authState, children, loginRequiredToViewApp }) => {
  return (
    <AuthContext.Provider value={authState}>
      {(() => {
        switch (authState) {
          case false:
            // Check whether app can be viewed without being unauthenticated. If this is
            // NOT allowed, the app won't show anything until the user is authenticated
            return !loginRequiredToViewApp ? children : null;

          case true:
          default:
            return children;
        }
      })()}
    </AuthContext.Provider>
  );
};

export const AuthProviderContainer = (props: IAuthProviderContainerProps) => {
  const { loginRequiredToViewApp = true, children } = props;
  const oidc = useOidc();

  if (!oidc) return null;

  return (
    <AuthProvider authState={oidc.isAuthenticated} loginRequiredToViewApp={loginRequiredToViewApp}>
      {children}
    </AuthProvider>
  );
};
