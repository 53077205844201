import { PermissionSettings, Roles } from 'models';
import { none } from 'utils/permissions/permission-conditions';
import { ADMIN_POSTPAID_ONLY, NOBODY, POSTPAID_WITHOUT_BILL, WITHOUT_BILL } from './_default-settings';

// Base products permissions which are applied on the main route.
export const PRODUCTS: PermissionSettings = [
  [Roles.POSTPAID],
  none([Roles.BILL, Roles.DELAYED_PAYMENT, Roles.CEASED_BC]),
];

export const ACTIVATE_SIM: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const BLOCK_SERVICES: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const BLOCK_SIM: PermissionSettings = POSTPAID_WITHOUT_BILL;

export const BUNDLES: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const CHANGE_ADDONS: PermissionSettings = {
  consumer: POSTPAID_WITHOUT_BILL,
  business: ADMIN_POSTPAID_ONLY,
};
export const CHANGE_DATA_CAP: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const CHANGE_DATA_CAP_NATIONAL: PermissionSettings = {
  consumer: POSTPAID_WITHOUT_BILL,
  business: NOBODY,
};
export const CHANGE_DATA_CAP_DETAILS: PermissionSettings = {
  consumer: POSTPAID_WITHOUT_BILL,
  business: ADMIN_POSTPAID_ONLY,
};
export const CHANGE_MOBILE_NUMBER: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const CONTRACT_DETAILS: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const DEVICE_AND_SIM: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const DEVICE_INSURANCE: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const DEVICE_LOAN: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const NUMBER_PORTING: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const REPLACE_ACTIVE_ESIM: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const REPAIR: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const RETAIN_PRODUCT: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const SUBSCRIPTION: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const CEASE_SUBSCRIPTION: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const VIEW_CHANGE_SUBSCRIPTION: PermissionSettings = POSTPAID_WITHOUT_BILL;
export const CHANGE_SUBSCRIPTION: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const PAUSE_SUBSCRIPTION: PermissionSettings = {
  consumer: ADMIN_POSTPAID_ONLY,
  business: NOBODY,
};
export const TRANSFER_CONTRACT: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const VASTOPMOBIEL: PermissionSettings = ADMIN_POSTPAID_ONLY;
export const VOICEMAIL: PermissionSettings = WITHOUT_BILL;
export const ADD_PRODUCT: PermissionSettings = POSTPAID_WITHOUT_BILL;

export const COMPONENTS__BUNDLE: PermissionSettings = ADMIN_POSTPAID_ONLY; // @TODO: name this better? What is this differetn from BUNDLES?
