import { Spacing, margin, media, themeProp } from '@vodafoneziggo/sandwich';
import { Tile } from 'components/Tiles/tiles.styles';
import styled, { keyframes } from 'styled-components';
import { Col, Row } from 'styles/elements/grid.styles';
import { ContentBlock } from '../ContentBlock/contentblock.styles';

const pageInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 1.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const Nba = styled.div`
  box-shadow: ${themeProp('shadows.base')};
`;

export const Page = styled.main`
  position: relative;

  ${ContentBlock} + ${ContentBlock} {
    ${margin.top('normal')}
  }

  /**
   * Initial values for the animation. We use translate3d here as it kicks in GPU rendering in the browser 
   * which results in smoother and better performing animations
   */
  transform: translate3d(0, 1.5rem, 0);
  opacity: 0;

  /* Fix for print version of web page (avoiding blank page) in Firefox and Safari */
  @media print {
    opacity: 1;
  }

  /* Set the animation to use for the page animation */
  animation: ${pageInAnimation} 0.5s;

  /** 
   * By delaying the animation a bit, we give the page components some time to render. This will usually
   * result in a page that is already fully constructed when animating
   */
  animation-delay: 0.375s;
  animation-fill-mode: forwards;
`;

export const Column = styled('div')`
  padding: 0;
`;

export const Sidebar = styled(Col)`
  /* Add a top margin to every direct element in the sidebar */
  > * {
    ${margin.top()}

    /* The first item, or empty items, should not have a top margin in desktop mode. */

    ${media.up('lg')`    
      &:first-child,
      &:empty {
        margin-top: 0;
      }  
    `}
  }
`;

export const Wrapper = styled(Row)`
  margin: ${Spacing.M} ${Spacing.OFF};

  /* The screen becomes larger so the vertical margin also increases */
  ${media.up('lg')`
    margin: ${Spacing.XL} ${Spacing.OFF};
  `}
`;

/**
 * Same Stylings as above, except this time it doesn't extend Grid
 */
export const ContentBlocksWrapper = styled.div`
  ${margin.vertical('medium')};

  ${media.up('lg')`
    ${margin.vertical('huge')}
  `};
`;

/**
 * Extends the Wrapper so it uses Grid as base, then accomodates for the top padding of
 * the Tile component so that the margin of the wrapper is not too large
 */
export const TilesWrapper = styled(Wrapper)`
  & + & {
    ${margin.top('normal')};
  }

  row-gap: ${Spacing.S};
  ${media.up('lg')`
    row-gap: ${Spacing.M};
  `};

  ${Tile} {
    margin-top: ${Spacing.S};
    ${media.up('lg')`
      margin-top: ${Spacing.M};
    `};
    &:first-of-type {
      margin-top: ${Spacing.OFF};
    }
  }
`;
