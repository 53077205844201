import { USER_LOCALSTORAGE_KEYS } from 'components/Auth';
import { ApiResponse, get } from 'utils/api';
import { getQueryStringParamByName } from 'utils/url';
import { IBillingCustomer } from '../../models/billing-customer.model';
import { Roles } from '../../models/permissions.model';
import { IUser } from '../../models/user.model';
import { LocalStorage, SessionStorage } from '../../utils/storage';
import { IBillingCustomerSummary } from './user.model';

export const getBillingCustomerById = (bcId: string, billingCustomers: IBillingCustomer[]) => {
  return billingCustomers.find(bc => bc.billing_customer_id === bcId);
};

export const getBillingCustomerIndexById = (bcId: string, billingCustomers: IBillingCustomer[]) => {
  return billingCustomers.findIndex(bc => bc.billing_customer_id === bcId);
};

/**
 * Gets authenticated user data.
 */
export const getAuthenticatedUser = () => {
  return get('/my/account/authenticated-user');
};

/**
 * Returns a summary about a billing customer. It contains the following attributes:
 * - in-collection: True if the BC has a delayed payment, false if not
 * - ceased-products: If the user has ceased products, possible values are: NONE, SOME, ALL
 *
 * @param bcId is the id of an BC
 */
/* eslint-disable-next-line require-await */
export const getBillingCustomerSummary = async (bcId: string): Promise<IBillingCustomerSummary> => {
  return get(`/my/billing-customer/${bcId}/summary`, undefined)
    .then(response => response.data)
    .catch(() => undefined);
};

/**
 * Handle any API error while fetching a new user.
 */
export const onGetAuthenticatedUserError = (apiResponse: ApiResponse) => {
  // Try catches because redirects sometimes error during unit tests.
  // This way they won't make the test fail.
  try {
    if (apiResponse.status === 400 && apiResponse.error) {
      // Handle status-400 specific errors.
      if (apiResponse.error.request_validation_errors.includes('ZIGGO_ONLY_USER')) {
        window.location.assign('/account/overzicht?messages=ZIGGO_ONLY_USER');

        return; // Return function because we quit app.
      }

      // When the backend throws this error, there is no way to proceed in My so we
      // need to show the error page.
      if (apiResponse.error.request_validation_errors.includes('ONLINE_ACCOUNT_HAS_NO_CONTACTS')) {
        window.location.assign('/account/overzicht?messages=ONLINE_ACCOUNT_HAS_NO_CONTACTS');
      }
    } else {
      // When backend throws an error that isn't 400,
      // redirect towards the account overview.
      window.location.assign('/account/overzicht');
    }
  } catch (err) {
    // If all else fails, we also redirect to account overview.
    window.location.assign('/account/overzicht');
  }
};

/**
 * Checks if there is already a user that should preselected:
 * - Preselect the first if there is only 1
 * - Check if there is a query param bc_id in the url, if so, see if that is a valid BC for this user
 * - Check localstorage to see if there is a BC that is valid for this user
 *
 * @param user
 * @return {IBillingCustomer | null} BC that should be selected or null.
 *
 */
export const setInitialActiveBcId = (user: IUser): IBillingCustomer | null => {
  if (!user || !user.billing_customers) {
    return null;
  }

  if (user.billing_customers.length === 1) {
    return user.billing_customers[0];
  }

  const urlToRestore = SessionStorage.get('url_to_restore');
  let activeBcFromQueryString: IBillingCustomer | undefined;

  if (urlToRestore) {
    const match = urlToRestore.match(/(\?|&)bc_id=([^&]+)/);

    if (match) {
      const bcIdParam = match[2];

      activeBcFromQueryString = getBillingCustomerById(bcIdParam, user.billing_customers);
    }
  }

  // Fallback to query string
  const activeBcIdFromQueryString = getQueryStringParamByName('bc_id');

  if (activeBcIdFromQueryString) {
    activeBcFromQueryString = getBillingCustomerById(activeBcIdFromQueryString, user.billing_customers);
  }

  const lastActiveBcIndex = LocalStorage.get(USER_LOCALSTORAGE_KEYS.ACTIVE_BC_INDEX);

  return (
    activeBcFromQueryString ||
    (typeof lastActiveBcIndex !== 'undefined' && user.billing_customers[lastActiveBcIndex]) ||
    null
  );
};

/**
 * Returns roles for the current active BC
 * @param bc is the BC we want to define the roles for
 */
export const getRolesFromBillingCustomer = (bc: IBillingCustomer): Roles[] => {
  const roles: Roles[] = [];

  if (!bc) {
    return roles;
  }

  if (bc.user_role) {
    roles.push(Roles[bc.user_role.toUpperCase() as keyof typeof Roles]);
  }

  roles.push(bc.is_prepaid ? Roles.PREPAID : Roles.POSTPAID);

  if (bc.is_eligible_for_naw_bonus) {
    roles.push(Roles.ELEGIBLE_NAW_BONUS);
  }

  if (bc.is_ceased) {
    roles.push(Roles.CEASED_BC);
  }

  if (bc.has_ceased_products) {
    roles.push(Roles.CEASED_PRODUCTS);
  }

  if (bc.has_delayed_payment) {
    roles.push(Roles.DELAYED_PAYMENT);
  }

  return roles;
};
