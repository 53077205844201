import { ReactNode, ReactText, ReactElement, Children, isValidElement } from 'react';
import { AnyObject } from 'types/helpers';

const hasChildren = (element: ReactNode): element is ReactElement<{ children: ReactNode[] }> =>
  isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

export const childToString = (child?: ReactText | boolean | AnyObject | null): string => {
  if (typeof child === 'undefined' || child === null || typeof child === 'boolean') {
    return '';
  }

  if (JSON.stringify(child) === '{}') {
    return '';
  }

  return (child as string | number).toString();
};

// Inspired from only text functionality of lib react-children-utilities
export const childrenToText = (children: ReactNode): string => {
  if (!(children instanceof Array) && !isValidElement(children)) {
    return childToString(children);
  }

  return Children.toArray(children).reduce((text: string, child: ReactNode): string => {
    let newText = '';
    const devider = text ? ' ' : '';

    if (isValidElement(child) && hasChildren(child)) {
      newText = childrenToText(child.props.children);
    } else if (isValidElement(child) && !hasChildren(child)) {
      newText = '';
    } else {
      newText = childToString(child);
    }

    return text.concat(devider + newText);
  }, '') as string;
};
