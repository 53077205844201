import React from 'react';
import { GridThemeProvider as BootstrapGridThemeProvider } from 'styled-bootstrap-grid';
import { useTheme } from '@vodafoneziggo/sandwich/generic/theme/theme.hooks';
import { getGridTheme } from './get-grid-theme';

interface GridThemeProviderProps {
  children: React.ReactChild;
}

const GridThemeProvider = (props: GridThemeProviderProps) => {
  const { children } = props;
  const theme = useTheme();

  return <BootstrapGridThemeProvider gridTheme={getGridTheme(theme)}>{children}</BootstrapGridThemeProvider>;
};

export default GridThemeProvider;
