export interface ISetting {
  component_code: string;
  assigned_component_id: string;
  parent_assigned_component_id: string;
  change_settings_allowed: boolean;
  component_setting: ComponentSetting;
}

export interface IServicesSetting {
  service_code?: ServiceCodes;
  value: boolean | PremiumDestinations;
  change_settings_allowed?: boolean;
}

export enum ServiceCodes {
  /** value for the IServicesSetting should be a boolean  */
  DATA = 'Data',
  /** value for the IServicesSetting should be a boolean  */
  BAIC = 'BAIC',
  BICROAM = 'BICROAM',
  BAOC = 'BAOC',
  BOIC = 'BOIC',
  BOICXHC = 'BOICXHC',
  /** value for the IServicesSetting should be one of PremiumDestinations  */
  PREMIUMDESTINATIONS = 'PremiumDestinations',
}

export enum PremiumDestinations {
  NO_EROTIC_DESTINATIONS = 'No_erotic_destinations',
  NO_EROTIC_AND_PREMIUM_DESTINATIONS = 'No_erotic_and_premium_destinations',
  NO_RESTRICTIONS = 'No_Restrictions',
}

export interface ISortedSettings {
  data: IServicesSetting[];
  calls: IServicesSetting[];
  premiumDestinations: IServicesSetting[];
}

export interface IUserBarsWithSettings {
  userBars: IServicesSetting[];
  settings: ISetting[];
}

interface ValidValue {
  code: string;
  name: string;
}

interface ComponentSetting {
  settingcode: string;
  value: string;
  defaultValue: string;
  is_mandatory: boolean;
  valid_values: ValidValue[];
}
