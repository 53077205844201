import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useGetUpdatedCCAMScopes } from 'utils/hooks/useRequestExtraScopes';
import { IBillingCustomer } from '../../models/billing-customer.model';
import { IUser } from '../../models/user.model';
import { AuthContext } from '../Auth/auth';
import {
  useClearUserOnAuthenticationFail,
  useFetchUserDataOnAuthChange,
  useUpdateLocalStorageOnUserChange,
  useUpdateRelayOnUserChange,
} from './hooks';
import {
  BillingCustomerSummaryCeasedProducts,
  IBillingCustomerSummary,
  IUserContext,
  IUserContextState,
  UserActionTypes,
} from './user.model';
import { userReducer } from './user.reducer';
import {
  getAuthenticatedUser,
  getBillingCustomerSummary,
  getRolesFromBillingCustomer,
  onGetAuthenticatedUserError,
  setInitialActiveBcId,
} from './user.utils';

// @TODO: Sometimes BC ID is a number, sometimes a string. Make this 1 thing please..
export const initialUserState: IUserContextState = {
  user: undefined,
  userIsProspect: false,
  activeBc: undefined,
  activeBcId: null,
  roles: [],
};

export const UserContext = React.createContext<IUserContext>({
  state: { ...initialUserState },
  setBillingCustomer: () => {
    //
  },
  refetchUserData: () => {
    //
  },
  refetchUpdatedUserData: () => {},
});

type SetBillingCustomerFn = (billingCustomer: IBillingCustomer) => void;
interface IUserProps {
  children: React.ReactNode;
  /**
   * Callback that is called whenever the user has multiple BC's, and UserProvider could
   * not preselect any based on e.g. localStorage. This is for exampled used in Module My
   * to redirect the user to the 'make a choice' page if the no BC is selected yet.
   * If you do NOT supply this prop, simply nothing will happen. The user object will be filled,
   * but the activeBc property won't.
   */
  onUserHasMultipleBcsButNoneSelected?: (setBillingCustomer: SetBillingCustomerFn) => void;
}

export const UserProvider = (props: IUserProps) => {
  const [userState, dispatch] = useReducer(userReducer, initialUserState);
  const authState = useContext(AuthContext);
  const { onUserHasMultipleBcsButNoneSelected } = props;

  /**
   * Calls API to fetch user data, and stores this in state, or
   * triggers an error on fail.
   */
  const fetchUserData = useCallback(() => {
    getAuthenticatedUser()
      .then(response => {
        onAuthenticatedUserFetched(response.data);
      })
      .catch(err => {
        onGetAuthenticatedUserError(err);
      });
  }, []);

  // this is only called when updating the users contact details
  // the billing customer will remain the same, just the address changes.
  const fetchUpdatedUserData = useCallback(() => {
    getAuthenticatedUser()
      .then(response => {
        onAuthenticatedUserUpdated(response.data);
      })
      .catch(err => {
        onGetAuthenticatedUserError(err);
      });
  }, []);

  /**
   * Clears user from context state on auth error.
   */
  const clearUser = () => {
    dispatch({ type: UserActionTypes.CLEAR });
  };

  /**
   * Sets a BC as the current selected BC in context state.
   * @param billingCustomer
   */
  const setBillingCustomer = useCallback(
    async (billingCustomer: IBillingCustomer) => {
      if (!billingCustomer || billingCustomer.billing_customer_id === userState.activeBcId) return;

      // When a BC is selected, we need to fetch some additional data. This is fetched first,
      // and is then merged into the billing customer object.
      const bcSummary: IBillingCustomerSummary | undefined = await getBillingCustomerSummary(
        billingCustomer.billing_customer_id
      );

      const bcData = {
        ...billingCustomer,
        // A BC is ceased when he/she has no products, either by count from the selectedBc.active_nr_products
        // property, or from the 'ceased-products' prop from the BC summary
        is_ceased:
          billingCustomer.active_nr_products === 0 ||
          (bcSummary && bcSummary.ceased_products === BillingCustomerSummaryCeasedProducts.ALL),

        // True if the 'ceased-products' prop does not equal the value 'NONE'
        has_ceased_products: bcSummary && bcSummary.ceased_products !== BillingCustomerSummaryCeasedProducts.NONE,

        // True if the 'in-collection' prop equals true
        has_delayed_payment: bcSummary && bcSummary.in_collection,
      };

      dispatch({
        type: UserActionTypes.SET_BILLING_CUSTOMER,
        payload: {
          billingCustomer: {
            ...bcData,
          },
          roles: getRolesFromBillingCustomer(bcData),
        },
      });
    },
    [userState.activeBcId]
  );

  /**
   * When authenticated user call has been completed successfully,
   * the user object is stored in state. This also triggers different hooks to
   * select the current BC amongst other things.
   * @param user
   */
  const onAuthenticatedUserFetched = (user: IUser) => {
    if (!user) return;

    dispatch({
      type: UserActionTypes.SAVE_USER,
      payload: {
        user,
        userIsProspect: !user.billing_customers || user.billing_customers.length === 0,
      },
    });
  };

  const onAuthenticatedUserUpdated = (user: IUser) => {
    if (!user) return;

    dispatch({
      type: UserActionTypes.UPDATE_USER,
      payload: {
        user,
        userIsProspect: !user.billing_customers || user.billing_customers.length === 0,
      },
    });
  };

  /**
   * As user data is known, see if we can preselect an active BC based in query params, localstorage,
   * or when there is only one BC. If not, call the onUserHasMultipleBcsButNoneSelected prop which could
   * for example redirect the user to the 'make a choice' page in My Vodafone.
   */
  useEffect(() => {
    if (!userState.user) return;
    const selectedBc = setInitialActiveBcId(userState.user);

    if (selectedBc) {
      setBillingCustomer(selectedBc);

      return;
    }

    if (!selectedBc && typeof onUserHasMultipleBcsButNoneSelected === 'function') {
      onUserHasMultipleBcsButNoneSelected(setBillingCustomer);
    }
  }, [userState.user]);

  /**
   * The different hooks subscribe to state changes.
   */
  useClearUserOnAuthenticationFail(authState, clearUser);
  useUpdateRelayOnUserChange(userState);
  useUpdateLocalStorageOnUserChange(userState);
  useFetchUserDataOnAuthChange(authState, fetchUserData);
  useGetUpdatedCCAMScopes({
    options: {
      scopes: [
        'openid',
        'email',
        'hawaii_sso',
        'ccam',
        'vz_sav',
        'vz_sim_read',
        'vz_sim_write',
        'vz_pts',
        'vz_ppis',
        'two_factor_challenge',
        'vz_user',
        'vz_sim_order',
        'vz_esim_activation_code_read',
      ],
    },
    skip: false,
    contactId: userState.activeBc?.primary_contact.contact_id ?? null,
  });

  const value = useMemo(() => {
    return {
      setBillingCustomer,
      refetchUserData: fetchUserData,
      refetchUpdatedUserData: fetchUpdatedUserData,
      state: userState,
    };
  }, [fetchUserData, fetchUpdatedUserData, setBillingCustomer, userState]);

  return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};
