import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IBreadcrumb } from './Breadcrumb';
import * as S from './breadcrumbs.styles';
import { BreadcrumbsContext, BreadcrumbsProviderContext } from './BreadcrumbsProvider';

interface BreadcrumbsProps {
  className?: string;
  withoutCurrentRoute?: boolean;
}

interface BreadcrumbsComponentProps extends BreadcrumbsProps, BreadcrumbsProviderContext {}

const BreadcrumbsComponent = (props: BreadcrumbsComponentProps) => {
  const { className, withoutCurrentRoute } = props;
  const location = useLocation();

  let { items } = props;

  if (!items.length) {
    return null;
  }

  // Make sure we work with a fresh copy so we don't mutate context state when we start sorting and filtering
  items = items.slice();

  // Sort the items on the length of their url properties. This ensures the items are always
  // in order, no matter how of where they are mounted
  items = items.sort((a, b) => {
    return a.url.length - b.url.length;
  });

  // If true, the item that matches the url of the current route will not be rendered
  if (withoutCurrentRoute) {
    items = items.filter(item => item.url !== location.pathname);
  }

  return (
    <S.Breadcrumbs className={classNames('breadcrumbs', className)} data-testid="breadcrumbs" aria-label="Breadcrumb">
      <S.BreadcrumbsList data-testid="breadcrumbs-list" itemScope itemType="http://schema.org/BreadcrumbList">
        {items.map((item: IBreadcrumb) => (
          <S.BreadcrumbsItem
            key={item.url}
            data-testid="breadcrumbs-item"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem">
            <S.BreadcrumbsLink to={item.url} className="breadcrumbs__link">
              {item.title}
            </S.BreadcrumbsLink>
          </S.BreadcrumbsItem>
        ))}
      </S.BreadcrumbsList>
    </S.Breadcrumbs>
  );
};

/**
 * Renders all <Breadcrumb> components added throughout the app
 * @param props are the props passed to the component
 */
const Breadcrumbs: React.SFC<BreadcrumbsProps> = props => (
  <BreadcrumbsContext.Consumer>
    {context => context && <BreadcrumbsComponent {...context} {...props} />}
  </BreadcrumbsContext.Consumer>
);

export default Breadcrumbs;
