import {
  VFWS10MID_ADMINMID,
  VFWS10MID_HELPCIRCLEMID,
  VFWS10HIFILIGHT_HELPCIRCLEHI,
  VFWS10HIFILIGHT_ADMINHI,
} from '@vodafoneziggo/sandwich/icons';
import { useTranslations } from 'components/Intl/useTranslations';
import Page from 'components/Page';
import PortalCard from 'components/PortalCard';
import React from 'react';
import * as S from './prospect-customer-overview.styles';
import { ErrorBlock } from 'components/InfoBlock';

const ProspectCustomerOverview = () => {
  const { translate } = useTranslations();

  return (
    <div data-testid="prospect-overview">
      <Page.Page hideHeader>
        <S.Container>
          <S.ErrorContainer>
            <ErrorBlock>{translate('dashboard-prospect.header')}</ErrorBlock>
          </S.ErrorContainer>
          <S.ProspectPortalCards>
            <PortalCard
              icon={VFWS10MID_ADMINMID}
              title={translate('dashboard-prospect.add-product-card.title')}
              bottomContent={translate('dashboard-prospect.add-product-card.content')}
              primaryButton={{
                content: translate('dashboard-prospect.add-product-card.primary-button-content'),
                href: '/shop/mobiel',
              }}
              secondaryButton={{
                content: translate('dashboard-prospect.add-product-card.secondary-button-content'),
                href: '/account/overzicht',
              }}>
              <S.Icon icon={VFWS10HIFILIGHT_ADMINHI} />
            </PortalCard>
            <PortalCard
              icon={VFWS10MID_HELPCIRCLEMID}
              title={translate('dashboard-prospect.customer-service-card.title')}
              bottomContent={translate('dashboard-prospect.customer-service-card.content')}
              primaryButton={{
                content: translate('dashboard-prospect.customer-service-card.primary-button-content'),
                href: 'tel:08000094',
              }}
              secondaryButton={{
                content: translate('dashboard-prospect.customer-service-card.secondary-button-content'),
                href: '/support/contact',
              }}>
              <S.Icon icon={VFWS10HIFILIGHT_HELPCIRCLEHI} />
            </PortalCard>
          </S.ProspectPortalCards>
        </S.Container>
      </Page.Page>
    </div>
  );
};

export default ProspectCustomerOverview;
