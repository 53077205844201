import { loadFonts, media, padding, themeProp } from '@vodafoneziggo/sandwich';
import { useTheme } from '@vodafoneziggo/sandwich/generic/theme/theme.hooks';
import React from 'react';
import { BaseCSS, Container } from 'styled-bootstrap-grid';
import { createGlobalStyle } from 'styled-components';

/**
 * Build the global font-face declaration for the Vodafone fonts.
 * Because we don't work with a base href, we need to assume all assets are loaded from the base url when they
 * are requested by the browser. That's why we need to start the path with `/my`.
 */

const Fonts = loadFonts(
  'https://origin.vodafone.nl/etc/designs/nlgeneric/dist/fonts/vodafonerg-webfont.woff',
  'https://origin.vodafone.nl/etc/designs/nlgeneric/dist/fonts/vodafonelt-webfont.woff',
  'https://origin.vodafone.nl/etc/designs/nlgeneric/dist/fonts/vodafonergbd-webfont.woff'
);

/**
 * These global styles are absolutely necessary to live on this level.
 * Prefer not to use global styles for specific elements. Create your own custom component for that.
 */
const Global = createGlobalStyle`
/* remove overflow on maintenance in progress page for mobile menu */
  body {
    overflow-x: hidden;
  }
  /** 
   * Vertical aligning is used on all icons we have. 
   * Most of the time those need to be aligned in the middle of some text next to it.
   */
  svg {
    vertical-align: middle;
  }

  *:focus-visible {
    outline: 2px solid ${themeProp('colors.blueD10')};
    outline-offset: 2px;
  }

  footer *:focus-visible {
    outline-color: ${themeProp('colors.yellow')};
  }

  /** 
   * Reset all the button styles before we style any button on top of that. 
   * We do not use the browser's default styling anywhere.
   */
  button {
    margin: 0;
    padding: 0;
    border: 0;
    color: inherit;
    font: inherit;
    text-transform: none;
    appearance: none;
    background: none;
    outline: none;
  }

  .svg-icon__symbol {
    display: none;
  }

  .svg-icon--default-size{
    height: 50px;
    width: 50px;
  }
`;

/**
 * The `styled-bootstrap-grid` package uses the same container padding for mobile and everything above that. But, for mobile,
 * we like the padding to be a bit less, so containers use more of the available screen width and are aligned with the header.
 * This global styling makes the container padding smaller on our mobile view.
 */
const Grid = createGlobalStyle`
  ${Container} {
    ${media.down('xs')`
      ${padding.horizontal('medium', true)}
    `}
  }
`;

/**
 * Whenever a `page--app` class is present on the body, a couple of elements need to be hidden on the page.
 * This allows for the page to be loaded into the My Vodafone App, without elements like the header and
 * footer visible.
 */
const PageView = createGlobalStyle`
  body.page--app {
    .vf-header,
    .vf-header-understructure,
    .header-navigation,
    .footer,
    .popup-cookie-law,
    .cookie-law-settings,
    .usabilla_live_button_container,
    .app-smart-banner {
      display: none;
    }
  }
`;

/**
 * Global styles receive current theme from the theme context, to force global
 * styling elements to rerender when the theme changes.
 */
export default () => {
  const theme = useTheme();

  return (
    <React.Fragment key={theme.name}>
      <BaseCSS />
      <Global />
      <Fonts />
      <Grid />
      <PageView />
    </React.Fragment>
  );
};
