import { UserContext } from 'components/User';
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { clearUrlToRestore } from 'utils/hooks/useUrlRestoration';
import { useUserType } from 'utils/hooks/user';
import { SessionStorage } from 'utils/storage';

interface Props {
  children: React.ReactNode;
}

const REDIRECT_SESSIONSTORAGE_KEY = 'postLoginRedirect';

/**
 * Component checks a key in sessionStorage to determine if it needs
 * to redirect the user back to a specific page. This is for example used
 * when a user comes from a Support page and needs to login to see certain
 * details of their account. After login, the user should go to 'maak een keuze'
 * page, and be redirect back to the page in SessionStorage after it made that choice.
 *
 * More info about how this redirect works can be found here:
 * https://vodafoneziggo.atlassian.net/wiki/spaces/TS/pages/143982593/Deeplinking
 */
export const RedirectToDeepLink = ({ children }: Props) => {
  const { state: userState } = useContext(UserContext);
  const location = useLocation();
  const userType = useUserType();

  // Don't do anything on 'maak een keuze page'
  if (location.pathname.includes('/maak-een-keuze/') || !userState.user || !userState.roles.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // if user is on a page that isn't 'maak een keuze', check if redirect
  // localstorage key is present, and redirect to that url.
  // If there is no redirect url, the component does nothing.

  let redirectUrl = SessionStorage.get(REDIRECT_SESSIONSTORAGE_KEY);

  const url_to_restore = SessionStorage.get('url_to_restore');

  SessionStorage.remove(REDIRECT_SESSIONSTORAGE_KEY);

  redirectUrl = redirectUrl ? redirectUrl[userType] : null;

  if (url_to_restore) {
    clearUrlToRestore();

    return <Navigate to={url_to_restore} replace />;
  }

  // If the redirect should be the My dashboard, we ignore this, since that
  // way we have a much nicer animation to the next page, and the app will already
  // handle the redirect to the dashboard by itself.
  if (redirectUrl && redirectUrl !== '/my/') {
    // I feel ashamed :( But by adding this location.assign we make SURE
    // that any writes to eg localstorage and stuff have been done before this
    // redirect occurs. This is needed since it might be the case the we are
    // redirecting to a page inside My Vodafone, for which we need these localstorage
    // keys. We could also solve this by using History.push() for the page inside My Vodafone,
    // and the location.assign only for pages outside My Vodafone. However, as long as parts
    // of My Vodafone are NOT build in the new app, we should also check features etc, which
    // would become way too complicated. So this is the genious thing I came up with 🤓
    setTimeout(() => window.location.assign(redirectUrl), 0);

    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default RedirectToDeepLink;
