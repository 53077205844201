/**
 * Default scopes aka legacy scopes for the hawaii backend
 */
export const DEFAULT_SCOPES = [
  'openid',
  'email',
  'hawaii_sso',
  'idm',
  'new_device_alert',
  'ziggo',
  'hawaii_backend',
  'two_factor',
];

export const DEFAULT_TOKEN_VALIDATION_OPTS = { scopes: DEFAULT_SCOPES };
