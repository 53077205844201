import RelayService from '@vodafonepub/relay-service';
import { EventTypes } from 'components/Nba/package';

/**
 * In this file we track any elements with the class defined below (BAT_LINK_CLASSNAME), which
 * are / should be links to the BAT tool.
 *
 * If any element appears in the DOM with this class, we send a event to Relay to track this.
 * Besides that this file also binds a click event on that specific element, that when it
 * is clicked, sends that click event to Relay as well.
 *
 * The reason we do this with a mutation observer, is because this way the tagging of BAT
 * links is completely decoupled from the rest of the app, and we can prevent implementing
 * this over and over again in different ways.
 */

export const BAT_LINK_CLASSNAME = 'js-tag-bat-links';

/**
 * Sends event to Relay.
 */
const triggerBatNbaEvent = (type: EventTypes) => {
  RelayService.trigger(`nba.${type}`, {
    data: {
      id: 'MCC_DFT_DFT_1037',
      name: 'Bundeladvies',
      event: type,
    },
  });
};

/**
 * Triggers impression event, and binds click event on anchor
 * which on click also sends the click event to Relay.
 */
const registerBatLinkVisibility = (node: Element) => {
  const link = node.tagName === 'A' || node.tagName === 'BUTTON' ? node : node.querySelector('a, button');

  if (!link) return;

  triggerBatNbaEvent(EventTypes.IMPRESSION);

  link.addEventListener('click', () => {
    triggerBatNbaEvent(EventTypes.CLICK);
  });

  // Remove class to prevent it from binding multiple click events
  // and sending events to relay multiple times.
  node.classList.remove(BAT_LINK_CLASSNAME);
};

export const initBundleAdviceTagging = () => {
  const app = document.getElementById('app');

  if (!app) return;

  const mutationCallback = (mutationsList: MutationRecord[]) => {
    // The mutation observer callback is wrapped in a try catch, to prevent
    // this to break the rest of the app :)
    try {
      mutationsList.forEach((mutation: MutationRecord) => {
        mutation.addedNodes.forEach((node: HTMLElement) => {
          const targetIsBatLink =
            node.className &&
            typeof node.className.indexOf === 'function' &&
            node.className.indexOf(BAT_LINK_CLASSNAME) > -1;

          if (targetIsBatLink) registerBatLinkVisibility(node);

          const batLinkAsChild = node.querySelector(`.${BAT_LINK_CLASSNAME}`);

          if (batLinkAsChild) registerBatLinkVisibility(batLinkAsChild);
        });
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const observer = new MutationObserver(mutationCallback);

  observer.observe(app, { childList: true, subtree: true });
};
