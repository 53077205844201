import { Colors, LoadingPlaceholder } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { Icon } from '@vodafoneziggo/sandwich/icons';
import React, { ReactNode } from 'react';
import * as S from './tiles.styles';

interface TileHeader {
  icon?: Icon;
  iconWidth?: string;
  iconHeight?: string;
  children?: React.ReactNode;
  color?: keyof Colors;
  loading?: boolean;
  as?: HeadingTags;
}

interface TileMessage {
  icon: Icon;
  testId?: string;
  children: React.ReactNode;
}

interface ITileProps {
  children: ReactNode;
  hasBorder?: boolean;
  hasShadow?: boolean;
  className?: string;
  testId?: string;
  isSelected?: boolean;
}

export const Tile = (props: ITileProps) => {
  const { hasBorder, hasShadow = true, children, className, testId, isSelected } = props;

  return (
    <S.Tile
      className={className}
      hasBorder={hasBorder}
      hasShadow={hasShadow}
      data-testid={testId}
      isSelected={isSelected}>
      {children}
    </S.Tile>
  );
};

export const TileContent = (props: { children: ReactNode }) => {
  return <S.TileContent>{props.children}</S.TileContent>;
};

export const TileHeader = ({
  icon,
  iconWidth = '36',
  iconHeight = '36',
  children,
  color,
  loading,
  as = HeadingTags.H2,
}: TileHeader) => {
  return (
    <S.TileHeader>
      {icon && (
        <S.TileHeaderIcon>
          <Icon icon={icon} width={iconWidth} height={iconHeight} />
        </S.TileHeaderIcon>
      )}
      {loading ? (
        <LoadingPlaceholder size="medium" width={40} />
      ) : (
        <S.TileHeaderTitle color={color || 'grayD40'} as={as} marginVertical="none">
          {children}
        </S.TileHeaderTitle>
      )}
    </S.TileHeader>
  );
};

export const TileFooter = (props: { children: ReactNode }) => {
  return <S.TileFooter>{props.children}</S.TileFooter>;
};

export const TileMessage = ({ icon, testId, children }: TileMessage) => {
  return (
    <S.TileMessage data-testid={testId}>
      <S.TileMessageIcon>
        <Icon icon={icon} />
      </S.TileMessageIcon>
      {children}
    </S.TileMessage>
  );
};
