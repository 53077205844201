import { Breakpoint, Spacing } from '@vodafoneziggo/sandwich';
import { Col as GridCol, Container as GridContainer, Row as GridRow } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { up } from 'styles/utils/mediaqueries';

export const gutterForDesktop = parseFloat(Spacing.M) / 2 + Spacing.M.replace(/[0-9.]/g, '');

export const Col = styled(GridCol)`
  padding: ${Spacing.OFF};
  ${up(Breakpoint.L)`
    padding: 0 ${gutterForDesktop};
  `};
`;
// Default exports to make styles centralised
export const Container = styled(GridContainer)``;

export const Row = styled(GridRow)``;
