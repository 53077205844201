import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiResponse } from './api';
import { IErrorMessages } from './error';

export interface MappedApiResponse extends ApiResponse {
  errorMessages?: IErrorMessages;
}

/**
 * Small wrapper around async/await that returns an object with the response and optional error
 *
 * Usage:
 *
 * ````
 * async () => {
 *   const {err, response} = await toAsyncResponse(someApiRequest());
 * }
 * ````
 *
 * @param promise A Promise, which can be any type of promise you want
 */
export const toAsyncResponse = (
  promise: Promise<unknown>
): Promise<{ response?: MappedApiResponse; err: null | MappedApiResponse }> => {
  return promise
    .then((response: MappedApiResponse) => {
      return { response, err: null };
    })
    .catch(err => {
      return {
        err,
      };
    });
};

/**
 * Executes the request and handle its promise.
 * @param asset Axios function
 * @param options contains the request url
 */
export const handleRequest = (instance: AxiosInstance, options: AxiosRequestConfig) => {
  return new Promise<ApiResponse>((resolve, reject) => {
    instance(options)
      .then(response => {
        resolve({
          data: response.data,
          status: response.status,
        });
      })
      .catch(error => {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          error,
        });
      });
  });
};
