import { Header, Heading, LoadingPlaceholder } from '@vodafoneziggo/sandwich';
import { HeadingTags } from '@vodafoneziggo/sandwich/components/heading';
import { Footer } from 'components/Footer';
import { ErrorBlock, SuccessBlock } from 'components/InfoBlock';
import { useTranslations } from 'components/Intl/useTranslations';
import { StyledTileContent } from 'components/MaintenanceMessages/maintenance-messages.styles';
import Page from 'components/Page';
import { Page as StyledPage } from 'components/Page/page.styles';
import { Tile } from 'components/Tiles';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container } from 'styled-bootstrap-grid';
import { useSearchParams } from 'utils/hooks/useSearchParams';
import * as S from './manage-privacy-settings.styles';

const intlPrefix = 'pages.manage-privacy-settings';

enum SettingsType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  TELEFOON = 'TELEFOON',
  POST = 'POST',
  ONLINE = 'ONLINE',
  USAGE_DATA = 'GEBRUIKSGEGEVENS',
  CLIENT_PROFILE = 'KLANTPROFIELEN',
  LOCATION_DATA = 'LOCATIEGEGEVENS',
  CUSTOMER_RESEARCH = 'KLANTONDERZOEK',
  NONE = 'NONE',
}

type QueryStringParams = {
  type: SettingsType;
  token: string;
};

enum SubscriptionAction {
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
}

const getSuccessMessage = (settingsType: SettingsType, subscriptionAction: SubscriptionAction, translate: any) => {
  const messages = {
    [SubscriptionAction.Subscribe]: translate(`${intlPrefix}.subscribe.success-message.${settingsType}`),
    [SubscriptionAction.Unsubscribe]: translate(`${intlPrefix}.unsubscribe.success-message.${settingsType}`),
  };

  return messages[subscriptionAction];
};

const ManagePrivacySettings = () => {
  const { translate, translateHtml, intl } = useTranslations();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getCurrentSearchParams } = useSearchParams();
  const searchParams = getCurrentSearchParams();

  const settingsType = searchParams.get('type') as SettingsType;
  const token = searchParams.get('token')?.replace(/ /g, '+');
  const payload: QueryStringParams = { type: settingsType ?? SettingsType.NONE, token: token ?? '' };

  const location = useLocation();
  const subscriptionAction = location.pathname.split('/').filter(Boolean).pop();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (
        settingsType &&
        token &&
        Object.values(SubscriptionAction).includes(subscriptionAction as SubscriptionAction)
      ) {
        try {
          const response = await fetch(`/rest/promos/${subscriptionAction}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error(response.statusText);
          }

          const data = await response.json();

          if (data.status !== 200) {
            throw new Error(data.error);
          }

          setIsSuccess(true);
        } catch (err) {
          console.error(err);
          setIsSuccess(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsSuccess(false);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [subscriptionAction, settingsType, token, payload]);

  const successMessage = getSuccessMessage(settingsType, subscriptionAction as SubscriptionAction, translate);

  const title = intl.formatMessage({ id: `${intlPrefix}.title.${subscriptionAction}` });

  return (
    <StyledPage title={title} meta-title={title} data-testid="manage-privacy-settings">
      <Header
        changeBillingCustomer={() => null}
        billingCustomers={[]}
        activeBillingCustomer={null}
        shouldShowMenuItem={() => Promise.resolve(false)}
        shouldRenderSubmenu={false}
      />
      <S.ManageSubscriptionContainer>
        <Container className="container">
          <Page.Wrapper>
            <Page.FullWidthContent>
              <Tile>
                <StyledTileContent>
                  <Heading.H1 as={HeadingTags.H2} data-testid="manage-privacy-settings-title">
                    {translate(`${intlPrefix}.title.${subscriptionAction}`)}
                  </Heading.H1>
                  {isLoading && <LoadingPlaceholder size="large" width={100} />}
                  {!isLoading && isSuccess && <SuccessBlock>{successMessage}</SuccessBlock>}
                  {!isLoading && !isSuccess && (
                    <ErrorBlock testId="manage-privacy-settings-error">
                      <span>{translateHtml(intlPrefix, 'failure-message')}</span>
                    </ErrorBlock>
                  )}
                </StyledTileContent>
              </Tile>
            </Page.FullWidthContent>
          </Page.Wrapper>
        </Container>
      </S.ManageSubscriptionContainer>
      <Footer />
    </StyledPage>
  );
};

export default ManagePrivacySettings;
