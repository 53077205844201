import React from 'react';

/**
 * Context lets us pass a value deep into the component tree
 * without explicitly threading it through every component.
 * more info: https://reactjs.org/docs/context.html
 */
const ThemeContext = React.createContext(`theme--red`);

export default ThemeContext;

/**
 * Current choices of themes/classes
 */
export enum Themes {
  RED = 'theme--red',
  RED_DARK = 'theme--red-dark',
  TURQUOISE = 'theme--blue',
  PURPLE = 'theme--purple',
  GRAY = 'theme--gray',
  BLUE = 'theme--blue',
  YELLOW = 'theme--yellow',
}
