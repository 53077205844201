import { IErrorMessages } from 'utils';

/** All the possible container statusses */
export enum ContainerStatuses {
  LOADING,
  FAILED,
  READY,
}

/** This is how the loading state should look */
export interface StatusLoading {
  status: ContainerStatuses.LOADING;
  data: undefined;
  errors: undefined;
}

/** This is how the failed state should look */
export interface StatusFailed {
  status: ContainerStatuses.FAILED;
  data: undefined;
  errors: IErrorMessages;
}

/** This is how the ready state should look  */
export interface StatusReady<T> {
  status: ContainerStatuses.READY;
  data: T;
}

/** Helper const to easily set a default LoadingState */
export const defaultLoadingStatus: StatusLoading = {
  status: ContainerStatuses.LOADING,
  data: undefined,
  errors: undefined,
};

/** Helper const to easily set a default ErrorState */
export const defaultFailedStatus: Omit<StatusFailed, 'errors'> = {
  status: ContainerStatuses.FAILED,
  data: undefined,
};

/** The type / interface you should use for your container state. */
export type ContainerStatus<T> = StatusLoading | StatusFailed | StatusReady<T>;
