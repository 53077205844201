import { initialUserState } from './user';
import { IUserContextState, UserActions, UserActionTypes } from './user.model';

export const userReducer = (state: IUserContextState, action: UserActions): IUserContextState => {
  switch (action.type) {
    case UserActionTypes.CLEAR:
      return { ...initialUserState };

    case UserActionTypes.SAVE_USER:
      return {
        ...state,
        user: action.payload.user,
        userIsProspect: action.payload.userIsProspect,
        activeBc: undefined,
        activeBcId: null,
      };

    case UserActionTypes.UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
        userIsProspect: action.payload.userIsProspect,
      };

    case UserActionTypes.SET_BILLING_CUSTOMER:
      return {
        ...state,
        activeBc: action.payload.billingCustomer,
        activeBcId: action.payload.billingCustomer.billing_customer_id,
        roles: action.payload.roles,
      };

    default:
      return state;
  }
};
