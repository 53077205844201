import MaintenanceMessages from 'components/MaintenanceMessages';
import { Page as StyledPage } from 'components/Page/page.styles';
import React from 'react';

const MaintenanceInProgress = () => {
  return (
    <StyledPage title="Werkzaamheden" data-testid="profile-page-wrapper">
      <MaintenanceMessages />
    </StyledPage>
  );
};

export default MaintenanceInProgress;
