import { IBillingCustomer, IUser, Roles } from 'models';

export interface IUserContextState {
  user?: IUser;
  userIsProspect: boolean;
  activeBc?: IBillingCustomer;
  activeBcId: string | null;
  roles: Roles[];
}

export interface IUserContext {
  state: IUserContextState;
  setBillingCustomer: (bc: IBillingCustomer) => void;
  refetchUserData: () => void;
  refetchUpdatedUserData: () => void;
}

export enum UserActionTypes {
  CLEAR,
  SAVE_USER,
  SET_BILLING_CUSTOMER,
  UPDATE_USER,
}

export type UserActions =
  | { type: UserActionTypes.CLEAR }
  | { type: UserActionTypes.SAVE_USER; payload: { user: IUser; userIsProspect: boolean } }
  | { type: UserActionTypes.SET_BILLING_CUSTOMER; payload: { billingCustomer: IBillingCustomer; roles: Roles[] } }
  | { type: UserActionTypes.UPDATE_USER; payload: { user: IUser; userIsProspect: boolean } };

export enum BillingCustomerSummaryCeasedProducts {
  NONE = 'NONE',
  SOME = 'SOME',
  ALL = 'ALL',
}

export interface IBillingCustomerSummary {
  in_collection: boolean;
  ceased_products: BillingCustomerSummaryCeasedProducts;
}
