import {
  ContainerStatus,
  ContainerStatuses,
  defaultFailedStatus,
  defaultLoadingStatus,
  StatusLoading,
} from 'models/container-statuses.model';
import { useEffect, useReducer, useState } from 'react';
import api, { IErrorMessages } from 'utils/api';
import { wrapWithCancellation } from 'utils/function';

type Action<T> =
  | { type: ContainerStatuses.LOADING }
  | { type: ContainerStatuses.READY; data: T }
  | { type: ContainerStatuses.FAILED; errors: IErrorMessages };

const initialState: StatusLoading = {
  ...defaultLoadingStatus,
};

interface RequestResponse<T> {
  /**
   * Contains the current status of the request, and data or errors if the request
   * succeeded or failed.
   */
  state: ContainerStatus<T>;

  /**
   * Sets the request url, which immediately triggers a new request if the url is different
   * from the url that was last requested.
   */
  setRequestUrl: React.Dispatch<React.SetStateAction<string>>;

  /**
   * Can be called to refetch the current request
   */
  refetchRequest: () => void;

  /**
   * Can be called to reset the hook to its initial loading state
   */
  resetRequest: () => void;
}

export const makeRequestReducer = <T>() => (state: ContainerStatus<T>, action: Action<T>): ContainerStatus<T> => {
  switch (action.type) {
    case ContainerStatuses.LOADING:
      return { ...defaultLoadingStatus };

    case ContainerStatuses.FAILED:
      return {
        ...defaultFailedStatus,
        errors: action.errors,
      };

    case ContainerStatuses.READY:
      return {
        ...state,
        status: action.type,
        errors: undefined,
        data: action.data,
      };
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
function useApiRequest<T>(url?: string, mapperFn?: Function): RequestResponse<T> {
  const [requestUrl, setRequestUrl] = useState(url);

  /**
   * This extra initialisation is needed to pass the generic T to the reducer, which is not possible
   * when the reducer is immediately passed to the useReducer hook.
   */
  const requestReducer = makeRequestReducer<T>();
  const [state, dispatch] = useReducer(requestReducer, initialState);

  /**
   * Method that can be used to refetch the last known url, without the need to change it.
   */
  const [refetchTick, refetchRequest] = useReducer(x => x + 1, 0);

  /**
   * Method that can be used to reset the request to its initial loading state.
   */
  const resetRequest = () => dispatch({ type: ContainerStatuses.LOADING });

  /**
   * Performs a request whenever the requestUrl changes, or the same request is forced to
   * refetch by the refetchTick state value.
   */
  useEffect(() => {
    const cancellation = wrapWithCancellation();

    /**
     * If no url is provided, then the request will not be executed. It's possible that some components that
     * use this hook need to wait for a side-effect, like useAuthSubscription, before they can construct
     * the full url. The url will then be set at a later moment via `setRequestUrl`, and initially it
     * will be undefined.
     */
    if (!requestUrl) return;

    dispatch({ type: ContainerStatuses.LOADING });

    api
      .get(requestUrl)
      .then(
        cancellation.wrapper(response => {
          const data = mapperFn ? mapperFn(response.data, state) : response.data;

          dispatch({ type: ContainerStatuses.READY, data });
        })
      )
      .catch(
        cancellation.wrapper(err => {
          if (!process.env.IS_PROD) {
            // eslint-disable-next-line no-console
            console.error(err);
          }

          const errors = err.errorMessages ? err.errorMessages : err;

          dispatch({ type: ContainerStatuses.FAILED, errors });
        })
      );

    return cancellation.cancel;
  }, [requestUrl, refetchTick]);

  /**
   * The `state` prop is intentionally not destructured because if it would, TS cannot infer that when status is READY,
   * data will always be set. This would require null checks for data to be added everywhere it was used.
   */
  return { state, setRequestUrl, refetchRequest, resetRequest };
}

export default useApiRequest;
