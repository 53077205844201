export enum FEATURES {
  SNOW = 'snow',
  ORDER_RED_TOGETHER_EXTRA = 'order_red_together_extra',
  CHANGE_SUBSCRIPTION = 'change_subscription',
  INFLATION_NOTIFICATION = 'inflation_notification',
  PREPAID_PROMO_BANNER = 'prepaid_promo_banner',
  CONTENT_SERVICES = 'content_services',
  NEW_BUNDLE_ADVICE = 'new_bundle_advice',
  UNLIMITED_BOOSTER_MESSAGE = 'unlimited_booster_message',
}

export enum FEATURE_STATES {
  DEFAULT = 'off',
  ON = 'on',
  OFF = 'off',
  REDIRECT = 'redirect',
}
