import { Roles } from 'models';

/**
 * Matches if ANY of the given roles matches ANY of the current user's roles.
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */
export const some = (allowedRoles: Roles[]) => {
  return (currentUserRoles: Roles[]) => {
    return allowedRoles.some(role => currentUserRoles.includes(role));
  };
};

/**
 * Matches if NONE of the given roles exist in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */
export const none = (allowedRoles: Roles[]) => {
  return (currentUserRoles: Roles[]) => {
    return allowedRoles.every(role => !currentUserRoles.includes(role));
  };
};

/**
 * Matches if ALL of the given roles are present in the current user's roles
 * Returns a matcher function that can be injected in the roles arrays.
 * @param allowedRoles
 */
export const all = (allowedRoles: Roles[]) => {
  return (currentUserRoles: Roles[]) => {
    return allowedRoles.every(role => currentUserRoles.includes(role));
  };
};
