// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';
import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import { getStoredCcamToken } from 'containers/auth/auth.utils';
import { ApiError } from 'utils/api/api.types';

/**
 * This Link will add the auth token and context contact id to the request headers
 */

/* eslint-disable-next-line require-await */
export const authLink = setContext(async (_, { headers, scopes = ['openid'], commonId, ...context }) => {
  const { skipAuthToken } = context;

  // When running in local development mode, we return the headers as is to bypass scope checks.
  // We do this because most of the required ccam scopes are not configured for our local test accounts.

  if (process.env.NODE_ENV === 'development') {
    return {
      headers,
    };
  }

  // Check if no authentication token is needed based on the provided scopes
  if (skipAuthToken) {
    return {
      headers,
    };
  }

  try {
    // ccam tokens are also scoped to the contactId of a user (hopefully the current one)
    const token = scopes.includes('ccam')
      ? getStoredCcamToken(scopes, {
          domain: 'vz_sav',
          commonId,
        })
      : OidcService.getStoredToken({ scopes });

    if (!token) {
      throw Error(ApiError.INVALID_AUTH_TOKEN);
    }

    // Get the authorization header
    const authorization = OidcService.getAuthHeader(token);

    if (!authorization) {
      throw Error(ApiError.INVALID_AUTH_TOKEN);
    }

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  } catch (e) {
    // Throw error to handle in UI
    throw new Error(ApiError.INVALID_AUTH_TOKEN);
  }
});
