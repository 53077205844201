import { Color, Gradient, Spacing, media, themeProp } from '@vodafoneziggo/sandwich';
import { Heading } from '@vodafoneziggo/sandwich/components/heading';
import BreadcrumbsBase from 'components/Breadcrumbs';
import styled from 'styled-components';
import { Container as StyledContainer } from 'styles/elements/grid.styles';

const menuSize = '3.125rem';

const gridBreakpoint = 'lg';

export const HeaderSpace = styled.div<{ backgroundColor?: Color }>`
  position: relative;
  padding-top: calc(${menuSize} + ${Spacing.L});
  margin-top: -${menuSize};
  padding-bottom: ${Spacing.L};
  margin-bottom: 0;

  @supports (padding: env(safe-area-inset-right)) {
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
  }

  background: ${props => (props.backgroundColor ? props.backgroundColor : Gradient.RED_DARK)};

  *:focus-visible {
    outline-color: ${themeProp('colors.yellow')};
  }
`;

export const Container = styled(StyledContainer)`
  ${props => media.up(gridBreakpoint)`
    /* This is to ensure the page doesn't jump when breadcrumbs are added */
    min-height: 5.625rem;
    padding: 0 1.75rem;

    display: grid;
    justify-items: start;

    /* 25% equals col-3 in sidebar */
    grid-template-columns: 1fr 17.3125rem;

    /* Used to hide breadcrumb and conversation when empty, which fixes selector alignments issues. */
    grid-template-rows: min-content 1fr;

    grid-column-gap: ${props.theme.grid.gutter};
    grid-template-areas:
      "breadcrumb selector"
      "title selector"
      "conversation .";

     /* IE11 grid syntax */
     display: -ms-grid;
     -ms-grid-columns: 1fr 25%;
     -ms-grid-rows: min-content 1fr;
  `}
`;

export const Breadcrumbs = styled(BreadcrumbsBase)`
  ${media.up(gridBreakpoint)`
    grid-area: breadcrumb;

    // IE11 grid syntax
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  `}
`;

export const Title = styled(Heading.H2)`
  color: ${themeProp('colors.white')} !important;
  margin: 0;
  font-weight: ${themeProp('fonts.weights.regular')} !important;

  ${media.up('md')`
    padding-left: 0;
  `}

  ${media.up(gridBreakpoint)`
    grid-area: title;
    align-self: center;

     // IE11 grid syntax
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  `}
`;

export const Conversation = styled.div`
  padding-top: ${Spacing.M};
  max-height: 25rem;

  /**
 * If the Conversation component renders NULL, hide the div so that
 * it has no margins and alignment keeps intact.
 */
  &:empty {
    display: none;
  }

  ${media.up(gridBreakpoint)`
    grid-area: conversation;

    /* IE11 grid syntax */
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  `}
`;

export const Selector = styled.div`
  padding-top: ${Spacing.M};
  ${media.up(gridBreakpoint)`
    margin-top: 0;
    padding-top: ${Spacing.OFF};
    grid-area: selector;
    align-self: center;
    max-width: 17.3125rem;

    /* IE11 grid syntax */
    -ms-grid-column: 2;
    -ms-grid-row-span: 2;
    -ms-grid-row-align: center;
  `};
`;
