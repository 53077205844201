import { useEffect, useState } from 'react';
import { getStatic } from 'utils';
import { AnyObject } from 'types/helpers';

/**
 * Fetches the translation file for the given locale
 * @param locale is the locale to fetch the translations for
 */
const useTranslatedContent = (locale: string) => {
  const [content, setContent] = useState<AnyObject>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    getStatic(`i18n/${locale}.json`)
      .then(({ data }) => {
        setContent(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [locale]);

  return {
    content,
    isLoading,
  };
};

export default useTranslatedContent;
