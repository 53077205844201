import RelayService from '@vodafonepub/relay-service';
import { TranslatedString } from 'components/Intl';

export const triggerConversationalHeaderEvent = (action: string, label: string, body?: TranslatedString[]) => {
  RelayService.trigger('ga.event', {
    ga_data: {
      event: 'conversational_header',
      interaction_type: action,
      event_location: window.location.href,
      creative_type: label,
      creative_title: '',
      creative_text: body?.join(' ') || '',
    },
  });
};

export const triggerApiErrorEvent = (errorSource: string, errorStatusCode?: number, errorMessages?: Record<string, unknown>) => {
  const errorMessagesFlat = errorMessages && Object.values(errorMessages).toString();

  RelayService.trigger('ga.event', {
    ga_data: {
      event: 'error',
      interaction_type: 'API response errors',
      category: 'my vodafone',
      event_location: window.location.href,
      error_type: `${errorStatusCode} error`,
      error_title: errorMessagesFlat,
      error_details: errorSource,
    },
  });
};
