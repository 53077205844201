import { ThemeInterface } from '@vodafoneziggo/sandwich';
import { pxToNumber } from 'utils/conversion';

/**
 * Based on the theme defined in Sandwich this util returns a valid GridTheme
 * object.
 * @param theme
 */
export const getGridTheme = (theme: ThemeInterface) => ({
  gridColumns: 12,
  breakpoints: {
    xxl: pxToNumber(theme.grid.breakpoints.xlg),
    xl: pxToNumber(theme.grid.breakpoints.xlg),
    lg: pxToNumber(theme.grid.breakpoints.lg),
    md: pxToNumber(theme.grid.breakpoints.md),
    sm: pxToNumber(theme.grid.breakpoints.sm),
    xs: pxToNumber(theme.grid.breakpoints.xs),
  },
  row: {
    padding: pxToNumber(theme.grid.gutter) / 2,
  },
  col: {
    padding: pxToNumber(theme.grid.gutter) / 2,
  },
  container: {
    padding: 16,

    // In our module we only use one max-width
    maxWidth: {
      xxl: pxToNumber(theme.grid.maxWidth),
      xl: pxToNumber(theme.grid.maxWidth),
      lg: pxToNumber(theme.grid.maxWidth),
      md: pxToNumber(theme.grid.maxWidth),
      sm: pxToNumber(theme.grid.maxWidth),
      xs: pxToNumber(theme.grid.maxWidth),
    },
  },
});
