import { gql, useApolloClient } from '@apollo/client';
import { OidcService } from '@hawaii-framework/oidc-implicit-core';
import { CcamContext, checkCcamSession, getStoredCcamToken } from 'containers/auth/auth.utils';
import { DefaultSuccessResponse, Domain, MutationUpdateDefaultSelectedAccountArgs } from 'generated/graphql';
import { useEffect } from 'react';

const getCurrentCCamTokenSubject = () => {
  const token = OidcService.getStoredToken({ scopes: ['ccam'] });

  if (!token) {
    console.error('No CCAM token found');

    return;
  }

  if (!token.access_token) {
    console.error('No access token in JWT');

    return;
  }

  const access_token = OidcService.parseJwt(token.access_token) as any;

  return access_token.sub;
};

export const useGetScopes = ({ options, skip }: { options: { scopes: string[] }; skip: boolean }) => {
  useEffect(() => {
    const requestNewScopes = async () => {
      try {
        await OidcService.checkSession(options);
      } catch (err) {
        console.error(err);
      }
    };

    !skip && requestNewScopes();
  }, [options, skip]);
};

export const UPDATE_DEFAULT_SELECTED_ACCOUNT = gql`
  mutation updateDefaultSelectedAccount($commonId: String!, $domain: Domain!, $sub: String!) {
    updateDefaultSelectedAccount(commonId: $commonId, domain: $domain, sub: $sub) {
      success
    }
  }
`;

export const useGetUpdatedCCAMScopes = ({
  options,
  skip,
  contactId,
}: {
  options: { scopes: string[] };
  skip: boolean;
  contactId: string | null;
}) => {
  const client = useApolloClient();

  useEffect(() => {
    const requestNewScopes = async () => {
      try {
        if (!contactId) return;
        const ccamContext = { domain: 'vz_sav', commonId: contactId } as CcamContext;

        // this should receive a CCAM token
        await OidcService.checkSession(options);

        // now check the CCAM token to find out if the subject is correct
        // it is possible that CCAM didn't return identical scopes to those we requested
        // but we still want the base CCAM token
        const ccamToken = getStoredCcamToken(['openid', 'ccam', 'vz_sav'], ccamContext);

        if (!ccamToken) {
          const subject = getCurrentCCamTokenSubject();

          // call the endpoint to update the default selected account
          await client.mutate<DefaultSuccessResponse, MutationUpdateDefaultSelectedAccountArgs>({
            mutation: UPDATE_DEFAULT_SELECTED_ACCOUNT,
            variables: {
              commonId: contactId,
              sub: subject,
              domain: Domain.vz_sav,
            },
          });

          // this should trigger the retrieval of a new token with the new default selected account
          await checkCcamSession(options.scopes, ccamContext);
        }
      } catch (err) {
        console.error(err);
      }
    };

    !skip && requestNewScopes();
  }, [options, skip, contactId]);
};
