import { Icon as UnstyledIcon } from '@vodafoneziggo/sandwich/icons';
import styled from 'styled-components';
import { media, themeProp, Spacing } from '@vodafoneziggo/sandwich';

export const Container = styled.div`
  max-width: 71.125rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${Spacing.S};
  padding-bottom: ${Spacing.S};

  ${media.up('md')`
  padding-top: ${Spacing.XL};
    padding-bottom: ${Spacing.XL};
  }
  `}

  display:grid;
  gap: ${Spacing.M};
`;

export const ErrorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(${themeProp('grid.gutter')} / 2);

  ${media.up('md')`
    grid-template-columns: 2fr 1fr;
  `}
`;

export const ProspectPortalCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${themeProp('grid.gutter')};

  a {
    text-decoration: none !important;
  }

  ${media.down('md')`
    grid-template-columns: 1fr;
    gap: ${Spacing.S};
  `}
`;

export const Icon = styled(UnstyledIcon)`
  width: 6rem;
  height: 6rem;
`;
