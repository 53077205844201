import { getQueryStringParamByName } from 'utils/url';
import { PayReturnTarget } from 'models/invoice.model';

type PaymentQueryParams = {
  returnTarget: PayReturnTarget;
  token?: string;
  email?: string;
  bcId?: string;
  baId?: string;
};

/**
 * This method will map a PaymentQueryParams object back to the correct URL search params.
 * It will also remove empty or default values from the resulting object.
 *
 * @param params PaymentQueryParams
 */
const mapToSearchParams = (params: PaymentQueryParams): { [key: string]: string } => {
  const filteredParams: { [key: string]: string } = {};

  Object.entries(params)
    // Sort by keys.
    .sort((a, b) => a[0].localeCompare(b[0]))
    .forEach(([key, value]) => {
      if (value === undefined) {
        // Don't include unset params.
        return;
      }

      if (key === 'returnTarget' && value === PayReturnTarget.WEB) {
        // Don't include the default returnTarget as param.
        return;
      }

      // Add to the filteredParams.
      filteredParams[key] = value;
    });

  return filteredParams;
};

/**
 * Utility hook to get the queryParams used in the payment flow.
 */
const usePaymentQueryParams = () => {
  const returnTarget =
    getQueryStringParamByName('returnTarget') === PayReturnTarget.APP ? PayReturnTarget.APP : PayReturnTarget.WEB;

  const params: PaymentQueryParams = {
    returnTarget,
    token: getQueryStringParamByName('token'),
    email: getQueryStringParamByName('email'),
    bcId: getQueryStringParamByName('bcId'),
    baId: getQueryStringParamByName('baId'),
  };

  /**
   * Will get all the queryParams as string representation ready to use in an URL.
   */
  const getURLQueryParams = () => {
    const searchParams = new URLSearchParams(mapToSearchParams(params));

    return searchParams.toString();
  };

  return { ...params, getURLQueryParams };
};

export default usePaymentQueryParams;
