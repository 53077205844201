import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import App from './App';
import './wdyr';

// We only want deployed production versions to generate Sentry logs
if (process.env.NODE_ENV === 'production' && !window.Cypress) {
  Sentry.init({
    dsn: 'https://5a7e6ffd8a4d476d95974f3419d421df@o575421.ingest.sentry.io/5830793',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    environment: process.env.NODE_ENV,
    release: `vf-module-my@${process.env.VERSION}`,
    allowUrls: [/https?:\/\/((\w*)\.)?\w*?\.?\w*?\.?vodafone\.nl/],
  });
}

ReactDOM.render(<App />, document.getElementById('app'));
