import { PermissionSettings, Roles } from 'models';
import { none } from 'utils/permissions/permission-conditions';

// Base prepaid permissions which are applied on the main route.
export const PREPAID: PermissionSettings = [
  // User must be prepaid
  [Roles.PREPAID],
];

// Permission set to make sure access to product related functionality can be blocked.
export const PREPAID_ACTIVE_BC: PermissionSettings = [[Roles.PREPAID], none([Roles.CEASED_BC])];
