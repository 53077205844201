import { Icon, MY_VFLOGO, VFWS10SYSTEM_ADMIN } from '@vodafoneziggo/sandwich/icons';
import React from 'react';
import { Container } from 'styled-bootstrap-grid';
import * as S from './TinyHeader.styles';

export const TinyHeader = () => {
  return (
    <S.Wrapper>
      <Container>
        <S.Content>
          <S.HomeLink href="/">
            <Icon icon={MY_VFLOGO} />
          </S.HomeLink>

          <S.LogoutLink href="/account/uitloggen">
            <Icon width="25" height="25" icon={VFWS10SYSTEM_ADMIN} /> Uitloggen
          </S.LogoutLink>
        </S.Content>
      </Container>
    </S.Wrapper>
  );
};
