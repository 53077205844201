import React from 'react';
import * as S from './nba.styles';
import { DisplayModes } from './package';
import useNba from './useNba';

export const SidebarNba = () => {
  // Jest doesn't work with exported enums in types.d.ts files, so we have this simple workaround that still enforces the type
  const nba = useNba({ displayMode: 1 as DisplayModes });

  if (!nba || !nba.markup) return null;

  return <S.Wrapper dangerouslySetInnerHTML={{ __html: nba.markup }} data-testid="nba" />;
};

export default SidebarNba;
