import usePaymentQueryParams from '../hooks/use-payment-query-params';
import { useEffect, useContext } from 'react';
import { BaseLayoutContext } from 'components/BaseLayout/BaseLayout';

const InvoicePaymentRedirect = () => {
  const { getURLQueryParams } = usePaymentQueryParams();
  const baseLayoutContext = useContext(BaseLayoutContext);

  useEffect(() => {
    // Since this is a setState method, lets not call it directly inside the render method.
    baseLayoutContext.setShowHeaderAndFooter(false);
    // With window.top we can break out of the iframe in case that hasn't happened yet (like in local development or the creditcard flow).
    window && window.top && window.top.location.assign(`/my/rekeningen/betaling?${getURLQueryParams()}`);
  }, []);

  return null;
};

export default InvoicePaymentRedirect;
