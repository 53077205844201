import { getQueryStringParamByName } from 'utils/url';
import { LocalStorage } from './storage';

const storageKey = 'VFpageView';

/**
 * Adds the view value to the body as a class
 * @param view
 */
const setPageViewToBodyClassList = (view: string) => {
  document.body.classList.add(`page--${view}`);
};

/**
 * Sets a `page--{type}` body class whenever it encounters either a `?view=` query param, or a previously
 * stored localStorage value. For now this is used to add a `page--app` class to the body, which causes
 * elements liv
 */
export function setPageView() {
  // Collect GET variables from the url
  const viewTypeFromQueryString = getQueryStringParamByName('view');

  // The current stored value - can return 'undefined'
  const storageVal = LocalStorage.get(storageKey);

  if ((viewTypeFromQueryString && typeof viewTypeFromQueryString === 'string') || viewTypeFromQueryString === '') {
    if (
      viewTypeFromQueryString === 'reset' ||
      viewTypeFromQueryString === 'default' ||
      viewTypeFromQueryString === ''
    ) {
      try {
        LocalStorage.remove(storageKey);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else {
      try {
        LocalStorage.set(storageKey, viewTypeFromQueryString);
        setPageViewToBodyClassList(viewTypeFromQueryString);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  } else if (storageVal) {
    setPageViewToBodyClassList(storageVal);
  }
}

export const __TESTABLE__ = { storageKey };
