import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { AnyObject } from 'types/helpers';
import { LoadableComponent } from '../Loading';
import useTranslatedContent from './useTranslatedContent';

export interface IntlProviderProps {
  children: React.ReactNode;
}

/**
 * As of now the currentLocale is static because we don't use other locales. If other locales should be supported,
 * this value could become a local state variable that fetches other locales translations when it changes.
 */
const currentLocale = 'nl';

export const flattenMessages = (nestedMessages: AnyObject, prefix = ''): AnyObject => {
  if (!nestedMessages) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      return { ...messages, [prefixedKey]: value };
    }

    return { ...messages, ...flattenMessages(value, prefixedKey) };
  }, {});
};

export const IntlProvider = ({ children }: IntlProviderProps) => {
  const { content, isLoading } = useTranslatedContent(currentLocale);

  if (isLoading) {
    return (
      <LoadableComponent isLoading={isLoading}>
        <span />
      </LoadableComponent>
    );
  }

  return (
    <ReactIntlProvider messages={flattenMessages(content)} locale={currentLocale} key={currentLocale}>
      {children}
    </ReactIntlProvider>
  );
};
