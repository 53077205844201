import { Roles } from 'models';
import { SessionStorage } from 'utils';

export * from 'components/User';

/**
 * Redirect the app to the BC-choice page.
 */
export const redirectToMakeAChoice = () => {
  const makeAChoiceUrl = '/my/maak-een-keuze/';

  // Don't create an infinite redirect loop :)
  if (window.location.href.indexOf(makeAChoiceUrl) > -1) return;

  // It could be that the user by now is on a specific page inside My Vodafone
  // for example viewing their latest bill. To make sure that the user will be
  // redirected back to the page he came from, we will set a postlogin redirect
  // variable back to this page insided My Vodafone.
  // However, if there already is a postLoginRedirect in place we will not override that
  // because we assume this is more important.
  // More information about how the redirect itself should work, can be found here:
  // https://vodafoneziggo.atlassian.net/wiki/spaces/TS/pages/143982593/Deeplinking

  const sessionStoragePostLoginRedirectKey = 'postLoginRedirect';

  if (!SessionStorage.get(sessionStoragePostLoginRedirectKey)) {
    SessionStorage.set(sessionStoragePostLoginRedirectKey, {
      [Roles.POSTPAID]: window.location.pathname + window.location.search,
      [Roles.PREPAID]: window.location.pathname + window.location.search,
    });
  }

  window.location.href = makeAChoiceUrl;
};
