/**
 * Lowercases the first character of the supplied string
 * @param str is the string to transform
 */
export const lowerFirst = (str: string): string => str.replace(/^\w/, s => s.toLowerCase());

/**
 * Uppercases the first character of the supplied string
 * @param str is the string to transform
 */
export const upperFirst = (str: string): string => str.replace(/^\w/, s => s.toUpperCase());

/**
 * Converts a JSX Element, number or string to a string.
 * @param element is either a string, number, or JSX Element
 */
export const safeString = (element?: string | number | (string | number)[] | JSX.Element): string => {
  if (!element) {
    return '';
  }

  if (typeof element === 'string') {
    return element;
  }

  if (typeof element === 'number') {
    return String(element);
  }

  if (Array.isArray(element)) {
    return element.map(subElement => safeString(subElement)).join('');
  }

  if (element.props && element.props.children) {
    return safeString(element.props.children);
  }

  if (element.props && !element.props.children) {
    return '';
  }

  return '';
};

/**
 * Converts hexadecimal to text
 * @param hex String hexadecimal
 */
export const hexToText = (hex: string) => {
  let str = '';

  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }

  return str;
};
