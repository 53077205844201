import { ApiResponse } from 'utils/api';

/**
 * In urls we expect only BC and product ids. That's why for now we only
 * filter out numeric values.
 * @param url
 */
export const filterPiiFromUrl = (url?: string): string => {
  if (!url) return '';

  return url.replace(/[0-9]/g, '*');
};

/**
 * The current environment is derived based on the hostname.
 * Possible options:
 * - development
 * - preview
 * - production
 * - 01.test (number is based on environment)
 * - 10.acc (number is based on environment)
 * - unknown
 *
 * If environment can not be resolved, we default to 'unknown'.
 */
export const getEnvironment = (hostname: string = window.location.hostname) => {
  const testForTestEnv = hostname.match(/[0-9]{2}.test/i);
  const testForTestAcc = hostname.match(/[0-9]{2}.acc/i);

  const isLocal = hostname.indexOf('kahuna') > -1;
  const isTest = testForTestEnv ? testForTestEnv[0] : false;
  const isAcc = testForTestAcc ? testForTestAcc[0] : false;
  const isPrv = hostname.indexOf('prv') > -1;
  const isProd = hostname.indexOf('www.vodafone.nl') > -1;

  if (isLocal) return 'development';

  if (isTest) return isTest; // returns specific test env, eg 05.test

  if (isAcc) return isAcc; // returns specific acc env, eg 05.acc

  if (isPrv) return 'preview';

  if (isProd) return 'production';

  return 'unknown';
};

interface IAdditionalErrorData {
  [key: string]: unknown;
}

/**
 * Logs an exception or error
 * @param error Either and Error object or a string, which will be thrown as an error
 * @param additionalErrorData key value object where the key is the name of the additional data, and the value the data
 *                            that should be logged.
 *
 * @example
 * logException('A fancy error message', {
 *     numberOfRetries: '5'
 * });
 */
export const logException = (error: Error | string, additionalErrorData?: IAdditionalErrorData) => {
  // Currently we have no third-party error logging service in place. But if we do, then this is
  // the place to implement it. For now, we only log the error to the console when not on production.
  if (getEnvironment() !== 'production') {
    // eslint-disable-next-line no-console
    console.error(error, additionalErrorData);
  }
};

/**
 * Accepts a error response from the kahuna backend, and logs this as an error to the console.
 *
 * The url and response params are marked as optional, to make sure we can easily pass the data of an API call
 * to it without the need of extra null checks.
 *
 * @param url The url of the API
 * @param response Backend API response
 */
export const logFailedApiCall = (url?: string, response?: ApiResponse) => {
  if (!url || !response) return;

  const backendHttpStatus = response.data.status;
  const backendErrors = response.data.error;
  const httpStatus = response.status;
  const txId = response.headers ? response.headers['x-hawaii-tx-id'] : null;

  logException(`API call failed:${filterPiiFromUrl(url)}`, {
    httpStatus,
    backendErrors,
    backendHttpStatus,
    txId,
  });
};
