/* stylelint-disable no-descending-specificity */
import { Color, margin, padding } from '@vodafoneziggo/sandwich';
import styled, { css } from 'styled-components';

export const List = styled.ol`
  ${margin.all('none', 'none', 'normal', 'none')};
  ${padding.all('none')};
  list-style-type: none;

  &:last-child {
    ${margin.bottom('none')};
  }
`;

const noTopBorder = css`
  border-top: none;
  ${padding.top('none')};
`;

export const ListItem = styled.li<{ noTopBorder?: boolean }>`
  position: relative;
  ${padding.all('normal', 'larger')}
  color: ${props => props.theme.colors.grayD40};
  border-top: 1px solid ${Color.GRAY_300};

  &:first-child {
    ${props => (props.noTopBorder ? noTopBorder : '')};
  }

  &:last-child {
    ${padding.bottom('none')};
  }

  a,
  a:visited {
    text-decoration: none;
    display: block;
    color: inherit;
  }

  a:hover {
    color: ${p => p.theme.colors.red};

    svg {
      color: inherit;
    }
  }
`;

export const Icon = styled.span`
  position: absolute;
  left: 0;

  svg {
    display: inline-block;
    color: ${p => p.theme.colors.grayD40};
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
`;

export const NavigationIcon = styled.span`
  position: absolute;
  right: 0;

  svg {
    display: inline-block;
    color: inherit;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
`;
