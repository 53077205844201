import { MeasurementUnits } from 'models';

/**
 * This file contains typings for usage responses as they are returned from the backend api. Responses are mapped into new objects
 * and typings, which you find here: src/pages/Usage/unbilled-usage.models.ts
 */

export interface IUnbilledUsage {
  unbilled_allowances?: IUnbilledAllowances[];
  daily_allowances?: IDailyAllowanceOffer[];
  usages_by_event_type?: IUsagesByEventType[];
  total?: ITotal;
  is_split_bill: boolean;
  is_out_of_data: boolean;
  is_throttled: boolean;
}

interface IThrottlingInfo {
  throttling_footprint: string;
  throttling_speed: string;
}

interface IConsumedAmount {
  amount_excluding_vat: number;
}

interface IContributionPerMsisdn {
  msisdn: string;
  resource_type: string;
  consumed_amount: IConsumedAmount;
}

interface IPcrfExtension {
  throttling_info: IThrottlingInfo[];
  contribution_per_msisdn: IContributionPerMsisdn[];
}

interface IUnbilledAllowances {
  subscription: string | 'group'; // "group" in case of red together bundle
  unbilled_allowances: IUnbilledAllowanceOffer[];
}

export interface IUnbilledAllowanceOffer {
  offer_type: OfferTypes;
  unbilled_allowances: IUnbilledAllowance[];
}

export interface IUnbilledAllowance {
  offer_id: string;
  subscription_id: string | 'group'; // "group" in case of red together bundle
  is_ziggo_benefit: boolean;
  is_family_bundle: boolean;
  is_roaming: boolean;
  is_group_level: boolean;
  is_group_bundle: boolean;
  is_aggregated_item: boolean;
  is_aggregated_total: boolean;
  is_unlimited_quota: boolean;
  is_unlimited_daily_allowance: boolean;
  is_unlimited_daily_eu_allowance: boolean;
  is_unlimited_daily_extended_allowance: boolean;
  unit_of_measurement: MeasurementUnits;
  allowance_description: string;
  special_icon?: string;
  quota: number | ITotalsAmountVat; // IUsageAmounts is used at Euro Bundles, else :number
  utilized_quota: number | ITotalsAmountVat;
  remaining_quota: number | ITotalsAmountVat;
  initial_quota: number | ITotalsAmountVat;
  next_quota: number | ITotalsAmountVat;
  rolled_pre_month_quota: number | ITotalsAmountVat;
  rolled_next_month_quota: number | ITotalsAmountVat;
  last_event_date: any;
  pcrf_extension?: IPcrfExtension;
}

export interface IDailyAllowanceOffer {
  offer_type: OfferTypes;
  daily_allowances: IDailyAllowance[];
}

export interface IDailyAllowance {
  allowance_profile_id: string;
  date_of_use: any;
  unit_of_measurement: MeasurementUnits;
  initial_quota: number;
  used_quota: number;
  remaining_quota: number;
  is_unlimited_daily_allowance: boolean;
  is_unlimited_daily_eu_allowance: boolean;
  is_unlimited_daily_extended_allowance: boolean;
  role: string;
  item_id: string;
  offer_id: string;
  offer_type: string;
  offer_instance: string;
  offer_name: string;
  offer_level: string;
  billing_arrangement: string;
  pay_channel: string;
  agreement_id: string;
  period_name: string;
  last_event_date: number;
  connection_to_daily_allowance: string;
  allowance_description: string;
  special_icon?: string;
  is_roaming: boolean;
}

export enum OfferTypes {
  ADDON = 'AddOn',
  AGGREGATED = 'Aggregated',
  BASE = 'Base',
}

export interface ITotalsAmountVat {
  amount_including_vat: number;
  amount_excluding_vat: number;
  vat_amount?: number;
}

interface IUsage {
  message_description: string;
  accumulated_charge: ITotalsAmountVat;
  amount_of_events: number;
  amount_of_free_events: number;
  accumulated_quantity: number;
  free_accumulated_quantity: number;
  unit_of_measurement: MeasurementUnits;
  description: string;
}

interface IUsagesByMessageCode {
  message_code: string;
  message_description: string;
  usage: IUsage;
}

interface IUsagesByEventType {
  event_type: UsageEventTypes;
  total: ITotalsAmountVat;
  usages_by_message_code: IUsagesByMessageCode[];
}

export enum UsageEventTypes {
  DATA = 'D',
  VOICE = 'V',
  TEXT = 'T',
  MONEY = 'M', // Content "Aankopen bij derden"
  OTHER = 'O', // Alles in 1 op reis
  OTHER_HYBRID = 'O-hybrid',
}

interface ITotal {
  last_event_date_inside?: number;
  last_event_date_outside?: number;
  total?: ITotalsAmountVat;
}
