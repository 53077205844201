/**
 * Apollo will flatten some errors to a plain Error object, so using custom error classes
 * will not always work as intended. Instead, we use these flat error strings to identify what error occured.
 */

export enum ApiError {
  INVALID_AUTH_TOKEN = 'INVALID_AUTH_TOKEN',
}

export interface IApiResponse<T> {
  data: T;
  status?: number;
  error?: {
    request_validation_errors: any;
    item_validation_errors: any;
  };
  headers?: {
    [key: string]: string;
  };
}
