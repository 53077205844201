import { MeasurementUnits } from 'models';
import { IConversation } from 'models/conversation.model';

export enum DashboardConversations {
  RETENTION = 'retention',
  ROAMING = 'roaming',
  ZIGGO_DATA_BUNDLES = 'ziggo-data-bundles',
}

export type IDashboardConversation = IConversation<DashboardConversations>;

export interface IConversationRetention extends IDashboardConversation {
  data: IRetentionData;
}

export interface IConversationRoaming extends IDashboardConversation {
  roaming: IRoamingData;
}

export interface IRetentionData {
  msisdns: string[];
}

export interface IRoamingData {
  country: string;
  country_iso2: string;
  tariff_info: ITariffInfo[];
  used_addon: string;
}

interface ITariffInfo {
  service_type: string;
  is_rate_found: boolean;
  rate: number;
  unit_of_measurement: MeasurementUnits;
  quantity: number;
  currency: string;
  is_vat_included: boolean;
}
