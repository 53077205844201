import { IProductSummary, ProductStatus } from 'pages/Products/models';
import api from 'utils/api';

export const sortProducts = (products: IProductSummary | IProductSummary[]) => {
  // Products should always be treated as an array, even if only one product is returned
  const productsArray = Array.isArray(products) ? products : [products];

  /**
   * Products that CEASED are moved to the last positions in the array, SMB-831
   * This sort function should also ensure that the original order of non ceased
   * items is not mutated.
   */
  const sorted = [...productsArray].sort((a, b) => {
    if ((a.product_status === ProductStatus.CEASED) < (b.product_status === ProductStatus.CEASED)) {
      return -1;
    }

    if ((a.product_status === ProductStatus.CEASED) > (b.product_status === ProductStatus.CEASED)) {
      return 1;
    }

    return productsArray.indexOf(a) - productsArray.indexOf(b);
  });

  return sorted;
};

/**
 * Perform api call to get the products belonging to the selected billing customer.
 * @param bcId
 */
export const getProductSummaries = (bcId: string): Promise<IProductSummary[] | null> =>
  api.get(`/my/product/summary/${bcId}`).then(response => {
    // If no products are returned, data contains an empty array
    if (Array.isArray(response.data) && !response.data.length) {
      return [];
    }

    return sortProducts(response.data);
  });

/**
 * Returns an array of product ids of products that are ceased
 * @param products is a collection of produc  t summaries
 */
export const getCeasedProductIds = (products: IProductSummary[]): string[] => {
  return products
    .slice()
    .filter(product => product.product_status === ProductStatus.CEASED)
    .map(product => product.product_id);
};
