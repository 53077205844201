import { Color, Spacing } from '@vodafoneziggo/sandwich';
import { Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${Color.WHITE};
  box-shadow: ${props => props.theme.shadows.base};
  padding: ${Spacing.S} 0;
  margin-bottom: ${Spacing.XL};
`;

export const Content = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HomeLink = styled.a`
  path {
    fill: ${Color.PRIMARY};
  }
`;

export const LogoutLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: ${props => props.theme.fonts.weights.light};

  svg {
    margin-right: ${Spacing.XS};
  }
`;
