/**
 * Returns a property deeply nested in an object, or undefined if the property does not exist.
 *
 * Usage:
 * const value = getDeepProp(obj, 'some.deep.property')
 *
 * or
 * const path = ['some', 'deep, 'prop']
 * const modified = getDeepProp(obj, path)
 *
 * @param obj is the object to search in
 * @param path is the path to search for, either a string or an array of string
 */
const getDeepProp = (obj: any, path: string | string[]): unknown | undefined => {
  const props = !Array.isArray(path) ? path.split('.') : path;

  return props.reduce((o, k) => (o && o[k] !== 'undefined' ? o[k] : undefined), obj);
};

/**
 * Sets a property deep in obj, and returns the modified obj
 *
 * Usage:
 * const modified = setDeepProp(obj, 'some.deep.property', newValue)
 *
 * or
 * const path = ['some', 'deep, 'prop']
 * const modified = setDeepProp(obj, path, newValue)
 *
 * @param obj is the object to set the prop in
 * @param path is the path to search for, either a string or an array of string
 * @param value is the value to set, which can also be undefined
 */
const setDeepProp = (obj: any, path: string | string[], value: unknown | undefined) => {
  const props = !Array.isArray(path) ? path.split('.') : path;
  const numProps = props.length - 1;
  let currObj = obj;

  for (let i = 0; i <= numProps; i += 1) {
    // eslint-disable-next-line no-multi-assign
    currObj = currObj[props[i]] = i !== numProps ? {} : value;
  }

  return obj;
};

/**
 * Returns true if all props are present and not falsy in the object provided
 *
 * @example
 * ```
 * const exist = propsExist(['foo', 'bar'], {foo: 'a', bar: null, baz: 'b'})
 * // false
 * ```
 */
const propsExist = (props: (keyof Record<string, any>)[], data: Record<string, any>) =>
  // eslint-disable-next-line no-prototype-builtins
  props.every(prop => data.hasOwnProperty(prop) && data[prop]);

export { getDeepProp, setDeepProp, propsExist };
