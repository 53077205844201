type cancellationWrapper = <T extends (...args: any[]) => any, P extends Parameters<T>>(
  fn: (...args: P) => ReturnType<T>
) => (...args: P) => ReturnType<T> | undefined;

type wrapWithCancellationReturnValue = {
  /**
   * Wrapper to pass your method to, which will not be executed whenever the cancel method was called first.
   */
  wrapper: cancellationWrapper;
  /**
   * Method to call, to prevent the wrapped method to be executed.
   */
  cancel: () => void;
};

/**
 * This method will allow you to provide a function wrapper which will not execute the
 * function passed to it, after the cancel method was called.
 * This can be useful to prevent unwanted side effects, for instance when using a promise inside a useEffect hook.
 */
export const wrapWithCancellation = (): wrapWithCancellationReturnValue => {
  // Indicates that the desctructor was called, and stops calling the function.
  let isCancelled = false;

  const wrapper: cancellationWrapper = fn => (...args) => (isCancelled ? undefined : fn(...args));

  const cancel = () => {
    isCancelled = true;
  };

  return { wrapper, cancel };
};
