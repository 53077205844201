import { IBillingCustomer } from './billing-customer.model';
import { IContact } from './contact.model';

export interface IUser {
  account_confirmed: boolean;
  billing_customers: IBillingCustomer[];
  contact: IContact;
  is_consumer: boolean;
  is_change_password_allowed: boolean;
  is_personal_search_enabled: boolean;
  user_name: string;
  party_id: string;
  is_ziggo_customer: boolean;
  enterprise: IUserEnterprise;
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
  /**
   * @deprecated Legacy value due to support legacy system. Prefer to use FEMALE ("F").
   */
  VROUW = 'V',
  /**
   * @deprecated Legacy value due to support legacy system. Prefer to use either MALE ("M") or FEMALE ("F").
   */
  MALE_FEMALE = 'M/V',
  UNKNOWN = '',
}

export enum EnterpriseContextType {
  CUSTOMER = 'CUSTOMER',
  ACCOUNT = 'ACCOUNT',
}

export interface IUserEnterprise {
  nr_accounts?: number;
  contact_id?: string;
  main_user_roles?: string[];
  contexts?: IEnterpriseContext[];
  access_settings?: IEnterpriseAccessSettings;
  nr_billing_customers?: number;
  nr_main_user_billing_customers?: number;
  nr_end_user_billing_customers?: number;
  billing_customers_show_search?: boolean;
  customer_level_products?: string[];
  choosable_contexts?: IEnterpriseContext[];
}

export interface IEnterpriseContext {
  id: string;
  type?: EnterpriseContextType;
  name: string;
  account: string;
  role: string;
  chat_segment_code: string;
  access_settings: IEnterpriseAccessSettings;
  main_user_roles: string[];
  customer_type?: string;
  customer_subtype?: string;
  is_one_net_customer?: boolean;
}

// Backwards compatibility for the interface rename
export type Context = IEnterpriseContext;

export interface IEnterpriseAccessSettings {
  show_prices?: boolean;
  is_ot?: boolean;
  is_iwr?: boolean;
  is_iwr_ccm?: boolean;
  is_gtf?: boolean;
  show_disturbance_alerts?: boolean;
  show_ansa_reports?: boolean;
  has_home_account_access?: boolean;
  has_customer_level_products?: boolean;
  has_actual_bc_access?: boolean;
}
