import { OidcConfig, OidcService } from '@hawaii-framework/oidc-implicit-core';
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SessionStorage } from 'utils';
import { useGetScopes } from 'utils/hooks/useRequestExtraScopes';
import { saveUrlToRestore } from 'utils/hooks/useUrlRestoration';
import { DEFAULT_TOKEN_VALIDATION_OPTS } from './scopes';

interface OidcContextValue {
  isInitialized: boolean;
  isAuthenticated: boolean;
  refreshAuthStatus: () => Promise<void>;
  oidcConfig: OidcConfig;
  isMFA: boolean;
}

export const OidcContext = createContext<OidcContextValue>({
  isInitialized: false,
  isAuthenticated: false,
  isMFA: false,
  refreshAuthStatus: () => Promise.resolve(),
  oidcConfig: {
    provider_id: '',
    client_id: '',
    response_type: '',
    redirect_uri: '',
    restricted_redirect_uris: [''],
    post_logout_redirect_uri: '',
    authorisation: '',
    scope: '',
    token_type: '',
    authorize_endpoint: '',
    csrf_token_endpoint: '',
    validate_token_endpoint: '',
    is_session_alive_endpoint: '',
    upgrade_session_endpoint: '',
    login_endpoint: '',
    logout_endpoint: '',
  },
});

interface OidcProviderProps {
  oidcConfig: OidcConfig;
  autoLogout?: number | false;
}

export const OidcProvider = ({ children, oidcConfig, autoLogout }: PropsWithChildren<OidcProviderProps>) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const location = useLocation();

  const refreshAuthStatus = useCallback(
    () =>
      OidcService.checkSession()
        .then(() => {
          setIsAuthenticated(true);

          // For local development we mock MFA accounts through a startup parameter
          const mfa = OidcService.getStoredToken({ scopes: ['two_factor_confirmed'] }) || process.env.MOCKMFA;

          setIsMFA(!!mfa);
        })
        .catch(() => {
          setIsAuthenticated(false);
        }),
    []
  );

  /**
   * Initialize OIDC Config
   */
  useEffect(() => {
    setIsInitialized(false);
    OidcService.OidcConfigService.config = oidcConfig;

    /**
     * The OIDC authorize call (caused by refreshAuthStatus) must always redirect the user to /my.
     * Before calling refreshAuthStatus, we want to find out if:
     * a) the user is being deep-linked to a page on /my that isn't the dashboard (they get redirected
     * to the dashboard anyway, so we don't need to restore it)
     * b) the user does not already have a url_to_restore (we don't want to overwrite it with /my or anything else).
     *
     * This url is picked up in the <RedirectToDeepLink/> component and a <Navigate/> component will be rendered if present.
     * If this occurs, the url_to_restore is removed from session storage.
     */
    if (
      !SessionStorage.get('url_to_restore') &&
      location.pathname !== '/' &&
      /**
       * After react router v6 upgrade, when the user has multiple billing customers, after logging in
       * double redirection happens to maak-een-keuze page.
       * Root cause is adding maak-een-keuze to url_to_restore. That is why it is exempted.
       *
       */
      location.pathname !== '/maak-een-keuze/'
    ) {
      saveUrlToRestore(location.pathname + location.search);
    }

    refreshAuthStatus();
    setIsInitialized(true);
  }, [oidcConfig, refreshAuthStatus]);

  useAutomaticLogout({
    isAuthenticated,
    oidcConfig,
    autoLogout,
  });

  useGetScopes({
    options: {
      scopes: [
        'openid',
        'email',
        'hawaii_sso',
        'ccam',
        'vz_sav',
        'vz_sim_read',
        'vz_sim_write',
        'vz_pts',
        'vz_ppis',
        'two_factor_challenge',
        'vz_user',
        'vz_sim_order',
        'vz_esim_activation_code_read',
      ],
    },
    skip: !isAuthenticated,
  });

  const oidcInfo = useMemo<OidcContextValue>(
    () => ({
      isInitialized,
      refreshAuthStatus,
      isAuthenticated,
      oidcConfig,
      isMFA,
    }),
    [isAuthenticated, isInitialized, oidcConfig, refreshAuthStatus, isMFA]
  );

  return <OidcContext.Provider value={oidcInfo}>{children}</OidcContext.Provider>;
};

export const useOidc = () => useContext(OidcContext);

interface UseAutomaticLogout {
  autoLogout?: number | false;
  isAuthenticated: boolean;
  oidcConfig: OidcConfig;
}

const useAutomaticLogout = ({ autoLogout, isAuthenticated, oidcConfig }: UseAutomaticLogout) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let autoLogoutInterval;

    if (autoLogout && isAuthenticated) {
      autoLogoutInterval = setInterval(() => {
        // Get stored token either returns a non-expired token or null
        const storedToken = OidcService.getStoredToken(DEFAULT_TOKEN_VALIDATION_OPTS);

        if (!storedToken) {
          OidcService.isSessionAlive().catch(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            clearInterval(autoLogoutInterval);

            // Navigate to the logged out page via the router.
            window.location.href = oidcConfig.post_logout_redirect_uri;
          });
        }
      }, autoLogout);
    }

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      if (autoLogoutInterval) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        clearInterval(autoLogoutInterval);
      }
    };
  }, [autoLogout, isAuthenticated, oidcConfig.post_logout_redirect_uri]);
};
