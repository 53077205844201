/**
 * These local storage keys are used throughout the different modules, although
 * most of them will be set in the Module My.
 * We did however decide to put all these keys in a single file inside Sandwich,
 * because all of the different module that need to clear these keys whenever a
 * user goes from a logged in state to the logged out state.
 * If we put them in the modules themselves for example Module Support would
 * not know about the keys used inside Module My, and had no way to clear them.
 *
 * Some idea's we have for the future to let modules set their own keys but have
 * the ability to clear keys from all modules:
 * - Work with a prefix for storage keys, and clear all keys with prefix
 * - Add another localstorage key containing all keys to be cleared.
 *    - So all modules register their own keys on 'mount' as soon as they will set them.
 *    - If the user navigates to another module, they also add their own keys on 'mount'.
 *    - When a module wants to clear the localstorage keys, it just reads this key, and then
 *      clears out all those localstorage keys.
 *    - Might sound like a weird idea at first, but it could also be a way to make modules
 *      only know about the things relevant to themselves.
 *
 * @see https://vodafoneziggo.atlassian.net/browse/DR-170
 */

export const PRODUCTS_LOCALSTORAGE_KEYS: { [key: string]: string } = {
  CURRENT_PRODUCT_ID: 'current_product_id',
  SELECTED_PRODUCT: 'selectedProduct',
  IS_DATA_ONLY: 'is_data_only',
  PRODUCT_SUMMARIES: 'productSummaries',
  CEASED_PRODUCTS: 'current_billing_customer_ceased_products',
};

export const USER_LOCALSTORAGE_KEYS: { [key: string]: string } = {
  ACTIVE_BC_INDEX: 'current_billing_customer',
  ACTIVE_BC_ID: 'current_billing_customer_id',
  CURRENT_ACCOUNT: 'current_account',
  CURRENT_BC_CEASED: 'current_billing_customer_ceased',
  CURRENT_BC_HAS_PAYMENT_DELAY: 'current_billing_customer_has_payment_delay',
  IE11_WARNING_DISMISSED: 'ie11_warning_dismissed',
};
