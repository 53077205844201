import { UserContext } from 'components/User';
import { IProductSummary } from 'pages/Products/models';
import React, { useContext, useMemo, useState } from 'react';
import {
  useFetchProductSummariesOnBillingCustomerChange,
  useRedirectCeasedProduct,
  useSetSelectedProductOnProductsChange,
  useUpdateLocalstorageOnCurrentProductChange,
  useUpdateRelayOnCurrentProductChange,
} from './hooks';
import { IProductSummariesContext, IProductSummariesContextState } from './products.model';

const initialProductSummariesContextState: IProductSummariesContextState = {
  products: [],
  forBc: null,
  hasMobileOffer: null,
};

export const ProductSummariesContext = React.createContext<IProductSummariesContext>({
  state: { ...initialProductSummariesContextState },
  setCurrentProduct: () => {},
});

interface IProductSummariesProviderProps {
  children: React.ReactNode;
}

export const ProductSummariesProvider = (props: IProductSummariesProviderProps) => {
  const [productSummariesState, setProductSummariesState] = useState<IProductSummariesContextState>({
    ...initialProductSummariesContextState,
  });
  const userContext = useContext(UserContext);

  useFetchProductSummariesOnBillingCustomerChange(userContext.state, setProductSummariesState);
  useSetSelectedProductOnProductsChange(productSummariesState, setProductSummariesState);
  useUpdateRelayOnCurrentProductChange(productSummariesState);
  useRedirectCeasedProduct(productSummariesState, setProductSummariesState);
  useUpdateLocalstorageOnCurrentProductChange(productSummariesState);

  const setCurrentProduct = (product: IProductSummary) => {
    setProductSummariesState(state => ({
      ...state,
      currentProduct: product,
    }));
  };

  const context = useMemo(() => {
    return { setCurrentProduct, state: productSummariesState };
  }, [setCurrentProduct, productSummariesState]);

  return <ProductSummariesContext.Provider value={context}>{props.children}</ProductSummariesContext.Provider>;
};
