import { setDeepProp } from 'utils/object';
import { ErrorTypes, IErrorMessages, IItemValidationError } from '..';

/**
 * Attempts to map common errors to existing translation keys. All errors are combined into an object
 * that has keys identifying the error, and values that match translations and can be fed into a
 * translate method.
 *
 * @param errors is an object containing errors from the backend
 */
export const mapErrorsToErrorMessages = (errors: { [key: string]: any }): IErrorMessages => {
  if (!errors) {
    return {};
  }

  /**
   * Maps error codes to possibly existing language file errors. The object values should match
   * translation lines in nl.json. The keys have no specific meaning and are mainly used to keep keys
   * unique and to easily find where the error came from.
   *
   * ````
   * {
   *   'bsl.bsl-1000424': 'error.bsl.bsl-1000424',
   *   ...
   * }
   * ````
   *
   * @param codes are the codes returned from the backend, which are uppercase strings matching our
   * translation file
   */
  const mapErrorCodes = (ns: string, codes: string[] = []) =>
    codes.reduce(
      /* eslint-disable-next-line @typescript-eslint/ban-types */
      (acc: {}, code: string) => ({
        ...acc,
        [`${ns}.${code}`]: `error.${ns}.${code.toLowerCase()}`,
      }),
      {}
    );

  /**
   * Maps 'item_validation_errors' to possibly existing language file errors. The object values should match
   * translation lines in nl.json. The keys have no specific meaning and are mainly used to keep keys
   * unique and to easily find where the error came from.
   *
   * ````
   * {
   *   'item_validation.birth_date.invalid': 'error.invalid.birthdate',
   *   'item_validation.first_name.required': 'error.required.first_name',
   *   ...
   * }
   * ````
   *
   * @param items are the items, returned from the backend, that have an error
   */
  const mapItemValidationErrors = (items: IItemValidationError[] = []) =>
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    items.reduce((acc: {}, item: IItemValidationError) => {
      const errorCode: string = item.error_code.toLowerCase();
      const itemKey: string = item.item_key;

      const errorTranslationPath =
        ['invalid', 'required'].indexOf(errorCode) !== -1
          ? // Translation is found in error.{errorCode}.{itemName}
            `error.${errorCode}.${itemKey}`
          : // Translation is found in error.other.{errorCode}
            `error.other.${errorCode}`;

      return {
        ...acc,
        [`${ErrorTypes.ITEM_VALIDATION}.${itemKey}.${errorCode}`]: errorTranslationPath,
      };
    }, {});

  // Add each error type to the messages and return the result
  const messages = {
    ...mapItemValidationErrors(errors.item_validation_errors),
    ...mapErrorCodes(ErrorTypes.REQUEST, errors.request_validation_errors),
    ...mapErrorCodes(ErrorTypes.BSL, errors.bsl_server_error_codes),
    ...mapErrorCodes(ErrorTypes.MOSA, errors.mosa_server_error_codes),
  };

  return messages;
};

type FormErrors = {
  [key: string]: {
    translationKey: string;
    value: any;
  };
};

/**
 * Collects 'item_validation' errors from an errorMessages object, to a FormErrors
 * object that has field names as keys and IMessage's as values
 * @param errors is an object with errors
 */
export const mapItemValidationErrorsToFormErrors = (errors: IErrorMessages): FormErrors => {
  // If no errors can be parsed, return an empty object
  if (!errors) {
    return {};
  }

  // Get only the errors starting with 'item_validation'. We're not interested in other errors here
  const onlyValidationErrors = Object.keys(errors).filter(err => err.split('.')[0] === ErrorTypes.ITEM_VALIDATION);

  // For each error, extract the field name from the error key and use that as the key of the object
  // which will be returned. The values of the object are IMessage's
  return onlyValidationErrors.reduce((acc, err) => {
    // Explode the error key into parts, so we can extract the field name from them.
    const parts = err.split('.');

    // Next, the field name consists of all parts between the first and the last part. Usually this is
    // only one part, but in some cases it can be more.
    const fieldName: string = [...parts.splice(1, parts.length - 2)].join('.');

    // Finally, if the fieldName has dots in them, we should return it as an object. Using lodash's `set`
    // method, we can convert a string with dots into an serie of objects.
    const submitError = setDeepProp({}, fieldName, {
      translationKey: errors[err],
    });

    return {
      ...acc,
      ...submitError,
    };
  }, {});
};
